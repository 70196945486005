import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_INACTIVE_LOADING,
  GET_INACTIVE_SUCCESS,
  GET_INACTIVE_FAIL,
  CLEAR_INACTIVE_ERROR,
} from '../types';

export const inactive = (currentPage) => async (dispatch, getState) => {
  dispatch({
    type: GET_INACTIVE_LOADING,
    payload: { currentPage },
  });
  try {
    const { inactiveData } = getState().inactive;
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/users/inactive/${currentPage}`, options);
    dispatch({
      type: GET_INACTIVE_SUCCESS,
      payload: {
        inactiveListUsers: [...inactiveData, ...response.data],
        blankInactive: response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_INACTIVE_FAIL,
      payload: err.message,
    });
  }
};

export const clearHistoryError = (id) => ({
  type: CLEAR_INACTIVE_ERROR,
  payload: { id },
});

import {
  GET_TURNOVER_LOADING,
  GET_TURNOVER_SUCCESS,
  GET_TURNOVER_FAIL,
  CLEAR_TURNOVER_ERROR,
} from '../types';

const initialState = {
  turnOverData: [],
  isLoading: false,
  error: '',
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TURNOVER_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case GET_TURNOVER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        turnOverData: payload.turnOverData,
      };

    case GET_TURNOVER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    case CLEAR_TURNOVER_ERROR:
      return {
        ...state,
        turnOverData: state.Wallet.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ChangePassword } from '../../store/actions/usersAction';
import './styles.css';
import { Button } from 'react-bootstrap';

const ChangePasswordView = ({
    ChangePassword,
    message,
    changePassMsg,
    errorPassword,
    auth,
}) => {
    const [password, setPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');

  const changePass = () => {
        ChangePassword(password, newpassword);
  }

  return (
    <div className={message.isLoading ? 'message loader' : 'message mt-5'}>
      <div className="change-password-ui">
        <h1>Change Password</h1>
        <input type="password" placeholder="Enter Your Old Password" 
         value={password}
         onChange={(e) => setPassword(e.target.value)}
        />
        <input type="password" placeholder="Enter Your New Password" 
         value={newpassword}
         onChange={(e) => setNewPassword(e.target.value)}
        />
        <Button className="chng-passwrd-btn" onClick={changePass}>Submit</Button>
        {errorPassword }<br/>{changePassMsg}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.users.messages,
  changePassMsg : state.users.changePassMsg,
  errorPassword : state.users.error,
  roleUsers: state.users.roleUsers,
  auth: state.auth,
});

export default connect(mapStateToProps, {
ChangePassword
})(ChangePasswordView);

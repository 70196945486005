import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { getHistory } from '../../store/actions/historyAction';

import './styles.css';

const BattingAmount = ({ getHistory, history, auth, deleteUsers }) => {
  useEffect(() => {
    getHistory();
  }, [getHistory]);

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'Start', prop: 'start' },
    { title: 'End', prop: 'end' },
    { title: 'Total Betting', prop: 'totalBetting' },
    { title: 'Winning Amount', prop: 'gamename' },
  ];

  // Table Body Data
  let bodyData = [];
  if (history !== undefined) {
    bodyData = [
      history.map((users, index) => {
        return {
          srno: index + 1,
          start: moment(users?.start).format('DD/MM/YYYY hh:mm:ss'),
          end: moment(users?.end).format('DD/MM/YYYY hh:mm:ss'),
          totalBetting: users?.total_betting,
          gamename: users?.total_winning,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={history.isLoading ? 'history loader' : 'history'}>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
        rowsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  history: state.history.gameHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { getHistory })(BattingAmount);

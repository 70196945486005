import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { editBettingtype, getBettingtypes } from '../../store/actions/bettingActions';

function EditBettingType(props) {
  const [winning_x, setwinning_x] = useState(props.winningX);
  const [status] = useState(props.status);
  const [description, setdescription] = useState(props.desc);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.getBettingtypes();
  };

  const handleShow = () => setShow(true);

  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = (e) => {
    props.editBettingtype(props.gameid, winning_x, status, description);
  };
  return (
    <>
      <span
        className="edit-icon"
        type="button"
        variant="primary"
        onClick={() => handleShow(props.gameid)}
      >
        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
      </span>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 profile-img cntr">
              <h3>EDIT GAME</h3>
              {props.name}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 profile-sub-details">
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Winning_X"
                    value={winning_x}
                    onChange={(e) => setwinning_x(e.target.value)}
                    required
                  />
                </div>
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Status"
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSubmit()} variant="success" >
            Submit
          </Button>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  bettingTypes: state.betting.bettingtypes,
});
export default connect(mapStateToProps, { getBettingtypes, editBettingtype })(EditBettingType);

import {
  SEARCH_USERS_LOADING,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USERS_TOKEN_LOADING,
  ADD_USERS_TOKEN_SUCCESS,
  ADD_USERS_TOKEN_FAIL,
  ADD_TRAN_SUCCESS,
  ADD_TRAN_FAIL,
  ADD_USERS_LOADING,
  ADD_USERS_SUCCESS,
  ADD_FILTER_USERS_LOADING,
  ADD_FILTER_USERS_SUCCESS,
  ADD_USERS_FAIL,
  DELETE_USERS_LOADING,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  EDIT_USERS_LOADING,
  EDIT_USERS_SUCCESS,
  EDIT_USERS_FAIL,
  CLEAR_USERS_ERROR,
  ERROR_SUCCESS,
  ADD_NEW_USERS_SUCCESS,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_AGENTS_SELECT_LOADING,
  GET_AGENTS_SELECT_SUCCESS,
  GET_AGENTS_SELECT_FAIL
} from '../types';

const initialState = {
  messages: [],
  roleUsers: [],
  roleIDBase: [],
  transactionUser: [],
  userParent: [],
  Userbettings: [],
  addNewUserByRole : [],
  changePass : [],
  changePassMsg : [],
  singleUserTokn: [],
  selectAgentsData: [],
  isLoading: false,
  error: null,
  parentId: '',
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_USERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_PASSWORD_LOADING:
      return{
        ...state,
        isLoading: true,
      };
    case  ADD_USERS_TOKEN_LOADING:
      return{
          ...state,
          isLoading: true,
      };
    case ADD_USERS_LOADING:
      return {
        ...state,
      };
    case ADD_FILTER_USERS_LOADING:
      return{
        ...state,
        roleUsers: []
      }
    case SEARCH_USERS_LOADING:
        return {
          ...state,
        };
    case DELETE_USERS_LOADING:
    case EDIT_USERS_LOADING:
      return {
        ...state,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roleUsers: payload.message,
        blankmsg: payload.blankmsg,
        roleIDBase: payload.roleIDLoad,
        error: '',
      };
    case ADD_FILTER_USERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          roleUsers: payload.message,
          blankmsg: payload.blankmsg,
          error: '',
      };
    case SEARCH_USERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          roleUsers: payload.message,
          error: '',
      };
    case ADD_USERS_TOKEN_SUCCESS:
      return{
          ...state,
          isLoading: false,
          singleUserTokn: payload
      };

    case CHANGE_PASSWORD_SUCCESS:
      return{
        ...state,
        changePass: payload.changePassword,
        changePassMsg: payload.changePasswordmsg
      };
    case ADD_NEW_USERS_SUCCESS:
      return{
        ...state,
        addNewUserByRole : payload.addNewUserByRole,
      }
    case ERROR_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case ADD_TRAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactionUser: payload.transactionUser,
        userParent: payload.parentUnderUser,
        Userbettings: payload.Userbettings,
        blankData: payload.blankData,
        blankPlaying: payload.blankPlaying
      };
    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        messages: state.messages.filter((m) => m.id !== payload.message.id),
      };
    case EDIT_USERS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    case DELETE_USERS_FAIL:
    case EDIT_USERS_FAIL:
      return {
        ...state,
        error: null,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_USERS_TOKEN_FAIL:
      return{
        ...state,
        isLoading: false,
        error: payload.error
      }
    case ADD_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case SEARCH_USERS_FAIL:
        return {
          ...state,
          isLoading: false,
          error: payload.error,
      };
    case ADD_TRAN_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_AGENTS_SELECT_LOADING:
        return {
          ...state,
          isLoading: true,
        };
    case GET_AGENTS_SELECT_SUCCESS:
          return {
            ...state,
            isLoading: false,
            selectAgentsData: payload.selectedAgent
          };
    case GET_AGENTS_SELECT_FAIL:
            return {
              ...state,
              isLoading: false,
              error: payload.error,
            };
    case CLEAR_USERS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

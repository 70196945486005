import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { editUsers, allUsers } from '../../store/actions/usersAction';

function EditUser(props) {
  const [name, setName] = useState(props.name);
  const [username, setUsername] = useState(props.username);
  const [email, setemail] = useState(props.email);
  const [phone, setphone] = useState(props.phone);
  const [password, setpassword] = useState();
  const [status, setStatus] = useState();
  const [commission, setcommission] = useState(props.commisionAmount);
  const [role, setRole] = useState(props.role?._id);
  const [games, setGames] = useState();
  const [canAgentCreateUser, setCanAgentCreateUser] = useState(props?.agentCreate);
  const [softDelete, setSoftDelete] = useState(props?.softdlt);
  const [allGames] = useState(props?.games);
  const [loading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // props.allUsers();
  };
  const handleShow = () => setShow(true);

  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = (e) => {
    props.editUsers(
      props.gameid,
      name,
      username,
      email,
      phone,
      status,
      password,
      commission,
      role,
      games,
      canAgentCreateUser,
      softDelete
    );
    alert('Your Profile is Edit Successfully');
    window.location.reload();
    setShow(false);
  };
  const selectGames = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setGames(value);
  };
  return (
    <>
      <span
        className="edit-icon"
        type="button"
        variant="primary"
        onClick={() => handleShow(props.gameid)}
      >
        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
      </span>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 profile-img cntr">
              <h3>EDIT USER</h3>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 profile-sub-details">
                <div className="row pt-3">
                  <label>
                    <b>Name</b>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    maxlength="8"
                  />
                </div>
                <div className="row pt-3">
                  <label>
                    <b>User Name</b>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your User"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    disabled
                  />
                </div>
                <div className="row pt-3">
                  <label>
                    <b>Email</b>
                  </label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </div>
                <div className="row pt-3">
                  <label>
                    <b>Commission</b>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="Commission"
                    value={commission}
                    onChange={(e) => setcommission(e.target.value)}
                    required
                  />
                </div>
                <div className="row pt-3">
                  <label>
                    <b>Select Role</b>
                  </label>
                  <select name="color" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="6114d59922b7c53a358bba09" label="Agent" />
                    <option value="6114d5c422b7c53a358bba0b" label="Customer" />
                  </select>
                </div>
                <div className="row pt-3">
                <label>Can Agent create user</label>
                  <select
                    name="canAgentCreateUser"
                    value={canAgentCreateUser}
                    onChange={(e) => setCanAgentCreateUser(e.target.value)}
                    required="true"
                    className="required-fild-red"
                  >
                    <option value="" label="Can Agent create user" />
                    <option value={true} label="Yes" />
                    <option value={false} label="No" />
                  </select>
                </div>
                <div className="row pt-3">
                <label>Delete</label>
                  <select
                    name="softDelete"
                    value={softDelete}
                    onChange={(e) => setSoftDelete(e.target.value)}
                   
                  >
                    <option value="" label="Delete" />
                    <option value={true} label="Yes" />
                    <option value={false} label="No" />
                  </select>
                </div>
                <div className="row pt-3">
                  <label>
                    <b>Phone No.</b>
                  </label>
                  <Form.Control
                    type="text"
                    placeholder="phone"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    required
                  />
                </div>
                <div className="row pt-3">
                  <select name="color" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="" label="Select Status" />
                    <option value="true" label="Unblock" />
                    <option value="false" label="Block" />
                  </select>
                </div>
                {props.role?.id === 3 && (
                  <div className="row pt-3">
                    <label>
                      <b>Select Games </b>
                    </label>
                    <select
                      className="select-games-main"
                      name="games"
                      onChange={selectGames}
                      multiple
                    >
                      {allGames.find((element) => element === '611b69498a898d2aa6386cb3') ? (
                        <option
                          className="selected-game-grn"
                          value="611b69498a898d2aa6386cb3"
                          label="Roulette"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="611b69498a898d2aa6386cb3"
                          label="Roulette"
                        />
                      )}

                      {allGames.find((element) => element === '615ad717bfe7fcaa9cdd75e9') ? (
                        <option
                          className="selected-game-grn"
                          value="615ad717bfe7fcaa9cdd75e9"
                          label="Lucky Card"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="615ad717bfe7fcaa9cdd75e9"
                          label="Lucky Card"
                        />
                      )}

                      {allGames.find((element) => element === '61b45b0d5e89b7d5fe031b9c') ? (
                        <option
                          className="selected-game-grn"
                          value="61b45b0d5e89b7d5fe031b9c"
                          label="Lucky Card Print"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="61b45b0d5e89b7d5fe031b9c"
                          label="Lucky Card Print"
                        />
                      )}

                      {allGames.find((element) => element === '619b8a1eb2e449216d0b970d') ? (
                        <option
                          className="selected-game-grn"
                          value="619b8a1eb2e449216d0b970d"
                          label="52 Cards"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="619b8a1eb2e449216d0b970d"
                          label="52 Cards"
                        />
                      )}

                      {allGames.find((element) => element === '625d525766b1a73d90b7f752') ? (
                        <option
                          className="selected-game-grn"
                          value="625d525766b1a73d90b7f752"
                          label="52 Cards Print"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="625d525766b1a73d90b7f752"
                          label="52 Cards Print"
                        />
                      )}

                      {allGames.find((element) => element === '625e870d85c46e39c0884f7b') ? (
                        <option
                          className="selected-game-grn"
                          value="625e870d85c46e39c0884f7b"
                          label="Triple Chance"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="625e870d85c46e39c0884f7b"
                          label="Triple Chance"
                        />
                      )}

                      {allGames.find((element) => element === '619b89ffb2e449216d0b970c') ? (
                        <option
                          className="selected-game-grn"
                          value="619b89ffb2e449216d0b970c"
                          label="Spin To Win"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="619b89ffb2e449216d0b970c"
                          label="Spin To Win"
                        />
                      )}

                      {allGames.find((element) => element === '625e886385c46e39c0884f7c') ? (
                        <option
                          className="selected-game-grn"
                          value="625e886385c46e39c0884f7c"
                          label="Print Triple Chance"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="625e886385c46e39c0884f7c"
                          label="Print Triple Chance"
                        />
                      )}

                      {allGames.find((element) => element === '62679a9a33c0d81c20065cc8') ? (
                        <option
                          className="selected-game-grn"
                          value="62679a9a33c0d81c20065cc8"
                          label="Spin To Win Print"
                          selected
                        />
                      ) : (
                        <option
                          className="selected-game-grn"
                          value="62679a9a33c0d81c20065cc8"
                          label="Spin To Win Print"
                        />
                      )}
                    </select>
                  </div>
                )}

                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        {/* <Button variant="warning" onClick={() => {
          props.editUsers(props.gameid,"","","","","","","","","","",
            softDelete
    );
    alert('Successfully Deleted');
    window.location.reload();
    setShow(false);
        }}>
           Delete
          </Button> */}
          <Button onClick={() => handleSubmit()} variant="success" disabled={loading}>
            {loading ? 'Loading...' : 'Submit'}
          </Button>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  message: state.users,
});
export default connect(mapStateToProps, { editUsers, allUsers })(EditUser);

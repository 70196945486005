import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';

function AdminDashboard(props) {
  return (
    <div>
      <Card className="card-align">
        <Card.Img variant="top" src={props.img} />
        <Card.Body>
          <Card.Title className="cntr">{props.title}</Card.Title>
          <Link to={props.url}>
            <Button variant="primary">
              GO <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AdminDashboard;

import React, { useEffect } from 'react';
import './styles.css';
import FullGameBoardBet from '../components/BettingData/FullGameBoardBet';
import FullLuckyCardBoard from '../components/BettingData/FullLuckyCardBoard';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { gameTypeBoard } from '../store/actions/bettingDataAction';
import FullFiftyTwoCardBoard from '../components/BettingData/FullFiftyTwoCardBoard';
import FullTripalChanceBoard from '../components/BettingData/FullTripalChanceBoard';
import FullSpinToWinBoard from '../components/BettingData/FullSpinToWinBoard';

const FullGameBoard = ({ gameTypeBoard, gameDetails }) => {
  const { id } = useParams();

  useEffect(() => {
    gameTypeBoard(id);
  }, [gameTypeBoard, id]);

  return (
    <>
            {gameDetails?.game === '611b69498a898d2aa6386cb3' && <FullGameBoardBet /> }
            {gameDetails?.game === '615ad717bfe7fcaa9cdd75e9' && <FullLuckyCardBoard /> }
            {gameDetails?.game === '619b8a1eb2e449216d0b970d' && <FullFiftyTwoCardBoard /> }
            {gameDetails?.game === '625e870d85c46e39c0884f7b' && <FullTripalChanceBoard /> }
            {gameDetails?.game === '619b89ffb2e449216d0b970c' && <FullSpinToWinBoard/> }
    </>
  );
};

const mapStateToProps = (state) => ({
  gameDetails: state.bettingData?.gameDetailsOnHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { gameTypeBoard })(FullGameBoard);

import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { connect } from 'react-redux';

function SideBarNav(props) {
  return (
    <>
      <nav className="main-side">
        <Link to="/" className="logo">
          <img src={`images/${props.logo}`} alt="Logo" />
        </Link>
        {localStorage.getItem('token') ? (
          <>
            {props.auth?.me?.role?.id === 1 ? (
              <>
                <Link to="/admingames">
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Manage All Games
                </Link>
                <Link to="/admin">
                  <i className="fa fa-dashboard"></i> Dashboard
                </Link>
                <Link to="/users">
                  <i className="fa fa-users" aria-hidden="true"></i> Users
                </Link>
                <Link to="/transactions">
                  <i className="fa fa-money" aria-hidden="true"></i> Transactions
                </Link>
                <Link to="/locked-devices">
                 <i className="fa fa-lock" aria-hidden="true"></i> Locked Devices
                </Link>
                <Link to="/betting">
                  <i className="fa fa-gamepad" aria-hidden="true"></i> Playing
                </Link>
                <Link to="/history">
                  <i className="fa fa-history" aria-hidden="true"></i> Game History
                </Link>
                <Link to="/inactive">
                  <i className="fa fa-ban" aria-hidden="true"></i> Inactive Users
                </Link>
                <Link to="/settings">
                  <i className="fa fa-cog" aria-hidden="true"></i> Settings
                </Link>
                <Link to="/tokens">
                  <i className="fa fa-key" aria-hidden="true"></i> Tokens
                </Link>
                <Link to="/turn-over">
                  <i className="fa fa-money" aria-hidden="true"></i> Turn Over
                </Link>
                <Link to="/change-password">
                  <i className="fa fa-key" aria-hidden="true"></i> Change Password
                </Link>
              </>
            ) : (
              ''
            )}
            {props.auth?.me?.role?.id === 2 ? (
              <>
                <Link to="/users">
                  <i className="fa fa-users" aria-hidden="true"></i> Users
                </Link>
                <Link to="/transactions">
                  <i className="fa fa-money" aria-hidden="true"></i> Transactions
                </Link>
                <Link to="/betting">
                  <i className="fa fa-gamepad" aria-hidden="true"></i> Playing
                </Link>
                <Link to="/turn-over">
                  <i className="fa fa-money" aria-hidden="true"></i> Turn Over
                </Link>
                <Link to="/change-password">
                  <i className="fa fa-key" aria-hidden="true"></i> Change Password
                </Link>
              </>
            ) : (
              ''
            )}
            {props.auth?.me?.role?.id === 3 ? (
              <>
                <Link to="/transactions">
                  <i className="fa fa-money" aria-hidden="true"></i> Transactions
                </Link>
                <Link to="/betting">
                  <i className="fa fa-gamepad" aria-hidden="true"></i> Playing
                </Link>
                <Link to="/turn-over">
                  <i className="fa fa-money" aria-hidden="true"></i> Turn Over
                </Link>
                <Link to="/change-password">
                  <i className="fa fa-key" aria-hidden="true"></i> Change Password
                </Link>
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </nav>
    </>
  );
}
const mapStateToProps = (state) => ({
  message: state.users.messages,
  roleUsers: state.users.roleUsers,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(SideBarNav);

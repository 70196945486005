import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getWallet, clearWalletError } from '../../store/actions/turnOverAction';
import './styles.css';
import { Button, Form } from 'react-bootstrap';
import { getGames } from '../../store/actions/gamesAction';

const TurnOverList = ({ getWallet, getGames, gamesList, turnOverDetails }) => {
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [userName, setuserName] = useState();
  const [gameId, setGameId] = useState();
  const [game, setGame] = useState();
  const [day, setDay] = useState();

  useEffect(() => {
    getWallet();
    getGames();
  }, [getWallet, getGames]);

  const autoFillDate = (e) => {
    let daysData = e.target.value;
    if (daysData === 'today') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'yesterday') {
      setDay(e.target.value);
      setendDate(moment().endOf('day').add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('day').add(-1, 'days').format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisWeek') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('week').add('d', 1).format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'lastWeek') {
      setDay(e.target.value);
      setendDate(
        moment().subtract(1, 'weeks').endOf('week').add('d', 1).format('YYYY-MM-DDTHH:mm:ss'),
      );
      setstartDate(
        moment().subtract(1, 'weeks').startOf('week').add('d', 1).format('YYYY-MM-DDT00:00:00'),
      );
    }
    if (daysData === 'thisMonth') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisLastMonth') {
      setDay(e.target.value);
      setendDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getWallet(startDate, endDate, userName, gameId, day, game);
  };
  let netLast = turnOverDetails.agent?.net;
  netLast = parseFloat(netLast);

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'Player ID', prop: 'playerid' },
    { title: 'Play Points', prop: 'playpoint' },
    { title: 'Win Ponits', prop: 'winPoint' },
    { title: 'End Points', prop: 'endPoint' },
    { title: 'Margin', prop: 'margin' },
    { title: 'Net', prop: 'net' },
  ];
  // Table Body Data
  let bodyData = [];

  if (turnOverDetails.bettings !== undefined) {
    bodyData = [
      turnOverDetails.bettings.map((users, index) => {
        let net = users?.net;
        net = parseFloat(net);

        return {
          srno: index + 1,
          playerid: <Link to={'/user-parent/' + users?.userid}>{users?.username}</Link>,
          playpoint: users?.betamount,
          winPoint: users?.winamount,
          endPoint: users?.endPoint.toFixed(2),
          margin: users?.margin,
          net: net.toFixed(2),
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={turnOverDetails.isLoading ? 'message loader' : 'message'}>
      <div className="row">
        <div className="col-md-4">
          <label>
            <b>Start Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={startDate}
            onChange={(e) => setstartDate(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label>
            <b>End Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={endDate}
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label>
            <b>User Name</b>
          </label>
          <Form.Control
            type="text"
            placeholder=""
            value={userName}
            onChange={(e) => setuserName(e.target.value)}
          />
        </div>
      </div>
      <div className="row  mrgn-btom">
        <div className="col-md-4">
          <label>
            <b>Day</b>
          </label>
          <br />
          <select name="days" className="select-days" value={day} onChange={autoFillDate}>
            <option value="">Select Days</option>
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="thisWeek">This Week</option>
            <option value="lastWeek">Last Week</option>
            <option value="thisMonth">This Month</option>
            <option value="thisLastMonth">This Last Month</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>
            <b>Game ID</b>
          </label>
          <Form.Control
            type="text"
            placeholder=""
            value={gameId}
            onChange={(e) => setGameId(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <label>
            <b>Game Name</b>
          </label>
          <select name="days" className="select-days" onChange={(e) => setGame(e.target.value)}>
            <option value="">Games</option>
            {gamesList.map(
              (games, index) =>

                !games?.name.match('Print') && !games?.name.match('jhandi munda') && !games?.status == false && <option value={games.id}>{games.name}</option>

            )}
          </select>
        </div>
        <div className="col-md-4 date-selector-btn tex-cntr">
          <Button variant="success" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col">
          <table>
            <tr>
              <th>Agent Name</th>
              <th>Play Points</th>
              <th>Win Points</th>
              <th>End Points</th>
              <th>Margin</th>
              <th>Net</th>
            </tr>
            <tr>
              <td>{turnOverDetails.agent?.agentName}</td>
              <td>{turnOverDetails.agent?.playpoint}</td>
              <td>{turnOverDetails.agent?.winPoint}</td>
              <td>{turnOverDetails.agent?.endTotal}</td>
              <td>{turnOverDetails.agent?.margin}</td>
              <td>{netLast.toFixed(2)}</td>
            </tr>
          </table>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col">
          <p className="bettings-turn-over">Bettings</p>
        </div>
      </div>

      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
      <div className="row turn-over-totl">
        <div className="col">
          <table>
            <tr>
              <td>
                <b>End Total:-</b> {turnOverDetails?.endTotal}
              </td>
              <td>
                <b>Total Bet:-</b> {turnOverDetails?.totalbet}
              </td>
              <td>
                <b>Total Win:-</b> {turnOverDetails?.totalwin}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  turnOverDetails: state.turnOver.turnOverData,
  gamesList: state.games?.games,
  auth: state.auth,
});

export default connect(mapStateToProps, { getWallet, getGames, clearWalletError })(TurnOverList);

import React, { useState, useEffect } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import {
  tripleChance,
  tripleChanceGameBet,
  tripleChanceToDayResult,
  tripleChanceGameBetId,
} from '../../store/actions/bettingDataAction';
import { Button } from 'react-bootstrap';

function TripleChanceGame(props) {
  const [jakPotNmbr, setjakPotNmbr] = useState('');
  const [manulNo, setManulNo] = useState();
  let gameCountr = props.tripleChnsCurntDetail?.counter;
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    props.tripleChanceToDayResult();
  }, [105 - counter === 1]);

  useEffect(() => {
    setCounter(gameCountr);
  }, [props.tripleChnsCurntDetail?.counter]);

  useEffect(() => {
    const intervalList = setTimeout(() => {
      if (counter) {
        setCounter(counter ? counter - 1 : gameCountr - 1);
      } else if (counter === 0) {
        return <>{setCounter(105)}</>;
      }
    }, 1000);
    return () => clearInterval(intervalList);
  }, [counter, gameCountr]);

  useEffect(() => {
    props.tripleChanceGameBetId();
    props.tripleChanceGameBet();
    const interval = setInterval(() => {
      props.tripleChanceGameBetId();
      props.tripleChanceGameBet();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    props.tripleChanceGameBetId();
  }, [105 - counter === 81]);

  const drownumbr = () => {
    props.tripleChance(manulNo, +jakPotNmbr);
    props.tripleChanceGameBet();
    props.tripleChanceToDayResult();
  };

  if (105 - counter === 0) {
    window.location.reload();
  }

  let proLoss =
    props.lctripleChnstodayResultData?.total?.[0]?.total_betting -
    props.lctripleChnstodayResultData?.total?.[0]?.total_winning;

  return (
    <div style={{ backgroundColor: 105 - counter > 74 && 105 - counter < 90 ? '#40d9c4' : '' }}>
      <div className="row ">
        <div className="col-md-6 disply-flx spcing-rmov">
          {jakPotNmbr === '0' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('0')}>
              1x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('0')}>
              1x
            </div>
          )}
          {jakPotNmbr === '2' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('2')}>
              2x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('2')}>
              2x
            </div>
          )}
          {jakPotNmbr === '3' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('3')}>
              3x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('3')}>
              3x
            </div>
          )}
          {jakPotNmbr === '4' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('4')}>
              4x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('4')}>
              4x
            </div>
          )}
          {jakPotNmbr === '5' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('5')}>
              5x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('5')}>
              5x
            </div>
          )}
          {jakPotNmbr === '6' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('6')}>
              6x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('6')}>
              6x
            </div>
          )}
          {jakPotNmbr === '7' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('7')}>
              7x
            </div>
          ) : (
            <div className="col jak-port-tab " onClick={() => setjakPotNmbr('7')}>
              7x
            </div>
          )}
          {jakPotNmbr === '8' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('8')}>
              8x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('8')}>
              8x
            </div>
          )}
        </div>
        <div className="col-md-6 disply-flx spcing-rmov">
          <div className="col-md-4 declr-nmbr">
            JackPot:-{' '}
            <input
              className="jak-pot-nmbr"
              type="text"
              value={jakPotNmbr}
              onChange={(e) => setjakPotNmbr(e.target.value)}
            />
          </div>
          <div className="col-md-4 declr-nmbr nmbr-logo">
            Number:-{' '}
            <input
              className="jak-pot-nmbr"
              type="text"
              value={manulNo}
              onChange={(e) => setManulNo(e.target.value)}
            />
          </div>
          <div className="col-md-4 tex-cntr">
            {105 - counter < 88 ? (
              <Button className="sbmt-btn" onClick={drownumbr} variant="warning">
                <b>Submit</b>
              </Button>
            ) : (
              <Button className="sbmt-btn" onClick={drownumbr} variant="warning" disabled>
                <b>Please Wait</b>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Counter</b> : <span>{105 - counter ? 105 - counter : '0'}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Playing</b> : <span>{props.lastTenResult?.total?.[0]?.total_betting}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Winning</b> : <span>{props.lastTenResult?.total?.[0]?.total_winning}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Profit/Loss</b> :{' '}
              <b>
                <span>{proLoss.toFixed(2)}</span>
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="row manual-numbr">
        <div className="col-md-3">
          <p className="mrgn-0 font-lucky-card">
            Current Game Total Playing:-{' '}
            {props.tripleChancecurrentGameId?.totalbetamount
              ? props.tripleChancecurrentGameId?.totalbetamount
              : '0'}
          </p>
        </div>
        <div className="col-md-3">
          <p className="mrgn-0 font-lucky-card">
            Get Manual Number Before <b>87</b>.
          </p>
        </div>
        <div className="col-md-6">
          <p className="mrgn-0 latest-nmbr-detal nmbr-wth-jkpot">
            {!!props &&
              props.lastTenResult &&
              props?.lastTenResult?.numbers?.length > 0 &&
              props.lastTenResult.numbers.map((data, index) => {
                return (
                  <>
                    <p className="jackpot-number-luckycard">
                      {data.number}
                      <span className={data.jackpot !== 0 && 'jk-highlighter'}>
                        {data.jackpot === 0 ? '' : data.jackpot + 'x'}
                      </span>
                      ,
                    </p>
                  </>
                );
              })}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7">
          {!!props &&
            !!props?.jackPotAmount &&
            Object.entries(props?.jackPotAmount).map(([key, value]) => {
              if (manulNo == key) {
                return (
                  <div className="row jackPot-amount-dtl">
                    <div className="col short-dtl-tbl">
                      {jakPotNmbr * value ? jakPotNmbr * value : value}
                    </div>
                  </div>
                );
              }
            })}

          <div className="row">
            <div className="col-md-4">
              <div className="blue-bg-whtext">Single Number</div>
              <table className="game-board-winTbl">
                <tr>
                  <th>Number</th>
                  <th>Playing Amount</th>
                </tr>
                {props.tripleChancecurrentGameId?.numbers !== undefined &&
                  Object.entries(props.tripleChancecurrentGameId?.numbers).map(([key, value]) => {
                    return (
                      <>
                        {key.length === 1 && (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
            <div className="col-md-4">
              <div className="blue-bg-whtext">Double Number</div>
              <table className="game-board-winTbl">
                <tr>
                  <th>Number</th>
                  <th>Playing Amount</th>
                </tr>
                {props.tripleChancecurrentGameId?.numbers !== undefined &&
                  Object.entries(props.tripleChancecurrentGameId?.numbers).map(([key, value]) => {
                    return (
                      <>
                        {key.length === 2 && (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
            <div className="col-md-4">
              <div className="blue-bg-whtext">Triple Number</div>
              <table className="game-board-winTbl">
                <tr>
                  <th>Number</th>
                  <th>Playing Amount</th>
                </tr>
                {props.tripleChancecurrentGameId?.numbers !== undefined &&
                  Object.entries(props.tripleChancecurrentGameId?.numbers).map(([key, value]) => {
                    return (
                      <>
                        {key.length === 3 && (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <table className="game-board-winTbl lucky-card-game-board">
            <tr>
              <th>Username</th>
              <th>Bet Amount</th>
              <th>Sale</th>
              <th>Loss/Profit</th>
              <th>Number</th>
            </tr>
            {!!props.tripleChancecurrentGameUsers &&
              props.tripleChancecurrentGameUsers.map((data, index) => {
                return (
                  <tr>
                    <td>{data.username}</td>
                    <td>{data.betamount}</td>
                    <td>{data.daily_play_points}</td>
                    <td>{data.daily_play_points - data.daily_winning_points}</td>
                    <td>
                      {Object.entries(data.numbers).map(([key, value]) => {
                        return (
                          <>
                            <span className="multi-numbr-border">
                              {key} : {<span className="jk-highlighter">{value}</span>}
                            </span>
                          </>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tripleChnsCurntDetail: state.bettingData?.tripleChnscurrentGame,
  lctripleChnstodayResultData: state.bettingData?.tripleChancetodayResult,
  lastTenResult: state.bettingData?.tripleChancetodayResult,
  tripleChancecurrentGameId: state.bettingData?.tripleChancecurrentGameId,
  tripleChancecurrentGameUsers: state.bettingData?.tripleChancecurrentGameId?.users,
  stripNumn: state.bettingData?.tripleChancecurrentGameId?.numbers,
  jackPotAmount: state.bettingData?.tripleChancecurrentGameId?.winamount_number,
  lcManulNo: state.bettingData.error,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  tripleChance,
  tripleChanceGameBet,
  tripleChanceToDayResult,
  tripleChanceGameBetId,
})(TripleChanceGame);

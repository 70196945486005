import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { placedUserBet } from '../../store/actions/bettingDataAction';
import './styles.css';
import moment from 'moment';

const UserGameBoard = ({ winDetailData, placedUserBet, userBetNumber, userbetlist, betHistory }) => {
  const { gameid, id , username} = useParams();
  useEffect(() => {
    placedUserBet(gameid, id);
  }, [placedUserBet, gameid, id ]);

  let winn = winDetailData?.winning;
  return (
    <div className="">
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-1 pding-rmov">
            <div className="row bord-zero left-redus-bordr-top">
              <div className="col-md-12 bord-text-zero">
                <span className="board-numb-left-top">00</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['00'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['00']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row bord-zero left-redus-bordr-botm">
              <div className="col-md-12 bord-text-zero">
                <span className="board-numb-left">0</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['0'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['0']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="col-md-10 pding-rmov">
            <div className="row">
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">3</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['3'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['3']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">6</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['6'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['6']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">9</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['9'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['9']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">12</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['12'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['12']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">15</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['15'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['15']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">18</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['18'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['18']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">21</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['21'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['21']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">24</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['24'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['24']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">27</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['27'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['27']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">30</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['30'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['30']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">33</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['33'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['33']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">36</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['36'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['36']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">2</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['2'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['2']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">5</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['5'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['5']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">8</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['8'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['8']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">11</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['11'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['11']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">14</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['14'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['14']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">17</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['17'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['17']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">20</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['20'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['20']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">23</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['23'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['23']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">26</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['26'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['26']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">29</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['29'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['29']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">32</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['32'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['32']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">35</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['35'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['35']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">1</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['1'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['1']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">4</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['4'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['4']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">7</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['7'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['7']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">10</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['10'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['10']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">13</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['13'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['13']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">16</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['16'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['16']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">19</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['19'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['19']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">22</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['22'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['22']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">25</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['25'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['25']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">28</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['28'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['28']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red num-black">31</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['31'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['31']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr">
                <span className="board-numb-red">34</span>
                {userBetNumber !== undefined ? (
                  userBetNumber['34'] === 0 ? null : (
                    <span className="bet-numb-point">{userBetNumber['34']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="col-md-1 pding-rmov mrgn-top-rit">
            <div className="row right-bord-box">
              <div className="col-md-12 bord-text-cntr-right">
                <span className="board-numb-right">C3</span>
              </div>
            </div>
            <div className="row right-bord-box">
              <div className="col-md-12 bord-text-cntr-right">
                <span className="board-numb-right">C2</span>
              </div>
            </div>
            <div className="row right-bord-box">
              <div className="col-md-12 bord-text-cntr-right">
                <span className="board-numb-right">C1</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 pding-rmov">
            <div className="row">
              <div className="col-md-4 bordr-col-btns">1st 12</div>
              <div className="col-md-4 bordr-col-btns">2nd 12</div>
              <div className="col-md-4 bordr-col-btns">3rd 12</div>
            </div>
            <div className="row">
              <div className="col-md-2 bordr-col-btns">01 - 18</div>
              <div className="col-md-2 bordr-col-btns">EVEN</div>
              <div className="col-md-2 bordr-col-btns">
                <span className="retcngl-btn-botm"></span>
              </div>
              <div className="col-md-2 bordr-col-btns">
                <span className="retcngl-btn-botm-black"></span>
              </div>
              <div className="col-md-2 bordr-col-btns">ODD</div>
              <div className="col-md-2 bordr-col-btns">19 - 36</div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <div class="text-center"><b>User Name</b>:- {username}</div>
      <div class="text-center"><b>Game ID</b>:- {id}</div>
      <div className="row manual-numbr mrg-top-dtd">
        <div className="col-md-6">
        <b>Start</b>:- {moment(betHistory?.start).format('DD/MM/YYYY hh:mm:ss A')}
        </div>
        <div className="col-md-6">
        <b>End</b>:- 
              {moment(betHistory?.end).format('DD/MM/YYYY hh:mm:ss A')}
        </div>
      </div>
      <div className="row manual-numbr mrg-top-dtd">
        <div className="col-md-6">
          <b>BetTotal</b>:- {winDetailData?.betTotal}
        </div>
        <div className="col-md-6">
          <b>Winning Number</b>:- {winDetailData?.winningNumber}
        </div>
      </div>
      <div className="row manual-numbr mrg-top-dtd">
        <div className="col-md-6">
          <b>Jackpot</b>:- {winDetailData?.jackpot}
        </div>
        <div className="col-md-6">
          <b>Winning Amount</b>:- {winn}
        </div>
      </div>
      <div className="row txt-cntr mrg-top-dtd hdr-bg">
        <div className="col-md-6">
          <b>Betting Type</b>
        </div>
        <div className="col-md-6">
          <b>Amount</b>
        </div>
      </div>
      {userbetlist !== undefined
        ? Object.keys(userbetlist).map((key) => {
            return (
              <>
                <div className="row txt-cntr betting-type-dta">
                  <div className="col-md-6">{key}</div>
                  <div className="col-md-6">{userbetlist[key].toFixed(2)}</div>
                </div>
              </>
            );
          })
        : ''}
    </div>
  );
};

const mapStateToProps = (state) => (
  {
  userBetNumber: state.bettingData.userPlaced,
  userbetlist: state.bettingData?.bettingType,
  winDetailData: state.bettingData?.winDetailUser,
  betHistory: state.bettingData?.gameDetailsOnHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { placedUserBet })(UserGameBoard);

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { transaction, clearUsersError } from '../../store/actions/usersAction';
import './styles.css';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  ADD_TRAN_SUCCESS
} from '../../store/types';

const UserTransactionList = ({ transaction, transactionUser, blankData }) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: ADD_TRAN_SUCCESS,
      payload: { transactionUser: []
     },
    });
    transaction(id);
  }, [transaction, id, dispatch]);

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'Comment', prop: 'comment'},
    { title: 'Create', prop: 'start' },
    { title: 'From User Name', prop: 'fromUser' },
    { title: 'Remaining Coins', prop: 'remaining_coins' },
    { title: 'Trans Coins', prop: 'trans_coins' },
  ];

  let bodyData = [];

  if (transactionUser !== undefined) {
    bodyData = [
      transactionUser.map((users, index) => {
        return {
          srno: index + 1,
          comment: users?.comment,
          start: moment(users?.createdAt).format('DD/MM/YYYY hh:mm:ss'),
          fromUser: users?.fromUser?.username,
          remaining_coins: users?.remaining_coins.toFixed(2),
          trans_coins: users?.trans_coins.toFixed(2),
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    transaction(id, currentPage);
  }

  return (
    <div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length ? bodyData[0] : []}
        classes={classes}
      />
      {
        !!blankData && !!blankData.length > 0 ?
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>Load More.....</button>
          </div>
        </div>
         :
         ""
      }
    </div>
  );  
};

const mapStateToProps = (state) => ({
  transactionUser: state.users?.transactionUser,
  blankData: state.users.blankData,
  auth: state.auth,
});

export default connect(mapStateToProps, { transaction, clearUsersError })(UserTransactionList);

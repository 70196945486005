import React from 'react';
import { Spinner } from 'react-bootstrap';

import './styles.css';

const Loader = (props) => {
  return (
    <div className="loader-container loader" {...props}>
      <Spinner animation="grow" variant="info" />
      {/* <h3 className="loader-content">Loading..</h3> */}
    </div>
  );
};

export default Loader;

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { logOutUser } from '../../store/actions/authActions';
import './styles.css';
import MainProfile from './MainProfile';

const Navbar = ({ auth, logOutUser, history, gameManagement, dashboardLucky, logo }) => {
  const onLogOut = (event) => {
    event.preventDefault();
    logOutUser(history);
  };

  let profileicon = <i className="fa fa-user" aria-hidden="true"></i>;

  return (
    <>
      <div className="top-nav-bar">
        <div className="row pb-1 pt-1">
          <div className="col-md-4 nav-title">
            {/* <Link to="/" className="logo">
              <img src={`images/${logo}`} alt='Logo'/>
            </Link> */}
          </div>
          {/* <div className="col-md-2 nav-title lucky-card-t-b">
            <Link to="/admingames" className="log-out-btn">
              {gameManagement}
            </Link>
          </div>
          <div className="col-md-1 nav-title lucky-card-t-b">
            <Link to="/admin" className="log-out-btn">
            {dashboardLucky}
            </Link>
          </div> */}
          <div className="col-md-4 nav-title text-center">
            <span className="nav-item lucky-card-t-b">
            <i className="fa fa-money" aria-hidden="true"></i> {auth?.me?.coins}
            </span>
          </div>
          <div className="col-md-4 nav-title">
            <Link to="/">
              <span className="nav-item log-out-btn-right" onClick={onLogOut}>
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
              </span>
            </Link>
            <span className="nav-item log-out-btn-right">
              <MainProfile profileicon={profileicon} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(Navbar);

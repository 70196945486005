import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import './app.css';
import Login from './pages/Login/Login';
import Navbar from './components/Navbar/Navbar';
import SideBarNav from './components/Navbar/SideBarNav';
import { logInUserWithOauth, loadMe } from './store/actions/authActions';
import Dashboard from './layout/Dashboard';
import ThemeCustomMode from './ThemeCustomMode';
import AdminGames from './layout/AdminGames';
import GameBoard from './layout/GameBoard';
import FullGameBoard from './layout/FullGameBoard';
import UsersList from './components/Users/UsersList';
import WalletTrans from './components/Wallet/WalletTrans';
import HistoryList from './components/History/HistoryList';
import AgentList from './components/Users/AgentList';
import BettingList from './components/BettingData/BettingList';
import SettingList from './components/Settings/SettingList';
import TokensList from './components/Tokens/TokensList';
import UserTransactionList from './components/Users/UserTransactionList';
import UserBetting from './components/Users/UserBetting';
import UserParentList from './components/Users/UserParentList';
import CurrentGame from './components/BettingData/CurrentGame';
import LuckyCardCurrent from './components/BettingData/NewLuckycardComponent';
import BattingAmount from './components/History/BattingAmount';
import HistoryUserList from './components/History/HistoryUserList';
import WinningList from './components/Winning/WinningList';
import InactiveList from './components/Inactive/InactiveList';
import TurnOverList from './components/TurnOver/TurnOverList';
import ChangePasswordView from './components/Users/ChangePasswordView';
import BettingsUsersList from './components/Betting/BettingList';
import Loader from './components/Loader/Loader';
import FullWidthGamesMenu from './components/Navbar/FullWidthGamesMenu';
import FiftyTwoCardsGame from './components/BettingData/FiftyTwoCardsGame';
import SpinToWinGame from './components/BettingData/SpinToWinGame';
import { getDashboard } from './store/actions/dashboardAction';
import TripleChanceGame from './components/BettingData/TripleChanceGame';
import LockedDevices from './components/lockeddevices/LockedDevices';
// Theme Mode
const GlobalStyle = createGlobalStyle`
body{
  background-color: ${(props) => (props.theme.mode === 'dark' ? '#242424' : '#d7d5d5')};
color : ${(props) => (props.theme.mode === 'dark' ? '#fff' : '#111')};
}`;

// Main component
const App = ({ logInUserWithOauth, auth, loadMe, getDashboard, appLogo }) => {
  const [theme, setTheme] = useState({ mode: 'light' });
  useEffect(() => {
    loadMe();
    getDashboard();
  }, [loadMe, getDashboard]);

  //redosled hookova
  useEffect(() => {
    if (window.location.hash === '#_=_') window.location.hash = '';

    const cookieJwt = Cookies.get('x-auth-cookie');
    if (cookieJwt) {
      Cookies.remove('x-auth-cookie');
      logInUserWithOauth(cookieJwt);
    }
  });

  useEffect(() => {
    if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
      loadMe();
    }
  }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ThemeCustomMode
          themeBtn={
            <span
              className="nav-item"
              onClick={(e) =>
                setTheme(theme.mode === 'dark' ? { mode: 'light' } : { mode: 'dark' })
              }
            ></span>
          }
        />
        {auth.appLoaded && auth.token?.length > 0 ? (
          <>
            <div>
              {window.location.pathname === '/lucky-card' ||
              window.location.pathname === '/fifty-two-cards' ||
              window.location.pathname === '/spin-to-win' ||
              window.location.pathname === '/triple-chance' ? (
                <>
                  <div className="row">
                    <div className="col-md-12 pding-rmov">
                      <FullWidthGamesMenu logo={appLogo}/>
                    </div>
                  </div>
                  <div class="col-md-12 full-width-game-board">
                    <Switch>
                      <Route exact path="/lucky-card" component={LuckyCardCurrent} />
                      <Route exact path="/fifty-two-cards" component={FiftyTwoCardsGame} />
                      <Route exact path="/spin-to-win" component={SpinToWinGame} />
                      <Route exact path="/triple-chance" component={TripleChanceGame} />
                    </Switch>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-2 pding-rmov sidebar-menu">
                        <SideBarNav logo={appLogo}/>
                      </div>
                    <div className="col-md-10 pding-rmov side-menu-spec over-flow-scrlling">
                      <Navbar logo={appLogo}/>
                      <Switch>
                          <Route path="/login" component={Login} />
                          <Route exact path="/admingames" component={AdminGames} />
                          <Route exact path="/transactions" component={WalletTrans} />
                          <Route exact path="/history" component={HistoryList} />
                          <Route exact path="/users" component={UsersList} />
                          <Route exact path="/locked-devices" component={LockedDevices} />
                          <Route exact path="/" component={UsersList} />
                          <Route exact path="/agents" component={AgentList} />
                          <Route exact path="/betting" component={BettingList} />
                          <Route exact path="/betting-types" component={BettingsUsersList} />
                          <Route exact path="/settings" component={SettingList} />
                          <Route exact path="/tokens" component={TokensList} />
                          <Route exact path="/user-transaction/:id" component={UserTransactionList} />
                          <Route exact path="/user-betting/:id" component={UserBetting} />
                          <Route exact path="/parent-customer-list/:id" component={UserBetting} />
                          <Route exact path="/user-parent/:id" component={UserParentList} />
                          <Route exact path="/user-game-board/:gameid/:id/:username" component={GameBoard}/>
                          <Route exact path="/game-board/:id" component={FullGameBoard} />
                          <Route exact path="/current-game" component={CurrentGame} />
                          <Route exact path="/betting-total-amount" component={BattingAmount} />
                          <Route exact path="/history-user/:id" component={HistoryUserList} />
                          <Route exact path="/winning" component={WinningList} />
                          <Route exact path="/inactive" component={InactiveList} />
                          <Route exact path="/turn-over" component={TurnOverList} />
                          <Route exact path="/change-password" component={ChangePasswordView} />
                          <Route exact path="/admin" component={Dashboard} />
                        </Switch>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Switch>
            <Route exact path="/" component={Login} />
            <Route component={Loader} />
          </Switch>
        )}
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => (
  {
  dashboardDataBlock: state.getDashboard.dashboardData,
  appLogo: state?.getDashboard?.webAppLogo,
  auth: state.auth,
});

export default compose(connect(mapStateToProps, { logInUserWithOauth, loadMe, getDashboard }))(App);

import {
  GET_BETTINGDATA_LOADING,
  GET_BETTINGDATA_SUCCESS,
  GET_BETTINGDATA_FAIL,
  GET_CURRENTGAMEDETAIL_LOADING,
  GET_CURRENTGAMEDETAIL_SUCCESS,
  GET_CURRENTGAMEDETAIL_FAIL,
  GET_CURRENTRESULT_LOADING,
  GET_CURRENTRESULT_SUCCESS,
  GET_CURRENTRESULT_FAIL,
  GET_CURRENTRESULTIMER_LOADING,
  GET_CURRENTRESULTIMER_SUCCESS,
  GET_CURRENTRESULTIMER_FAIL,
  CLEAR_BETTINGDATA_ERROR,
} from '../types';

const initialState = {
  bettingData: [],
  userPlaced: [],
  gamePlaced: [],
  gamePlacedList: [],
  bettingType: [],
  fullGameBordData: [],
  currentGameData: [],
  currentGameId: [],
  todayResult: [],
  luckyCardJakPort: [],
  lcManualNmbr: [],
  tripleChnaceManualNmbr: [],
  fiftyToManualNmbr: [],
  lccurrentGame: [],
  tripleChnscurrentGame: [],
  lctodayResult: [],
  tripleChancetodayResult : [],
  lccurrentGameId: [],
  tripleChancecurrentGameId: [],
  gameDetailsOnHistory: [],
  winDetailUser: [],
  jakPotWinAmount: [],
  nagtvrows: [],
  fiftyTwoCardCurrent: [],
  fiftytoCdayResult: [],
  stwdayResult: [],
  isLoading: false,
  error: null,
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_CURRENTRESULTIMER_LOADING:
    case GET_CURRENTRESULT_LOADING:
    case GET_CURRENTGAMEDETAIL_LOADING:
    case GET_BETTINGDATA_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    // case GET_BETTINGDATA_LOADING:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    case GET_BETTINGDATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bettingData: payload.bettingData,
        nagtvrows: payload.nagtvMenu,
        luckyCardJakPort: payload.luckyCardbettingData,
        lcManualNmbr: payload.lcManualNmbr,
        tripleChnaceManualNmbr: payload.tripleChnaceManualNmbr,
        fiftyToManualNmbr: payload.fiftyToManualNmbr,
        lcmnulnmbrError: payload.lcmnulnmbrError,
        lcJakPotError: payload.lcJakPotError,
        userPlaced: payload.userPlaced,
        gamePlaced: payload.gamePlaced,
        gamePlacedList: payload.gamePlacedList,
        fullGameBordData: payload.fullGameBordData,
        bettingType: payload.bettingType,
        winDetailUser: payload.winDetailUser,
        currentGameData: payload.currentGame,
        currentGameId: payload.currentGameId,
        lccurrentGameId: payload.lccurrentGameId,
        tripleChancecurrentGameId: payload.tripleChancecurrentGameId,
        jakPotWinAmount: payload.jakPotWinAmount,
        blankPlaying: payload.blankPlaying,
        fiftyTwoCardCurrent: payload.fiftyTwocurrentGameId,
        spinToWingCardCurrent: payload.spintoWincurrentGameId,
      };
    case GET_CURRENTRESULTIMER_SUCCESS:
      return {
        ...state,
        lccurrentGame: payload.lccurrentGame,
        tripleChnscurrentGame: payload.tripleChancecurrentGame
      };
    case GET_CURRENTRESULT_SUCCESS:
      return {
        ...state,
        todayResult: payload.todayResult,
        lctodayResult: payload.lctodayResult,
        tripleChancetodayResult: payload.tripleChnstodayResult,
        fiftytoCdayResult: payload.fiftytodayResult,
        stwdayResult: payload.spinToWintodayResult,
      };
    case GET_CURRENTGAMEDETAIL_SUCCESS:
      return {
        ...state,
        gameDetailsOnHistory: payload.gameDetailsData,
      };
    case GET_CURRENTRESULTIMER_FAIL:
      return {
        ...state,
        isLoading: false,
        lccurrentGame: [],
        tripleChnscurrentGame:[],
        fiftytoCdayResult: [],
      };
    case GET_BETTINGDATA_FAIL:
      return {
        ...state,
        isLoading: false,
        bettingData: [],
        nagtvrows: [],
        userPlaced: [],
        bettingType: [],
        winDetailUser: [],
        gamePlaced: [],
        gamePlacedList: [],
        fullGameBordData: [],
        currentGameData: [],
        lccurrentGame: [],
        tripleChnscurrentGame: [],
        currentGameId: [],
        todayResult: [],
        luckyCardJakPort: [],
        lcManualNmbr: [],
        tripleChnaceManualNmbr: [],
        lctodayResult: [],
        tripleChancetodayResult: [],
        lccurrentGameId: [],
        tripleChancecurrentGameId: [],
        lcmnulnmbrError: [],
        lcJakPotError: [],
        jakPotWinAmount: [],
        fiftyTwoCardCurrent: [],
        error: payload,
      };
    case GET_CURRENTGAMEDETAIL_FAIL:
      return {
        ...state,
        gameDetailsOnHistory: [],
      };
    case GET_CURRENTRESULT_FAIL:
      return {
        ...state,
        todayResult: [],
        lctodayResult: [],
        tripleChancetodayResult : []
      };
    case CLEAR_BETTINGDATA_ERROR:
      return {
        ...state,
        bettingData: state.bettingData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Datatable from 'react-bs-datatable';
import {
  searchUser,
  allUsers,
  errorStatusShow,
  logOutSUser,
  getSelectAgents,
} from '../../store/actions/usersAction';
import Profile from '../Navbar/Profile';
import './styles.css';
import { getDashboard } from '../../store/actions/dashboardAction';
import AddUser from '../../pages/Users/AddUser';
import EditUser from '../../pages/Users/EditUser';
import AddWallet from '../../pages/Wallet/AddWallet';
import SubWallet from '../../pages/Wallet/SubWallet';
import LogoutUser from '../../pages/Wallet/LogoutUser';
import { ADD_USERS_SUCCESS } from '../../store/types';

const UsersList = ({
  blankmsg,
  selectAgentsData,
  allUsers,
  searchUser,
  roleIDLoad,
  roleUsers,
  dashboardDataBlock,
  message,userAllDetails,
  getDashboard,
  getSelectAgents,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchusername, setSearchusername] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: ADD_USERS_SUCCESS,
      payload: { message: [] },
    });
    allUsers();
    getDashboard();
    getSelectAgents();
  }, [allUsers, getDashboard, getSelectAgents, dispatch]);

  // ALL Users
  const allUsersGet = (e) => {
    e.preventDefault();
    dispatch({
      type: ADD_USERS_SUCCESS,
      payload: { message: [] },
    });
    setCurrentPage(1);
    allUsers(1);
  };
  // ALL Users
  const allAgents = () => {
    dispatch({
      type: ADD_USERS_SUCCESS,
      payload: { message: [] },
    });
    setCurrentPage(1);
    allUsers(2);
  };
  // ALL Users
  const allCustomer = (e) => {
    e.preventDefault();
    dispatch({
      type: ADD_USERS_SUCCESS,
      payload: { message: [] },
    });
    setCurrentPage(1);
    allUsers(3);
  };

  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    allUsers(roleIDLoad, currentPage);
  };

  const searchData = () => {
    dispatch({
      type: ADD_USERS_SUCCESS,
      payload: { message: [] },
    });
    searchUser('', '', searchusername);
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      searchData('', '', searchusername);
    }
  };

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'UserName', prop: 'username' },
    { title: 'Agent', prop: 'parentName' },
    { title: 'Activity', prop: 'activity' },
    { title: 'AppVersion', prop: 'appversion' },
    { title: 'Points', prop: 'coin' },
    { title: 'Game', prop: 'date' },
    { title: 'Comision', prop: 'commission' },
    { title: 'Trans', prop: 'tran' },
    { title: 'Playing', prop: 'betting' },
    { title: 'Profit/Loss', prop: 'profitloss' },
    { title: 'Wallet', prop: 'wallet' },
    { title: 'Role', prop: 'role' },
    { title: 'Status', prop: 'userstatus' },
    { title: 'Actions', prop: 'action' },
    { title: 'Logout', prop: 'logout' },
  ];
  let gameadd = 'ADD NEW USER';
  let gameprofileView = <i className="fa fa-eye view-icon" aria-hidden="true"></i>;
  
  
  

  // Table Body Data
  let bodyData = [];
  if (roleUsers !== undefined) {
    bodyData = [
      roleUsers.map((users, index) => {
        let tran = (
          <Link to={'/user-transaction/' + users?.id} variant="info" className="">
            <i className="fa fa-money" aria-hidden="true"></i>
          </Link>
        );
        let bettings = (
          <Link to={'/user-betting/' + users?.id} variant="info" className="">
            {users.daily_play_points.toFixed(2)}
          </Link>
        );
        let agentName = users?.parent?.username ? (
          <Link to={'/user-parent/' + users?.parent?.id}>{users?.parent?.username}</Link>
        ) : (
          'Admin'
        );
        let logOutUser = [users?.username === 'admin' ? '' : <LogoutUser userid={users?.id} />];

        let wallet = [
          <div className="cntr-text">
            <AddWallet
              apiReload={allUsers}
              errorStatus={errorStatusShow}
              username={users?.username}
              userid={users?.id}
              customClass={'sub-tbl-btn betting-btn btn-spac'}
              title={'ADD Amount'}
              icon={<i className="fa fa-plus" aria-hidden="true"></i>}
            />
            {users?.username === 'admin' ? (
              ''
            ) : (
              <SubWallet
                apiReload={allUsers}
                username={users?.username}
                userid={users?.id}
                customClass={'sub-tbl-btn wallet-btn btn-spac'}
                title={'Withdrawal Amount'}
                icon={<i className="fa fa-minus" aria-hidden="true"></i>}
              />
            )}
          </div>,
        ];
        let deleteicon = [
          <Profile
            profileicon={gameprofileView}
            coins={users?.coins.toFixed(2)}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role?.name}
          />,
          <EditUser
            gameid={users?.id}
            name={users?.name}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role}
            commisionAmount={users?.commission}
            games={users?.games}
            agentCreate={users?.canAgentCreateUser}
            softdlt={users?.softDelete}
          />,
        ];
        let userView = (
          <Profile
            coins={users?.coins}
            profileicon={users?.username}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role?.name}
          />
        );
        //data.daily_winning_points - data.daily_play_points;
        let profit = users.daily_winning_points - users.daily_play_points;
        let updateTime = new Date(users?.updatedAt);
        let currentTime = new Date();
              
        let diffInSeconds = ( currentTime - updateTime) / 1000;
        
        return {
          srno: index + 1,
          username: userView,
          parentName: agentName,
          date: users.current_game === '611b69498a898d2aa6386cb3' ? 'Roulette' : 'Lucky Card',
          activity:
          diffInSeconds > 300 ? (
              <b className="role-base-comision">OFFLINE</b>
            ) : (
              <span>
                <b className="required-scus-red">ONLINE</b>
              </span>
            ),
          appversion:users?.appversion,  
          coin: users?.coins,
          commission: users?.commission + '%',
          tran: tran,
          betting: bettings,
          profitloss: profit.toFixed(1),
          wallet: wallet,
          role: users?.role?.name,
          userstatus: users.status ? 'Unblock' : 'Block',
          action: deleteicon,
          logout: logOutUser,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={message.isLoading ? 'message loader' : 'message'}>
      <div>
       {!!userAllDetails?.canAgentCreateUser && userAllDetails.canAgentCreateUser ? <AddUser addGame={gameadd} showAgentsList={selectAgentsData} /> : ""} 
        <button onClick={allUsersGet} type="button" className="btn btn-primary btns-width">
          ALL
        </button>
        <button onClick={allAgents} type="button" className="btn btn-danger btns-width">
          AGENTS
        </button>
        <button onClick={allCustomer} type="button" className="btn btn-info btns-width">
          USERS
        </button>
      </div>
      <div className="row">
        <div className="col-md-5 form-group search-input-usr">
          <input
            onChange={(e) => setSearchusername(e.target.value)}
            onKeyPress={handleKeypress}
            type="text"
            className="form-control"
            placeholder="Search User Name"
          />
          <button onClick={searchData} className="btn btn-success">
            Search
          </button>
        </div>
        <div className="col cntr mt-2">
          <h4>
            Agent: {dashboardDataBlock?.[2]?.[0]?.agents ? dashboardDataBlock?.[2]?.[0]?.agents : 0}
          </h4>
        </div>
        <div className="col cntr mt-2">
          <h4>
            Customer:{' '}
            {dashboardDataBlock?.[3]?.[0]?.customers ? dashboardDataBlock?.[3]?.[0]?.customers : 0}
          </h4>
        </div>
      </div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
      {!!blankmsg && !!blankmsg.length > 0 ? (
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>
              Load More.....
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state) => (
  console.log("state", state),
  {
  message: state.users.messages,
  blankmsg: state.users.blankmsg,
  dashboardDataBlock: state.getDashboard.dashboardData,
  roleUsers: state.users.roleUsers,
  roleIDLoad: state?.users?.roleIDBase,
  userAllDetails: state?.auth?.me,
  selectAgentsData: state.users.selectAgentsData,
});

export default connect(mapStateToProps, {
  searchUser,
  logOutSUser,
  allUsers,
  getDashboard,
  getSelectAgents,
})(UsersList);

import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_GAMES_LOADING,
  GET_GAMES_SUCCESS,
  GET_GAMES_FAIL,
  ADD_GAMES_LOADING,
  ADD_GAMES_SUCCESS,
  ADD_GAMES_FAIL,
  DELETE_GAMES_LOADING,
  DELETE_GAMES_SUCCESS,
  DELETE_GAMES_FAIL,
  EDIT_GAMES_LOADING,
  EDIT_GAMES_SUCCESS,
  EDIT_GAMES_FAIL,
  CLEAR_GAMES_ERROR,
} from '../types';

export const getGames = () => async (dispatch, getState) => {
  dispatch({
    type: GET_GAMES_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/games', options);
    dispatch({
      type: GET_GAMES_SUCCESS,
      payload: { games: response.data.games },
    });
  } catch (err) {
    dispatch({
      type: GET_GAMES_FAIL,
      payload: { error: err?.response?.data.games || err.games },
    });
  }
};
export const addGames =
  (id, name, username, email, phone, role, password) => async (dispatch, getState) => {
    dispatch({
      type: ADD_GAMES_LOADING,
      payload: { me: { ...getState().auth.me } },
    });
    try {
      const formData = { id, name, username, email, phone, role, password };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/games', formData, options);

      dispatch({
        type: ADD_GAMES_SUCCESS,
        payload: { message: response.data.message },
      });
    } catch (err) {
      dispatch({
        type: ADD_GAMES_FAIL,
        payload: { error: err?.response?.data.message || err.message },
      });
    }
  };

export const deleteGames = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_GAMES_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`/api/games/${id}`, options);
    dispatch({
      type: DELETE_GAMES_SUCCESS,
      payload: { message: response.data.message },
    });
  } catch (err) {
    dispatch({
      type: DELETE_GAMES_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editGames = (id, status) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_GAMES_LOADING,
    payload: { id },
  });
  try {
    const formData = { status };
    const options = attachTokenToHeaders(getState);
    const response = await axios.put(`/api/games/${id}`, formData, options);

    dispatch({
      type: EDIT_GAMES_SUCCESS,
      payload: { games: response.data.games },
    });
  } catch (err) {
    dispatch({
      type: EDIT_GAMES_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const clearGamesError = (id) => ({
  type: CLEAR_GAMES_ERROR,
  payload: { id },
});

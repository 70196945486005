import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { inactive } from '../../store/actions/inactiveAction';
import {
  GET_INACTIVE_SUCCESS
} from '../../store/types';

import './styles.css';

const InactiveList = ({ inactive, inactiveListUsers, blankInactive }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: GET_INACTIVE_SUCCESS,
      payload: { inactiveListUsers: []
      },
    });
    inactive();
  }, [dispatch, inactive]);

  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    inactive(currentPage);
  }

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'User Name', prop: 'username' },
    { title: 'Points', prop: 'coins' },
    { title: 'Date', prop: 'date' },
  ];

  // Table Body Data
  let bodyData = [];
  if (inactiveListUsers !== undefined) {
    bodyData = [
      inactiveListUsers.map((users, index) => {
        return {
          srno: index + 1,
          username: users?.username,
          coins: users?.coins,
          date: moment(users.updatedAt).format('DD/MM/YYYY hh:mm:ss'),
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className="">
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
      {
        !!blankInactive && !!blankInactive.length > 0 ?
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>Load More.....</button>
          </div>
        </div>
        : ""
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  inactiveListUsers: state.inactive.inactiveData,
  blankInactive : state.inactive.blankInactive,
  auth: state.auth,
});
export default connect(mapStateToProps, { inactive })(InactiveList);

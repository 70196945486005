import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getHistory } from '../../store/actions/historyAction';

import './styles.css';

const DashboardList = (props) => {
  return (
    <div>
      <div className="container mt-4">
        <div className="dash-tab">
          <div className="dash-bord-box">
            <div className="dash-top-tab">
              <p>
                {props.icon} <span>{props.amount}</span>
              </p>
            </div>
            <div className="dash-tab-title">
              <p>{props.title}</p>
            </div>
          </div>
          <Link to={props.url}>
            <div className="tab-view">View</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  history: state.history.gameHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { getHistory })(DashboardList);

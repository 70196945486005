import React from 'react';
import './styles.css';
import { connect } from 'react-redux';

function LuckyCards(props) {
  return (
    <>
      <div className={props.imgClass}>
        <div className="lucky-card-text">
          {/* <p className="playing-btn-luckyCrd">Playing</p>
                <p className="playing-btn-luckyCrd-blk">Winning</p> */}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  lccurrentGameId: state.bettingData?.lccurrentGameId,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LuckyCards);

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { getBettingData } from '../../store/actions/bettingDataAction';
import { Button, Form } from 'react-bootstrap';
import './styles.css';
import { GET_BETTINGDATA_SUCCESS } from '../../store/types';
import { getGames } from '../../store/actions/gamesAction';

const BettingList = ({
  getBettingData,
  bettingData,
  getGames,
  gamesList,
  blankPlaying,
  nagtvrows,
}) => {
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [day, setDay] = useState();
  const [gameId, setGameId] = useState();
  const [searchusername, setSearchusername] = useState();
  const [ticket_id, setTicket_id] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { bettingData: [] },
    });
    getBettingData('', '', '', '', '', nagtvrows);
  }, []);
  useEffect(() => {
    getGames();
  }, []);
  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getBettingData(
      startDate,
      endDate,
      day,
      gameId,
      currentPage,
      nagtvrows,
      searchusername,
      ticket_id,
    );
  };

  const autoFillDate = (e) => {
    let daysData = e.target.value;
    if (daysData === 'today') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisWeek') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('week').add('d', 1).format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'lastWeek') {
      setDay(e.target.value);
      setendDate(
        moment().subtract(1, 'weeks').endOf('week').add('d', 1).format('YYYY-MM-DDTHH:mm:ss'),
      );
      setstartDate(
        moment().subtract(1, 'weeks').startOf('week').add('d', 1).format('YYYY-MM-DDT00:00:00'),
      );
    }
    if (daysData === 'thisMonth') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisLastMonth') {
      setDay(e.target.value);
      setendDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
  };

  const clearState = () => {
    setCurrentPage(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { bettingData: [] },
    });
    getBettingData(startDate, endDate, day, gameId, '', '', searchusername, ticket_id).then(
      clearState,
    );
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      getBettingData('', '', '', '', '', '', searchusername);
    }
  };

  // Table data
  const header = [
    { title: 'Date', prop: 'start' },
    { title: 'Start Time', prop: 'starttime' },
    { title: 'End Time', prop: 'end' },
    { title: 'Name', prop: 'name' },
    { title: 'Starts With', prop: 'startswith' },
    { title: 'Playing Amount', prop: 'playingAmount' },
    { title: 'Win Amount', prop: 'winamount' },
    { title: 'End Point', prop: 'endpoint' },
    { title: 'Placed Bet', prop: 'placedbet' },
  ];

  // Table Body Data
  let bodyData = [];
  if (bettingData !== undefined) {
    bodyData = [
      bettingData.map((users, index) => {
        let placedBtn = (
          <Link to={'/user-game-board/' + users.userid + '/' + users.gameid + '/' + users.username}>
            Placed Bet
          </Link>
        );
        let win = users?.winamount;
        win = parseFloat(win);
        return {
          start: moment(users?.start).format('DD/MM/YYYY'),
          starttime: moment(users?.start).format('hh:mm:ss A'),
          end: moment(users?.end).format('hh:mm:ss A'),
          name: <Link to={'/user-parent/' + users?.userid}>{users?.username}</Link>,
          playingAmount: users?.betamount,
          startswith: users?.startswith,
          winamount: win.toFixed(2),
          endpoint: users?.startswith - users?.betamount + win,
          placedbet: placedBtn,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-3">
          <label>
            <b>Start Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={startDate}
            onChange={(e) => setstartDate(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <label>
            <b>End Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={endDate}
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <label>
            <b>Day</b>
          </label>
          <br />
          <select name="days" className="select-days" value={day} onChange={autoFillDate}>
            <option value="">Select Days</option>
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="lastWeek">Last Week</option>
            <option value="thisMonth">This Month</option>
            <option value="thisLastMonth">This Last Month</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>
            <b>Game Name</b>
          </label>
          <br />
          <select name="days" className="select-days" onChange={(e) => setGameId(e.target.value)}>
            <option value="">Games</option>
            {gamesList.map(
              (games, index) =>
                !games?.name.match('Print') && !games?.name.match('jhandi munda') && !games?.status == false && <option value={games.id}>{games.name}</option>
            )}
          </select>
        </div>
        <div className="col-md-2 date-selector-btn tex-cntr">
          <Button variant="success" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 form-group search-input-usr">
          <input
            onChange={(e) => setSearchusername(e.target.value)}
            onKeyPress={handleKeypress}
            type="text"
            className="form-control"
            placeholder="Search User Name"
          />
        </div>
        <div className="col-md-5 form-group search-input-usr">
          <input
            onChange={(e) => setTicket_id(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Enter Ticket ID"
          />
        </div>
      </div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
      {!!blankPlaying && !!blankPlaying.length > 0 ? (
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>
              Load More.....
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  bettingData: state.bettingData.bettingData,
  blankPlaying: state.bettingData.blankPlaying,
  nagtvrows: state.bettingData.nagtvrows,
  gamesList: state.games?.games,
  auth: state.auth,
});
export default connect(mapStateToProps, { getBettingData, getGames })(BettingList);

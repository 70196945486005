export const LOGIN_WITH_OAUTH_LOADING = 'LOGIN_WITH_OAUTH_LOADING';
export const LOGIN_WITH_OAUTH_SUCCESS = 'LOGIN_WITH_OAUTH_SUCCESS';
export const LOGIN_WITH_OAUTH_FAIL = 'LOGIN_WITH_OAUTH_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_WITH_EMAIL_LOADING = 'LOGIN_WITH_EMAIL_LOADING';
export const LOGIN_WITH_EMAIL_SUCCESS = 'LOGIN_WITH_EMAIL_SUCCESS';
export const LOGIN_WITH_EMAIL_FAIL = 'LOGIN_WITH_EMAIL_FAIL';

export const REGISTER_WITH_EMAIL_LOADING = 'REGISTER_WITH_EMAIL_LOADING';
export const REGISTER_WITH_EMAIL_SUCCESS = 'REGISTER_WITH_EMAIL_SUCCESS';
export const REGISTER_WITH_EMAIL_FAIL = 'REGISTER_WITH_EMAIL_FAIL';

export const ME_LOADING = 'ME_LOADING';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const GET_FEATURE = 'GET_FEATURE';
export const GET_PROFILE = 'GET_PROFILE';

export const GET_LOCKEDDEVICES_LOADING = 'GET_LOCKEDDEVICES_LOADING';
export const GET_LOCKEDDEVICES_SUCCESS = 'GET_LOCKEDDEVICES_SUCCESS';
export const GET_LOCKEDDEVICES_FAIL = 'GET_LOCKEDDEVICES_FAIL';

export const DELETE_LOCKEDDEVICES_LOADING = 'DELETE_LOCKEDDEVICES_LOADING';
export const DELETE_LOCKEDDEVICES_SUCCESS = 'DELETE_LOCKEDDEVICES_SUCCESS';
export const DELETE_LOCKEDDEVICES_FAIL = 'DELETE_LOCKEDDEVICES_FAIL';

export const GET_MESSAGES_LOADING = 'GET_MESSAGES_LOADING';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';

export const ADD_MESSAGE_LOADING = 'ADD_MESSAGE_LOADING';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAIL = 'ADD_MESSAGE_FAIL';

export const DELETE_MESSAGE_LOADING = 'DELETE_MESSAGE_LOADING';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL';

export const EDIT_MESSAGE_LOADING = 'EDIT_MESSAGE_LOADING';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const EDIT_MESSAGE_FAIL = 'EDIT_MESSAGE_FAIL';
export const CLEAR_MESSAGE_ERROR = 'CLEAR_MESSAGE_ERROR';

export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const EDIT_USER_LOADING = 'EDIT_USER_LOADING';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export const DELETE_USER_LOADING = 'DELETE_USER_LOADING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const RESEED_DATABASE_LOADING = 'RESEED_DATABASE_LOADING';
export const RESEED_DATABASE_SUCCESS = 'RESEED_DATABASE_SUCCESS';
export const RESEED_DATABASE_FAIL = 'RESEED_DATABASE_FAIL';

export const GET_USERS = 'GET_USERS';
export const POST_DATA = 'POST_DATA';
// Games Types
export const GET_GAMES_LOADING = 'GET_GAMES_LOADING';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';
export const ADD_GAMES_LOADING = 'ADD_GAMES_LOADING';
export const ADD_GAMES_SUCCESS = 'ADD_GAMES_SUCCESS';
export const ADD_GAMES_FAIL = 'ADD_GAMES_FAIL';
export const DELETE_GAMES_LOADING = 'DELETE_GAMES_LOADING';
export const DELETE_GAMES_SUCCESS = 'DELETE_GAMES_SUCCESS';
export const DELETE_GAMES_FAIL = 'DELETE_GAMES_FAIL';
export const EDIT_GAMES_LOADING = 'EDIT_GAMES_LOADING';
export const EDIT_GAMES_SUCCESS = 'EDIT_GAMES_SUCCESS';
export const EDIT_GAMES_FAIL = 'EDIT_GAMES_FAIL';
export const CLEAR_GAMES_ERROR = 'CLEAR_GAMES_ERROR';

// Users Types
export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const ERROR_SUCCESS = 'ERROR_SUCCESS';

export const GET_AGENTS_SELECT_LOADING = 'GET_AGENTS_SELECT_LOADING';
export const GET_AGENTS_SELECT_SUCCESS = 'GET_AGENTS_SELECT_SUCCESS';
export const GET_AGENTS_SELECT_FAIL = 'GET_AGENTS_SELECT_FAIL';

export const ADD_TRAN_LOADING = 'ADD_TRAN_LOADING';
export const ADD_TRAN_SUCCESS = 'ADD_TRAN_SUCCESS';
export const ADD_TRAN_FAIL = 'ADD_TRAN_FAIL';

export const ADD_USERS_LOADING = 'ADD_USERS_LOADING';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';
export const ADD_USERS_FAIL = 'ADD_USERS_FAIL';
export const ADD_NEW_USERS_SUCCESS = 'ADD_NEW_USERS_SUCCESS';

export const ADD_FILTER_USERS_SUCCESS = 'ADD_FILTER_USERS_SUCCESS';
export const ADD_FILTER_USERS_LOADING = 'ADD_FILTER_USERS_LOADING';

export const SEARCH_USERS_LOADING = 'SEARCH_USERS_LOADING';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'SEARCH_USERS_FAIL';

export const ADD_USERS_TOKEN_LOADING = 'ADD_USERS_TOKEN_LOADING';
export const ADD_USERS_TOKEN_SUCCESS = 'ADD_USERS_TOKEN_SUCCESS';
export const ADD_USERS_TOKEN_FAIL = 'ADD_USERS_TOKEN_FAIL';

export const DELETE_USERS_LOADING = 'DELETE_USERS_LOADING';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAIL = 'DELETE_USERS_FAIL';

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const EDIT_USERS_LOADING = 'EDIT_USERS_LOADING';
export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS';
export const EDIT_USERS_FAIL = 'EDIT_USERS_FAIL';

export const CLEAR_USERS_ERROR = 'CLEAR_USERS_ERROR';
export const ADD_USERSBYAGENT_LOADING = 'ADD_USERSBYAGENT_LOADING';
export const ADD_USERSBYAGENT_SUCCESS = 'ADD_USERSBYAGENT_SUCCESS';
export const ADD_USERSBYAGENT_FAIL = 'ADD_USERSBYAGENT_FAIL';

// History Type
export const GET_HISTORY_LOADING = 'GET_HISTORY_LOADING';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAIL = 'GET_HISTORY_FAIL';
export const CLEAR_HISTORY_ERROR = 'CLEAR_HISTORY_ERROR';

//Game Betting type
export const GET_BETTING_LOADING = 'GET_BETTING_LOADING';
export const GET_BETTING_SUCCESS = 'GET_BETTING_SUCCESS';
export const GET_BETTING_FAIL = 'GET_BETTING_FAIL';
export const ADD_BETTING_LOADING = 'ADD_BETTING_LOADING';
export const ADD_BETTING_SUCCESS = 'ADD_BETTING_SUCCESS';
export const ADD_BETTING_FAIL = 'ADD_BETTING_FAIL';
export const DELETE_BETTING_LOADING = 'DELETE_BETTING_LOADING';
export const DELETE_BETTING_SUCCESS = 'DELETE_BETTING_SUCCESS';
export const DELETE_BETTING_FAIL = 'DELETE_BETTING_FAIL';
export const EDIT_BETTING_LOADING = 'EDIT_BETTING_LOADING';
export const EDIT_BETTING_SUCCESS = 'EDIT_BETTING_SUCCESS';
export const EDIT_BETTING_FAIL = 'EDIT_BETTING_FAIL';
export const CLEAR_BETTING_ERROR = 'CLEAR_BETTING_ERROR';

// Betting
export const GET_BETTINGDATA_LOADING = 'GET_BETTINGDATA_LOADING';
export const GET_BETTINGDATA_SUCCESS = 'GET_BETTINGDATA_SUCCESS';
export const GET_BETTINGDATA_FAIL = 'GET_BETTINGDATA_FAIL';
export const GET_CURRENTGAMEDETAIL_LOADING = 'GET_CURRENTGAMEDETAIL_LOADING';
export const GET_CURRENTGAMEDETAIL_SUCCESS = 'GET_CURRENTGAMEDETAIL_SUCCESS';
export const GET_CURRENTGAMEDETAIL_FAIL = 'GET_CURRENTGAMEDETAIL_FAIL';

export const GET_CURRENTRESULT_LOADING = 'GET_CURRENTRESULT_LOADING';
export const GET_CURRENTRESULT_SUCCESS = 'GET_CURRENTRESULT_SUCCESS';
export const GET_CURRENTRESULT_FAIL = 'GET_CURRENTRESULT_FAIL';
export const CLEAR_BETTINGDATA_ERROR = 'CLEAR_BETTINGDATA_ERROR';

export const GET_CURRENTRESULTIMER_LOADING = 'GET_CURRENTRESULTIMER_LOADING';
export const GET_CURRENTRESULTIMER_SUCCESS = 'GET_CURRENTRESULTIMER_SUCCESS';
export const GET_CURRENTRESULTIMER_FAIL = 'GET_CURRENTRESULTIMER_FAIL';

// Settings
export const GET_SETTINGS_LOADING = 'GET_SETTINGS_LOADING';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAIL = 'GET_SETTINGS_FAIL';
export const ADD_SETTINGS_LOADING = 'ADD_SETTINGS_LOADING';
export const ADD_SETTINGS_SUCCESS = 'ADD_SETTINGS_SUCCESS';
export const ADD_SETTINGS_FAIL = 'ADD_SETTINGS_FAIL';
export const DELETE_SETTINGS_LOADING = 'DELETE_SETTINGS_LOADING';
export const DELETE_SETTINGS_SUCCESS = 'DELETE_SETTINGS_SUCCESS';
export const DELETE_SETTINGS_FAIL = 'DELETE_SETTINGS_FAIL';
export const EDIT_SETTINGS_LOADING = 'EDIT_SETTINGS_LOADING';
export const EDIT_SETTINGS_SUCCESS = 'EDIT_SETTINGS_SUCCESS';
export const EDIT_SETTINGS_FAIL = 'EDIT_SETTINGS_FAIL';
export const CLEAR_SETTINGS_ERROR = 'CLEAR_SETTINGS_ERROR';

// Wallet Types
export const GET_WALLET_LOADING = 'GET_USERS_LOADING';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const GET_WALLET_FAIL = 'GET_WALLET_FAIL';
export const ADD_WALLET_LOADING = 'ADD_WALLET_LOADING';
export const ADD_WALLET_SUCCESS = 'ADD_WALLET_SUCCESS';
export const ADD_WALLET_FAIL = 'ADD_WALLET_FAIL';
export const DELETE_WALLET_LOADING = 'DELETE_WALLET_LOADING';
export const DELETE_WALLET_SUCCESS = 'DELETE_WALLET_SUCCESS';
export const DELETE_WALLET_FAIL = 'DELETE_WALLET_FAIL';
export const EDIT_WALLET_LOADING = 'EDIT_WALLET_LOADING';
export const EDIT_WALLET_SUCCESS = 'EDIT_WALLET_SUCCESS';
export const EDIT_WALLET_FAIL = 'EDIT_WALLET_FAIL';
export const CLEAR_WALLET_ERROR = 'CLEAR_WALLET_ERROR';

// Token Type
export const GET_TOKEN_LOADING = 'GET_TOKEN_LOADING';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAIL = 'GET_TOKEN_FAIL';
export const CLEAR_TOKEN_ERROR = 'CLEAR_TOKEN_ERROR';

// Dashboard
export const GET_DASHBOARD_LOADING = 'GET_DASHBOARD_LOADING';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';
export const CLEAR_DASHBOARD_ERROR = 'CLEAR_DASHBOARD_ERROR';

// Winning
export const GET_WINNING_LOADING = 'GET_WINNING_LOADING';
export const GET_WINNING_SUCCESS = 'GET_WINNING_SUCCESS';
export const GET_WINNING_FAIL = 'GET_WINNING_FAIL';
export const CLEAR_WINNING_ERROR = 'CLEAR_WINNING_ERROR';

// Inactive
export const GET_INACTIVE_LOADING = 'GET_INACTIVE_LOADING';
export const GET_INACTIVE_SUCCESS = 'GET_INACTIVE_SUCCESS';
export const GET_INACTIVE_FAIL = 'GET_INACTIVE_FAIL';
export const CLEAR_INACTIVE_ERROR = 'CLEAR_INACTIVE_ERROR';

// TurnOver
export const GET_TURNOVER_LOADING = 'GET_TURNOVER_LOADING';
export const GET_TURNOVER_SUCCESS = 'GET_TURNOVER_SUCCESS';
export const GET_TURNOVER_FAIL = 'GET_TURNOVER_FAIL';
export const CLEAR_TURNOVER_ERROR = 'CLEAR_TURNOVER_ERROR';

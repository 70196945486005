import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  currentGameBet,
  currentGameBetId,
  manualNumber,
  toDayResult,
  jakPotNuber,
} from '../../store/actions/bettingDataAction';
import './styles.css';
import { Button } from 'react-bootstrap';

const CurrentGame = ({
  jakPotNuber,
  todayData,
  manualNumber,
  toDayResult,
  currentGameBet,
  currentGameBetId,
  currentGameIdUserList,
  currentGameDetails,
}) => {
  const [number, setnumber] = useState('');
  const [jakPotNmbr, setjakPotNmbr] = useState('');
  let gameCountr = currentGameDetails?.counter;
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    currentGameBet();
  }, [currentGameBet]);

  useEffect(() => {
    toDayResult();
  }, [toDayResult]);

  useEffect(() => {
    setCounter(gameCountr);
  }, [currentGameDetails, setCounter, gameCountr]);

  useEffect(() => {
    setTimeout(() => {
      if (counter) {
        setCounter(counter ? counter - 1 : gameCountr - 1);
      } else if (counter === 0) {
        return <>{setCounter(75)}</>;
      }
    }, 1000);
  }, [counter, gameCountr]);

  useEffect(() => {
    currentGameBetId();
    const interval = setInterval(() => {
      currentGameBetId();
    }, 2000);
    return () => clearInterval(interval);
  }, [currentGameBetId]);

  // const jackpotDeclare = () => {
  //     jakPotNuber(jakPotNmbr);
  //     alert("JackPot Amount Added Successfully.")
  // }

  const drownumbr = () => {
    manualNumber(number);
    jakPotNuber(jakPotNmbr);
    alert('Your Number is added Successfully');
    currentGameBetId();
  };
  if (75 - counter === 0) {
    // alert('Refresh for new game');
    window.location.reload();
  }
  let nmbrDetail = todayData?.number;
  let todayTotWin = todayData?.total?.[0]?.total_betting - todayData?.total?.[0]?.total_winning;
  return (
    <div className="">
      <div className="row">
        <div className="col-md-6 disply-flx spcing-rmov">
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('0')}>
            1x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('2')}>
            2x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('3')}>
            3x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('4')}>
            4x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('5')}>
            5x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('6')}>
            6x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('7')}>
            7x
          </div>
          <div className="col jak-port-tab" onClick={() => setjakPotNmbr('8')}>
            8x
          </div>
        </div>
        <div className="col-md-6 disply-flx spcing-rmov">
          <div className="col-md-4 declr-nmbr">
            JackPot:-{' '}
            <input
              className="jak-pot-nmbr"
              type="text"
              value={jakPotNmbr}
              onChange={(e) => setjakPotNmbr(e.target.value)}
            />
            {/* JackPot:- {jakPotNmbr ? jakPotNmbr : 0}x  */}
            {/* {number ? number : "0"} */}
          </div>
          <div className="col-md-4 declr-nmbr">
            Number:-{' '}
            <input
              className="jak-pot-nmbr"
              type="text"
              value={number}
              onChange={(e) => setnumber(e.target.value)}
            />
          </div>
          {/* <div className="col-md-3 ">
                <Form.Control className="mrgn-botm-r spcing-rmov" size="sm" type="text" placeholder="Enter Winning Number" value={number}
                                onChange={e => setnumber(e.target.value)}/>
                </div> */}
          <div className="col-md-4 tex-cntr ">
            {75 - counter < 59 ? (
              <Button
                onClick={drownumbr}
                variant="warning"
                className="sbmt-btn-r mrgn-botm-r spcing-rmov"
              >
                <b>Submit</b>
              </Button>
            ) : (
              <Button
                onClick={drownumbr}
                variant="warning"
                disabled
                className="sbmt-btn-r mrgn-botm-r spcing-rmov"
              >
                <b>Please Wait</b>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Counter</b> : <span>{75 - counter ? 75 - counter : 0}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Playing</b> : <span>{todayData?.total?.[0]?.total_betting.toFixed(2)}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Winning</b> : <span>{todayData?.total?.[0]?.total_winning.toFixed(2)}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Profit/Loss</b> : <b><span>
                {todayTotWin.toFixed(2)}
              </span></b>
            </p>
          </div>
        </div>
      </div>
      <div className="row manual-numbr">
        <div className="col-md-4">
          <p className="mrgn-0">
            Current Game Total Playing:- {currentGameIdUserList?.totalbetamount ? currentGameIdUserList?.totalbetamount : "0"}
            {/* {' '} */}
            {/* {currentGameDetails?.currentgame?.total_betting
              ? currentGameDetails?.currentgame?.total_betting
              : '0'} */}
          </p>
        </div>
        <div className="col-md-4">
          <p className="mrgn-0">
            Get Manual Number before <b>59</b>.
          </p>
        </div>
        <div className="col-md-4">
          <p className="mrgn-0">
            {nmbrDetail !== undefined
              ? nmbrDetail.map((data, index) => {
                  return <>{data.number + (data.jackpot > 0 ? '(J)' : '')},</>;
                })
              : '0'}
          </p>
        </div>
      </div>

      {/* Top Menu */}
      <div className="container mt-3">
        <div className="row jackPot-amount-dtl">
          <div className="col short-dtl-tbl">
            {
              jakPotNmbr ? 
                  !!currentGameIdUserList &&
                  !!currentGameIdUserList?.winamount_number &&
                  currentGameIdUserList?.winamount_number[number] * jakPotNmbr
                : !!currentGameIdUserList &&
                  !!currentGameIdUserList?.winamount_number &&
                  currentGameIdUserList?.winamount_number[number]
                ?
                jakPotNmbr ? 
                  !!currentGameIdUserList &&
                  !!currentGameIdUserList?.winamount_number &&
                  currentGameIdUserList?.winamount_number[number] * jakPotNmbr
                : !!currentGameIdUserList &&
                  !!currentGameIdUserList?.winamount_number &&
                  currentGameIdUserList?.winamount_number[number]
                : "0"
                
            
              // !!jackPotAmount && jackPotAmount[`${number}`] * jakPotNmbr
            }
          </div>
        </div>
        {/* end */}
        <div className="row">
          <div className="col-md-1 pding-rmov">
            <div className="row bord-zero left-redus-bordr-top">
              <div className="col-md-12 bord-text-zero" onClick={() => setnumber('00')}>
                <span className="board-numb-left-top">00</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['00'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['00']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row bord-zero left-redus-bordr-botm">
              <div className="col-md-12 bord-text-zero" onClick={() => setnumber('0')}>
                <span className="board-numb-left">0</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['0'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['0']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="col-md-10 pding-rmov">
            <div className="row">
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('3')}>
                <span className="board-numb-red">3</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['3'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['3']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('6')}>
                <span className="board-numb-red num-black">6</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['6'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['6']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('9')}>
                <span className="board-numb-red">9</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['9'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['9']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('12')}>
                <span className="board-numb-red">12</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['12'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['12']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('15')}>
                <span className="board-numb-red num-black">15</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['15'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['15']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('18')}>
                <span className="board-numb-red">18</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['18'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['18']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('21')}>
                <span className="board-numb-red">21</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['21'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['21']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('24')}>
                <span className="board-numb-red num-black">24</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['24'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['24']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('27')}>
                <span className="board-numb-red">27</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['27'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['27']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('30')}>
                <span className="board-numb-red">30</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['30'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['30']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('33')}>
                <span className="board-numb-red num-black">33</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['33'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['33']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('36')}>
                <span className="board-numb-red">36</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['36'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['36']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('2')}>
                <span className="board-numb-red num-black">2</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['2'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['2']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('5')}>
                <span className="board-numb-red">5</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['5'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['5']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('8')}>
                <span className="board-numb-red num-black">8</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['8'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['8']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('11')}>
                <span className="board-numb-red num-black">11</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['11'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['11']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('14')}>
                <span className="board-numb-red">14</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['14'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['14']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('17')}>
                <span className="board-numb-red num-black">17</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['17'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['17']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('20')}>
                <span className="board-numb-red num-black">20</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['20'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['20']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('23')}>
                <span className="board-numb-red">23</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['23'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['23']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('26')}>
                <span className="board-numb-red num-black">26</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['26'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['26']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('29')}>
                <span className="board-numb-red num-black">29</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['29'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['29']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('32')}>
                <span className="board-numb-red">32</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['32'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['32']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('35')}>
                <span className="board-numb-red num-black">35</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['35'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['35']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('1')}>
                <span className="board-numb-red">1</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['1'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['1']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('4')}>
                <span className="board-numb-red num-black">4</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['4'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['4']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('7')}>
                <span className="board-numb-red">7</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['7'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['7']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('10')}>
                <span className="board-numb-red num-black">10</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['10'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['10']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('13')}>
                <span className="board-numb-red num-black">13</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['13'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['13']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('16')}>
                <span className="board-numb-red">16</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['16'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['16']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('19')}>
                <span className="board-numb-red">19</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['19'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['19']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('22')}>
                <span className="board-numb-red num-black">22</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['22'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['22']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('25')}>
                <span className="board-numb-red">25</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['25'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['25']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('28')}>
                <span className="board-numb-red num-black">28</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['28'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['28']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('31')}>
                <span className="board-numb-red num-black">31</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['31'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['31']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="col-md-1 bord-text-cntr" onClick={() => setnumber('34')}>
                <span className="board-numb-red">34</span>
                {currentGameIdUserList?.numbers !== undefined ? (
                  currentGameIdUserList?.numbers['34'] === 0 ? null : (
                    <span className="bet-numb-point">{currentGameIdUserList?.numbers['34']}</span>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="col-md-1 pding-rmov mrgn-top-rit">
            <div className="row right-bord-box">
              <div className="col-md-12 bord-text-cntr-right">
                <span className="board-numb-right">C3</span>
              </div>
            </div>
            <div className="row right-bord-box">
              <div className="col-md-12 bord-text-cntr-right">
                <span className="board-numb-right">C2</span>
              </div>
            </div>
            <div className="row right-bord-box">
              <div className="col-md-12 bord-text-cntr-right">
                <span className="board-numb-right">C1</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 pding-rmov">
            <div className="row">
              <div className="col-md-4 bordr-col-btns">1st 12</div>
              <div className="col-md-4 bordr-col-btns">2nd 12</div>
              <div className="col-md-4 bordr-col-btns">3rd 12</div>
            </div>
            <div className="row">
              <div className="col-md-2 bordr-col-btns">01 - 18</div>
              <div className="col-md-2 bordr-col-btns">EVEN</div>
              <div className="col-md-2 bordr-col-btns">
                <span className="retcngl-btn-botm"></span>
              </div>
              <div className="col-md-2 bordr-col-btns">
                <span className="retcngl-btn-botm-black"></span>
              </div>
              <div className="col-md-2 bordr-col-btns">ODD</div>
              <div className="col-md-2 bordr-col-btns">19 - 36</div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <div className="row mrg-top-dtd">
        <div className="col-md-6">
          <div className="row">
            <table className="game-board-winTbl">
              <tr>
                <th>UserName</th>
                <th>Playing Amount</th>
                <th>Sale</th>
                <th>Loss/Profit</th>
                <th>Number</th>
              </tr>
              {currentGameIdUserList?.users !== undefined && currentGameIdUserList?.users.length
                ? currentGameIdUserList?.users.map((data, index) => {
                    return (
                      <>
                        <tr>
                          <td>{data.username}</td>
                          <td>{data.betamount.toFixed(2)}</td>
                          <td>{data.daily_play_points}</td>
                          <td>{data.daily_play_points - data.daily_winning_points}</td>
                          <td>
                                        {
                                        Object.entries(data?.numbers).map(([key, value]) =>{
                                          return (
                                            <>
                                              {key}:{value} , 
                                            </>
                                          );
                                        }
                                        )
                                        }
                                       </td> 
                        </tr>
                      </>
                    );
                  })
                : 'No Record Found.'}
            </table>
          </div>
          {/* <div className="row mrgn-top-gameList">
                    <div className="col-md-6 txt-cntr">
                        <b>Betting Type</b>
                    </div>
                    <div className="col-md-6 txt-cntr">
                        <b>Amount</b>
                    </div>
                </div>
                    {currentGameIdUserList?.numbers !== undefined && currentGameIdUserList?.numbers.length ?
                    Object.keys(currentGameIdUserList?.numbers).map(key => {
                        return(
                            <>
                            <div className="row txt-cntr betting-type-dta">
                                <div className="col-md-6">
                                {key}
                                </div>
                                <div className="col-md-6">
                                {currentGameIdUserList?.numbers[key]}
                                </div>
                            </div>
                            </>
                        )
                    }) : ""
                } */}
        </div>
        <div className="col-md-6">
          <table className="game-board-winTbl">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              <th>Winning Amount</th>
            </tr>
            {currentGameIdUserList?.win_bet_amount !== undefined &&
            currentGameIdUserList?.win_bet_amount.length
              ? currentGameIdUserList?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                      <tr>
                        <td>{data.number}</td>
                        <td>{data.betamount}</td>
                        <td>{data.wincoins_if_draw_this_number.toFixed(2)}</td>
                      </tr>
                    </>
                  );
                })
              : 'No Record Found.'}
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentGameDetails: state.bettingData?.currentGameData,
  currentGameIdUserList: state.bettingData.currentGameId,
  todayData: state.bettingData.todayResult,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  jakPotNuber,
  currentGameBet,
  currentGameBetId,
  manualNumber,
  toDayResult,
})(CurrentGame);

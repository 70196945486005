import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { userParent } from '../../store/actions/usersAction';
import './styles.css';
import Profile from '../Navbar/Profile';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EditUser from '../../pages/Users/EditUser';

const UserParentList = ({ userParentData, userParent }) => {
  const { id } = useParams();

  useEffect(() => {
    userParent(id);
  }, [userParent, id]);

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'User Name', prop: 'username'},
    { title: 'Parent Name', prop: 'parentName'},
    { title: 'Transactions', prop: 'tran' },
    { title: 'Bettings', prop: 'betting' },
    { title: 'Coin', prop: 'coin' },
    // {title: 'Wallet', prop: 'wallet'},
    { title: 'Role', prop: 'role' },
    { title: 'Actions', prop: 'action' },
  ];
  let gameprofileView = <i className="fa fa-eye view-icon" aria-hidden="true"></i>;

  let bodyData = [];

  if (userParentData !== undefined) {
    // Table Body Data
    bodyData = [
      userParentData.map((users, index) => {
        let currentUserId = users.id;

        let tran = (
          <Link to={'/user-transaction/' + currentUserId} variant="info" className="">
            <i className="fa fa-money" aria-hidden="true"></i> Transactions
          </Link>
        );
        let bettings = (
          <Link to={'/user-betting/' + currentUserId} variant="info" className="">
            <i className="fa fa-gamepad" aria-hidden="true"></i> Bettings
          </Link>
        );

        let deleteicon = [
          <Profile
            profileicon={gameprofileView}
            coins={users?.coins}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role?.name}
          />,
          <EditUser
            gameid={users?.id}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
          />,
        ];
        let userView = (
          <Profile
            coins={users?.coins}
            profileicon={users?.username}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role?.name}
          />
        );
        return {
          srno: index + 1,
          username: userView,
          parentName: users?.parent?.username,
          tran: tran,
          betting: bettings,
          coin: (
            <span>
              <i className="fa fa-money" aria-hidden="true"></i> {users.coins}
            </span>
          ),
          // wallet:wallet,
          role: users?.role?.name,
          action: deleteicon,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length ? bodyData[0] : []}
        classes={classes}
        rowsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userParentData: state.users.userParent,
});

export default connect(mapStateToProps, { userParent })(UserParentList);

import React, { useState } from 'react';
import Switch from 'react-switch';
import { connect } from 'react-redux';
import { updateTokens, getTokens } from '../../store/actions/tokenAction';

const ToggleButtonGame = (props) => {
  const [checked, setChecked] = useState(props.status);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    props.updateTokens(props.toknId, nextChecked);
    props.getTokens();
  };

  return (
    <>
      <Switch
        className="react-switch"
        onChange={handleChange}
        checked={checked}
        aria-labelledby="neat-label"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  gameTokens: state.token.gameToken,
});
export default connect(mapStateToProps, { updateTokens, getTokens })(ToggleButtonGame);

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { getWallet, getTranFiltr, clearWalletError } from '../../store/actions/walletAction';
import './styles.css';
import { Button, Form } from 'react-bootstrap';
import {
  GET_WALLET_SUCCESS
} from '../../store/types';

const WalletTrans = ({ getWallet, Wallet, getTranFiltr, blankWallet }) => {
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [userName, setuserName] = useState();
  const [gameId] = useState();
  const [game] = useState();
  const [day, setDay] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: GET_WALLET_SUCCESS,
      payload: { Wallet: []
       },
    });
    getWallet();
  }, [getWallet, dispatch]);
  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getWallet(startDate, endDate, userName, gameId, day, game, currentPage);
  }
  
  const clearState = () => {
    setCurrentPage(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_WALLET_SUCCESS,
      payload: { Wallet: []
       },
    });
    getWallet(startDate, endDate, userName, gameId, day, game).then(clearState);
  };

  // ALL addWalletTrn
  const addWalletTrn = () => {
    getTranFiltr('ADD');
  };
  // ALL subWalletTrn
  const subWalletTrn = () => {
    getTranFiltr('SUBTRACT');
  };

  // ALL WITHDRAW
  const withWalletTrn = () => {
    getTranFiltr('WITHDRAW');
  };
  
  const autoFillDate = (e) => {
    let daysData = e.target.value;
    if (daysData === 'today') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisWeek') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('week').add('d',1).format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'lastWeek') {
      setDay(e.target.value);
      setendDate(moment().subtract(1, 'weeks').endOf('week').add('d',1).format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().subtract(1, 'weeks').startOf('week').add('d',1).format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisMonth') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisLastMonth') {
      setDay(e.target.value);
      setendDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
  };
  return (
    <div className={Wallet.isLoading ? 'message loader' : 'message'}>
      <div className="row">
        <div className="col-md-4">
          <button onClick={addWalletTrn} type="button" className="btn btn-primary btns-width-tran">
            ADD
          </button>
        </div>
        <div className="col-md-4">
          <button onClick={subWalletTrn} type="button" className="btn btn-danger btns-width-tran">
            SUBTRACT
          </button>
        </div>
        <div className="col-md-4">
          <button onClick={withWalletTrn} type="button" className="btn btn-warning btns-width-tran">
            WITHDRAW
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>
            <b>Start Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={startDate}
            onChange={(e) => setstartDate(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label>
            <b>End Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={endDate}
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label>
            <b>User Name</b>
          </label>
          <Form.Control
            type="text"
            placeholder=""
            value={userName}
            onChange={(e) => setuserName(e.target.value)}
          />
        </div>
      </div>
      <div className="row  mrgn-btom">
        <div className="col-md-6">
          <label>
            <b>Day</b>
          </label>
          <br />
          <select name="days" className="select-days" value={day} onChange={autoFillDate}>
            <option value="">Select Days</option>
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="lastWeek">Last Week</option>
            <option value="thisMonth">This Month</option>
            <option value="thisLastMonth">This Last Month</option>
          </select>
        </div>
        {/* <div className="col-md-2">
          <label>
            <b>Game ID</b>
          </label>
          <Form.Control
            type="text"
            placeholder=""
            value={gameId}
            onChange={(e) => setGameId(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <label>
            <b>Game Name</b>
          </label>
          <select name="days" className="select-days" onChange={(e) => setGame(e.target.value)}>
            <option value="">Games</option>
            <option value="611b69498a898d2aa6386cb3">ROULETTE</option>
            <option value="615ad717bfe7fcaa9cdd75e9">LUCKY CARD</option>
          </select>
        </div> */}
        <div className="col-md-6 mt-3 date-selector-btn tex-cntr">
          <Button variant="success" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table>
            <tr>
              <th>Sr No</th>
              <th>User Name</th>
              <th>Type</th>
              <th>Date</th>
              <th>New Points</th>
              <th>In</th>
              <th>Out</th>
              <th>Old</th>
            </tr>

            {Wallet.map((users, index) => {
              let showDate = moment(users.updatedAt).format('DD/MM/YYYY hh:mm:ss');
              return (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      {users?.toUser?.username ? users?.toUser?.username : users?.fromUser?.name}
                    </td>
                    <td>{users?.type}</td>
                    <td>{showDate}</td>
                    <td>{users?.remaining_coins}</td>
                    <td>{users.type === 'ADD' ? users?.trans_coins : ''}</td>
                    <td>
                      {users.type === 'SUBTRACT' || users.type === 'WITHDRAW'
                        ? users?.trans_coins
                        : ''}
                    </td>
                    <td>
                      {users.type === 'SUBTRACT' || users.type === 'WITHDRAW'
                        ? users?.remaining_coins + users?.trans_coins
                        : users?.remaining_coins - users?.trans_coins}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
      {
        !!blankWallet && !!blankWallet.length > 0 ?
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>Load More.....</button>
          </div>
        </div> 
      : ""
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  Wallet: state.wallet.Wallet,
  blankWallet : state.wallet.blankWallet,
  auth: state.auth,
});

export default connect(mapStateToProps, { getWallet, getTranFiltr, clearWalletError })(WalletTrans);

import React, { useEffect } from 'react';
import LuckyCards from '../Navbar/LuckyCards';
import moment from 'moment';
import './styles.css';
import { connect } from 'react-redux';
import {
  placedGameBet,
  jakPotNuberLuckyCard,
  lcmanualNumber,
  lcCurrentGameBet,
  lcToDayResult,
  lcCurrentGameBetId,
} from '../../store/actions/bettingDataAction';
import { getUserHistory } from '../../store/actions/historyAction';
import { useParams } from 'react-router-dom';

function LuckyCardCurrent({
  gameBetAmount,
  placedGameBet,
  getUserHistory,
  luckyCrdTap,
}) {
  const { id } = useParams();
  useEffect(() => {
    placedGameBet(id);
  }, [placedGameBet, id]);

  useEffect(() => {
    getUserHistory(id);
  }, [getUserHistory, id]);

  return (
    <>
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Start Time</b>:
              <span>
                {gameBetAmount !== undefined
                  ? moment(gameBetAmount.start).format('DD/MM/YYYY hh:mm:ss')
                  : ''}
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>End Time</b>:
              <span>
                {gameBetAmount !== undefined
                  ? moment(gameBetAmount.end).format('DD/MM/YYYY hh:mm:ss')
                  : ''}
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Total Betting</b>:
              <span>{gameBetAmount !== undefined ? gameBetAmount?.totalbetamount : ''}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Total Winning</b>:
              <span>{gameBetAmount !== undefined ? gameBetAmount?.totalwinning : ''}</span>
            </p>
          </div>
        </div>
      </div>
      {/* input type */}
      {/* <div className="row lucky-card-mrgn">
                {
                    cards.map((card, index)=> (
                      console.log("card data=>", card?.betAmount),
                        <div className="row" key={`index-${index}`}
                        onClick={() => setActive(index)}
                        className="col-md-2">
                            <p className="betamount-on-card">{`${card?.betAmount}`}</p>
                            <LuckyCards amount={`${card.betAmount}`} imgClass={`${card.class} ${index === active && "selected-card"} l-cards-main`} />
                        </div>
                    ))
                }
        </div> */}
      <div className="row lucky-card-mrgn">
        <div className="col-md-7">
          <div className="row">
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['1'] === 0 ? null : (
                    <span>{luckyCrdTap['1']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-three l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['2'] === 0 ? null : (
                    <span>{luckyCrdTap['2']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-two l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['3'] === 0 ? null : (
                    <span>{luckyCrdTap['3']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['4'] === 0 ? null : (
                    <span>{luckyCrdTap['4']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-for l-cards-main'} />
            </div>
          </div>
          <div className="row lucky-card-mrgn">
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['5'] === 0 ? null : (
                    <span>{luckyCrdTap['5']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-seven l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['6'] === 0 ? null : (
                    <span>{luckyCrdTap['6']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-six l-cards-main'} />
            </div>

            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['7'] === 0 ? null : (
                    <span>{luckyCrdTap['7']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-five l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['8'] === 0 ? null : (
                    <span>{luckyCrdTap['8']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-eight l-cards-main'} />
            </div>
          </div>
          <div className="row lucky-card-mrgn">
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['9'] === 0 ? null : (
                    <span>{luckyCrdTap['9']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-eleven l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['10'] === 0 ? null : (
                    <span>{luckyCrdTap['10']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-ten l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['11'] === 0 ? null : (
                    <span>{luckyCrdTap['11']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-nine l-cards-main'} />
            </div>
            <div className=" col-md-3">
              <p className="betamount-on-card">
                {luckyCrdTap !== undefined ? (
                  luckyCrdTap['12'] === 0 ? null : (
                    <span>{luckyCrdTap['12']}</span>
                  )
                ) : (
                  '0'
                )}
              </p>
              <LuckyCards imgClass={'card-img-luky-twel l-cards-main'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <table className="game-board-winTbl">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              <th>Winning Amount</th>
            </tr>
            {gameBetAmount?.win_bet_amount !== undefined
              ? gameBetAmount?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                      <tr>
                        <td>{data?.number}</td>
                        <td>{data?.betamount}</td>
                        <td>{data?.wincoins_if_draw_this_number}</td>
                      </tr>
                    </>
                  );
                })
              : ''}
          </table>
        </div>
      </div>

      {/* <div className="row mrg-top-dtd">
            <div className="col-md-6">
                <div className="row">
                    <table className="game-board-winTbl">
                        <tr>
                            <th>UserName</th>
                            <th>Playing Amount</th>
                            <th>Winning</th>
                        </tr>
                        {userListing?.users !== undefined ?
                             userListing?.users.map((data, index) => {
                                return(
                                    <>
                                    <tr key={index}>
                                        <td>{data?.username}</td>
                                        <td>{data?.betamount}</td>
                                        <td>{data?.winning}</td>
                                    </tr>
                                    </>
                                )
                            }) : "No Record Found."
                        }
                    </table>
                </div>
            </div>
        </div> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  gamePlaced: state.bettingData.gamePlaced,
  gamePlacedList: state.bettingData.gamePlacedList,
  gameBetAmount: state.bettingData.fullGameBordData,
  luckyCrdTap: state.bettingData.fullGameBordData?.numbers,
  userListing: state.history?.gameHistory,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  placedGameBet,
  getUserHistory,
  jakPotNuberLuckyCard,
  lcmanualNumber,
  lcCurrentGameBet,
  lcToDayResult,
  lcCurrentGameBetId,
})(LuckyCardCurrent);

import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addWallet, getWallet } from '../../store/actions/walletAction';
import { allUsers } from '../../store/actions/usersAction';

function AddWallet(props) {
  const [trans_coins, settrans_coins] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    props.apiReload();
    window.location.reload();
    props.errorStatus();
  };
  const handleShow = () => setShow(true);

  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = (e) => {
    e.preventDefault();
    props.addWallet(props.userid, trans_coins);
    // alert(!props.statusMessage ? 'Your Amount is Added Successfully' : props.statusMessage);
    // window.location.reload();
    // setShow(false);
  };
  return (
    <>
      <Button className={props.customClass} onClick={handleShow} variant={props.buttnVariant}>
        {props.icon}
      </Button>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 profile-img cntr">
              <h3>{props.title}</h3>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 profile-sub-details">
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your ID"
                    value={props.username}
                    disabled
                  />
                </div>
                <div className="row pt-3">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Your Amount"
                    value={trans_coins}
                    onChange={(e) => settrans_coins(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} variant="success" >
            Submit
          </Button>
          <div className="row cntr-text">{props.statusMessage || props.scuMsg}</div>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => (
  {
  statusMessage: state.wallet.error,
  scuMsg: state.wallet.scussMsg
});
export default connect(mapStateToProps, { getWallet, addWallet, allUsers })(AddWallet);

import React, { useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addUsers, allUsers } from '../../store/actions/usersAction';

function AddUser(props) {
  const [name, setname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const [role, setRole] = useState('');
  const [password, setpassword] = useState('');
  const [commission, setcommission] = useState('');
  const [pname, setpname] = useState('');
  const [rgames, setRGames] = useState("");
  const [lgames, setLGames] = useState("");
  const [lPgames, setlPgames] = useState("");
  const [canAgentCreateUser, setCanAgentCreateUser] = useState(true);
  const [show, setShow] = useState(false);
  const games = [rgames, lgames, lPgames]
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  const handleShow = () => {
    setShow(true);
  }

  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = (e) => {
    props.addUsers(props.gameid, name, username, email, phone, role, password, commission, pname, games,canAgentCreateUser);
    alert('Add User Successfully');
    window.location.reload();
  };
  
  return (
    <>
      <Button onClick={handleShow} variant="success" className="btns-width">
        {props.addGame}
      </Button>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 profile-img cntr">
              <h3>ADD NEW USER</h3>
              <p className="text-req-red">Please Enter Required Field</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 profile-sub-details">
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    required="true"
                  />
                </div>

                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    className="required-fild-red"
                    placeholder="Enter Your UserName"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required="true"
                  />
                </div>
                <div className="row pt-3">
                  <Form.Control
                    type="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Phone No."
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                  />
                </div>
                <div className="row pt-3">
                  <select
                    name="color"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required="true"
                    className="required-fild-red"
                  >
                    <option value="" label="Select Role" />
                    <option value="2" label="Agent" />
                    <option value="3" label="User" />
                  </select>
                </div>
                <div className="row pt-3">
                <label>Can Agent create user</label>
                  <select
                    name="color"
                    value={canAgentCreateUser}
                    onChange={(e) => setCanAgentCreateUser(e.target.value)}
                    required="true"
                    className="required-fild-red"
                  >
                    <option value="" label="Can Agent create user" />
                    <option value={true} label="Yes" />
                    <option value={false} label="No" />
                  </select>
                </div>
    
                {role === "3" ? 
                props.auth?.me?.role?.id === 1 &&
                <div className="row pt-3">
                   <select
                    name="color"
                    value={pname}
                    onChange={(e) => setpname(e.target.value)}
                    required="true"
                    className="required-fild-red"
                  >
                        <option value="" label="Select Agent" />
                        {props.showAgentsList.map((users, index) => 
                          <option value={users?.username} label={users?.username} />
                        )}
                    </select>
              </div> 
                : " "}
                {role === "3" ? 
                props.auth?.me?.role?.id === 2 &&
                <div className="row pt-3">
                   <select
                    name="color"
                    value={pname}
                    onChange={(e) => setpname(e.target.value)}
                  >
                        <option value="" label="Select Agent" />
                        {props.showAgentsList.map((users, index) => 
                          <option value={users?.username} label={users?.username} />
                        )}
                    </select>
              </div> 
                : " "}
                {role === "3" ? 
                props.auth?.me?.role?.name === "agent" &&
                <div className="row pt-3">
                   <select
                    name="color"
                    value={pname}
                    onChange={(e) => setpname(e.target.value)}
                    required="true"
                    className="required-fild-red"
                  >
                  { props.roleUsers.map((users, index) => 
                           users?.role?.id===2 && 
                          <option value={users?.username} label={users?.username} />
                          
                        )}
                    </select>
              </div> 
                :''}

                <div className="row pt-3">
                  <Form.Control
                    type="number"
                    placeholder="Enter Commission"
                    value={commission}
                    onChange={(e) => setcommission(e.target.value)}
                  />
                </div>
                {role === "3" &&
                <div className="row pt-3">
                  <div className="col-md-4 cntr-game-alow">
                  <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setRGames("611b69498a898d2aa6386cb3")}/>
                   Roulette</label>
                  </div>
                  <div className="col-md-4 cntr-game-alow">
                  <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setLGames("615ad717bfe7fcaa9cdd75e9")}/>
                   Lucky Card</label>
                  </div>
                  <div className="col-md-4 cntr-game-alow">
                  <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("61b45b0d5e89b7d5fe031b9c")}/>
                   Lucky Card Print</label>
                  </div>
                  <div className="col-md-4 cntr-game-alow">
                  <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("619b89ffb2e449216d0b970c")}/>
                   Spin to Win</label>
                   </div>
                   <div className="col-md-4 cntr-game-alow">
                  <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("619b8a1eb2e449216d0b970d")}/>
                   52 Cards</label>
                   </div>
                   <div className="col-md-4 cntr-game-alow">
                   <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("625d525766b1a73d90b7f752")}/>
                   52 Cards Print</label>
                   </div>
                   <div className="col-md-4 cntr-game-alow">
                   <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("625e870d85c46e39c0884f7b")}/>
                    Triple Chance</label>
                    </div>
                    <div className="col-md-4 cntr-game-alow">
                   <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("625e886385c46e39c0884f7c")}/>
                    Print Triple Chance</label>
                    </div>
                    <div className="col-md-4 cntr-game-alow">
                   <label for="scales">
                    <input type="checkbox" name="games" value={games} onChange={(e) => setlPgames("62679a9a33c0d81c20065cc8")}/>
                   Print Spin to Win</label>
                  </div>
                </div>
                }
                <div className="row pt-3">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    required="true"
                    onChange={(e) => setpassword(e.target.value)}
                    className="required-fild-red"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} variant="success">
            Submit
          </Button>
          <div className="row cntr-text"><span className="text-req-red">{props.errors}</span><span className="required-scus-red">{!!props && props?.roleScuss}</span></div>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => (
  {
  roleScuss : state.users?.addNewUserByRole,
  roleUsers: state.users.roleUsers,
  errors: state.users?.error,
  auth: state.auth,
});

export default connect(mapStateToProps, { addUsers, allUsers })(AddUser);

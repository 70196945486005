import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  ADD_SETTINGS_LOADING,
  ADD_SETTINGS_SUCCESS,
  ADD_SETTINGS_FAIL,
  DELETE_SETTINGS_LOADING,
  DELETE_SETTINGS_SUCCESS,
  DELETE_SETTINGS_FAIL,
  EDIT_SETTINGS_LOADING,
  EDIT_SETTINGS_SUCCESS,
  EDIT_SETTINGS_FAIL,
  CLEAR_SETTINGS_ERROR,
  GET_LOCKEDDEVICES_LOADING,
  GET_LOCKEDDEVICES_SUCCESS,
  GET_LOCKEDDEVICES_FAIL,
  DELETE_LOCKEDDEVICES_LOADING,
  DELETE_LOCKEDDEVICES_SUCCESS,
  DELETE_LOCKEDDEVICES_FAIL,
} from '../types';

export const getSettings = (game) => async (dispatch, getState) => {
  dispatch({
    type: GET_SETTINGS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/settings/${game}`, options);
    dispatch({
      type: GET_SETTINGS_SUCCESS,
      payload: { settingData: response.data.settings },
    });
  } catch (err) {
    dispatch({
      type: GET_SETTINGS_FAIL,
      payload: { error: err?.response?.data.settings || err.settings },
    });
  }
};
export const addUsers =
  (id, name, username, email, phone, role, password) => async (dispatch, getState) => {
    dispatch({
      type: ADD_SETTINGS_LOADING,
      payload: { me: { ...getState().auth.me } },
    });
    try {
      const formData = { id, name, username, email, phone, role, password };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/users', formData, options);

      dispatch({
        type: ADD_SETTINGS_SUCCESS,
        payload: { message: response.data.message },
      });
    } catch (err) {
      dispatch({
        type: ADD_SETTINGS_FAIL,
        payload: { error: err?.response?.data.message || err.message },
      });
    }
  };

export const deleteUsers = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_SETTINGS_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`/api/users/${id}`, options);
    dispatch({
      type: DELETE_SETTINGS_SUCCESS,
      payload: { message: response.data.message },
    });
  } catch (err) {
    dispatch({
      type: DELETE_SETTINGS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editSetting = (id, value) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_SETTINGS_LOADING,
    payload: { id },
  });
  try {
    const formData = { value };
    const options = attachTokenToHeaders(getState);
    const response = await axios.put(`/api/settings/${id}`, formData, options);

    dispatch({
      type: EDIT_SETTINGS_SUCCESS,
      payload: { settingData: response.data.settings },
    });
  } catch (err) {
    dispatch({
      type: EDIT_SETTINGS_FAIL,
      payload: { error: err?.response?.data.settings || err.settings, id },
    });
  }
};

export const clearUsersError = (id) => ({
  type: CLEAR_SETTINGS_ERROR,
  payload: { id },
});

export const deleteLockedDevices = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_LOCKEDDEVICES_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`/api/lockeddevices/${id}`, options);
    dispatch({
      type: DELETE_LOCKEDDEVICES_SUCCESS,
      payload: { message: response.data.message },
    });
  } catch (err) {
    dispatch({
      type: DELETE_LOCKEDDEVICES_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getLockedDevices = (currentPage) => async (dispatch, getState) => {
  dispatch({
    type: GET_LOCKEDDEVICES_LOADING,
  });
  try {
    const { lockedDevicesList } = getState().settings;
    const formData = {currentPage};
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/lockeddevices', formData, options);
    dispatch({
      type: GET_LOCKEDDEVICES_SUCCESS,
      payload: { 
        lockedDevice: [...lockedDevicesList, ...response.data.lockedDevices],
        blankmsg: response.data.lockedDevices,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_LOCKEDDEVICES_FAIL,
      payload: { error: err?.response?.data.games || err.games },
    });
  }
};

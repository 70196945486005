import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { transaction, bettings, clearUsersError } from '../../store/actions/usersAction';
import './styles.css';
import { useParams } from 'react-router-dom';

const UserBetting = ({ bettings, Userbettings, blankPlaying }) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    bettings(id);
  }, [bettings, id]);

  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    bettings(id, currentPage);
  }

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'Game', prop: 'game' },
    { title: 'Name', prop: 'name'},
    { title: 'Bet Name', prop: 'betting_type'},
    { title: 'Amount', prop: 'amount' },
    { title: 'Winning_x', prop: 'winning_x' },
    { title: 'Status', prop: 'status' },
    { title: 'Numbers', prop: 'numbers' },
    { title: 'Tokens', prop: 'tokens' },
  ];
  // Table Body Data
  let bodyData = [];
  if (Userbettings !== undefined) {
    bodyData = [
      Userbettings.map((users, index) => {
        return {
          srno: index + 1,
          game: users?.game_history,
          name: users?.name,
          betting_type: users?.betting_type?.name,
          amount: users?.amount,
          winning_x: users?.betting_type?.winning_x,
          status: users?.status,
          numbers: users?.numbers?.toString(),
          tokens: users?.tokens?.toString(),
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };


  return (
    <div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
        // rowsPerPage={50}
      />
      {
        !!blankPlaying && !!blankPlaying.length > 0 ?
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>Load More.....</button>
          </div>
        </div>
        : ""
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  Userbettings: state.users.Userbettings,
  blankPlaying: state.users.blankPlaying,
  auth: state.auth,
});

export default connect(mapStateToProps, { transaction, bettings, clearUsersError })(UserBetting);

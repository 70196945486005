import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addWallet, getWallet } from '../../store/actions/walletAction';
import { allUsers } from '../../store/actions/usersAction';

function SubWallet(props) {
  const [trans_coins, settrans_coins] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    props.apiReload();
    window.location.reload();
  };
  const handleShow = () => setShow(true);

  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = () => {
    props.addWallet(props.userid, -Math.abs(trans_coins));
    // alert(!props.statusMessage ? 'Your Amount is Subtract Successfully' : props.statusMessage);
    // window.location.reload();
    // setShow(false);
  };
  return (
    <>
      <Button className={props.customClass} onClick={handleShow} variant={props.buttnVariant}>
        {props.icon}
      </Button>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 profile-img cntr">
              <h3>{props.title}</h3>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 profile-sub-details">
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your ID"
                    value={props.username}
                    disabled
                  />
                </div>
                <div className="row pt-3">
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Amount"
                    value={trans_coins}
                    onChange={(e) => settrans_coins(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit} variant="success">
            Submit
          </Button>
          <div className="row cntr-text">{props.statusMessage || props.scuMsg}</div>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  statusMessage: state.wallet.error,
  scuMsg: state.wallet.scussMsg
});
export default connect(mapStateToProps, { getWallet, addWallet, allUsers })(SubWallet);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import {  allUsers, errorStatusShow } from '../../store/actions/usersAction';
import Profile from '../Navbar/Profile';
import './styles.css';
import AddUser from '../../pages/Users/AddUser';
import EditUser from '../../pages/Users/EditUser';
import AddWallet from '../../pages/Wallet/AddWallet';
import SubWallet from '../../pages/Wallet/SubWallet';

const AgentList = ({
  allUsers,
  roleUsers,
  message,
}) => {
  useEffect(() => {
    allUsers(2);
  }, [allUsers]);

  // ALL Users
  const allUsersGet = (e) => {
    e.preventDefault();
    allUsers(1);
  };
  // ALL Users
  const allAgents = (e) => {
    e.preventDefault();
    allUsers(2);
  };
  // ALL Users
  const allCustomer = (e) => {
    e.preventDefault();
    allUsers(3);
  };
  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'User Name', prop: 'username'},
    { title: 'Agent', prop: 'parentName'},
    { title: 'Date', prop: 'date' },
    { title: 'Comision', prop: 'commission' },
    { title: 'Transactions', prop: 'tran' },
    { title: 'Playing', prop: 'betting' },
    { title: 'Profit/Loss', prop: 'profitloss' },
    { title: 'Points', prop: 'coin' },
    { title: 'Wallet', prop: 'wallet' },
    { title: 'Role', prop: 'role' },
    { title: 'Status', prop: 'userstatus' },
    { title: 'Actions', prop: 'action' },
  ];
  let gameadd = 'ADD NEW USER';
  let gameprofileView = <i className="fa fa-eye view-icon" aria-hidden="true"></i>;

  //http://qqq.google.xom?page=1
  // Table Body Data
  let bodyData = [];

  if (roleUsers !== undefined) {
    bodyData = [
      roleUsers.map((users, index) => {
        let tran = (
          <Link to={'/user-transaction/' + users?.id} variant="info" className="">
            <i className="fa fa-money" aria-hidden="true"></i> Transactions
          </Link>
        );
        let bettings = (
          <Link to={'/user-betting/' + users?.id} variant="info" className="">
            {users.betting_points}
          </Link>
        );
        let agentName = users?.parent?.name ? (
          <Link to={'/user-parent/' + users?.parent?.id}>{users?.parent?.name}</Link>
        ) : (
          'Admin'
        );
        let wallet = [
          <div className="cntr-text">
            <AddWallet
              apiReload={allUsers}
              errorStatus={errorStatusShow}
              username={users?.username}
              userid={users?.id}
              customClass={'sub-tbl-btn betting-btn btn-spac'}
              title={'ADD Amount'}
              icon={<i className="fa fa-plus" aria-hidden="true"></i>}
            />
            {users?.username === 'admin' ? (
              ''
            ) : (
              <SubWallet
                apiReload={allUsers}
                username={users?.username}
                userid={users?.id}
                customClass={'sub-tbl-btn wallet-btn btn-spac'}
                title={'Withdrawal Amount'}
                icon={<i className="fa fa-minus" aria-hidden="true"></i>}
              />
            )}
          </div>,
        ];
        let deleteicon = [
          <Profile
            profileicon={gameprofileView}
            coins={users?.coins}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role?.name}
          />,
          <EditUser
            gameid={users?.id}
            name={users?.name}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            commisionAmount={users?.commission}
          />,
          //<span className="delete-icon" onClick={(e) => handleDelete(e, users.id)} type="button"><i className="fa fa-trash" aria-hidden="true"></i></span>
        ];
        let userView = (
          <Profile
            coins={users?.coins}
            profileicon={users?.username}
            username={users?.username}
            email={users?.email}
            phone={users?.phone}
            role={users?.role?.name}
          />
        );
        let profit = users.betting_points - users.profit_loss;
        return {
          srno: index + 1,
          username: userView,
          parentName: agentName,
          date: moment(users.updatedAt).format('DD/MM/YYYY'),
          commission: users?.commission + '%',
          tran: tran,
          betting: bettings,
          profitloss: profit,
          coin: users?.coins,
          wallet: wallet,
          role: users?.role?.name,
          userstatus: users.status ? 'Unblock' : 'Block',
          action: deleteicon,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={message.isLoading ? 'message loader' : 'message'}>
      <div>
        <AddUser addGame={gameadd} />
        <button onClick={allUsersGet} type="button" className="btn btn-primary btns-width">
          ALL
        </button>
        <button onClick={allAgents} type="button" className="btn btn-danger btns-width">
          AGENTS
        </button>
        <button onClick={allCustomer} type="button" className="btn btn-info btns-width">
          USERS
        </button>
      </div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.users.messages,
  roleUsers: state.users.roleUsers,
});

export default connect(mapStateToProps, {
  allUsers,
})(AgentList);

import { GET_INACTIVE_SUCCESS, GET_INACTIVE_FAIL, CLEAR_INACTIVE_ERROR } from '../types';

const initialState = {
  inactiveData: [],
  isLoading: false,
  error: null,
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_INACTIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inactiveData: payload.inactiveListUsers,
        blankInactive: payload.blankInactive,
      };
    case GET_INACTIVE_FAIL:
      return {
        ...state,
        isLoading: false,
        inactiveData: [],
        error: payload,
      };
    case CLEAR_INACTIVE_ERROR:
      return {
        ...state,
        inactiveData: state.inactiveData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

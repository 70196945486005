import aSpade from "../../images/fiftycards/1.png"
import twoSpade from "../../images/fiftycards/2.png"
import threeSpade from "../../images/fiftycards/3.png"
import forSpade from "../../images/fiftycards/4.png"
import fiveSpade from "../../images/fiftycards/5.png"
import sixSpade from "../../images/fiftycards/6.png"
import sevenSpade from "../../images/fiftycards/7.png"
import eghtSpade from "../../images/fiftycards/8.png"
import nineSpade from "../../images/fiftycards/9.png"
import tenSpade from "../../images/fiftycards/10.png"
import elevnSpade from "../../images/fiftycards/11.png"
import twelSpade from "../../images/fiftycards/12.png"
import thirteenSpade from "../../images/fiftycards/13.png"
import aClub from "../../images/fiftycards/14.png"
import twoClub from "../../images/fiftycards/15.png"
import threeClub from "../../images/fiftycards/16.png"
import forClub from "../../images/fiftycards/17.png"
import fiveClub from "../../images/fiftycards/18.png"
import sixClub from "../../images/fiftycards/19.png"
import sevenClub from "../../images/fiftycards/20.png"
import eghtClub from "../../images/fiftycards/21.png"
import nineClub from "../../images/fiftycards/22.png"
import tenClub from "../../images/fiftycards/23.png"
import elevnClub from "../../images/fiftycards/24.png"
import twelClub from "../../images/fiftycards/25.png"
import thirteenClub from "../../images/fiftycards/26.png"
import aHeart from "../../images/fiftycards/27.png"
import twoHeart from "../../images/fiftycards/28.png"
import threeHeart from "../../images/fiftycards/29.png"
import forHeart from "../../images/fiftycards/30.png"
import fiveHeart from "../../images/fiftycards/31.png"
import sixHeart from "../../images/fiftycards/32.png"
import sevenHeart from "../../images/fiftycards/33.png"
import eghtHeart from "../../images/fiftycards/34.png"
import nineHeart from "../../images/fiftycards/35.png"
import tenHeart from "../../images/fiftycards/36.png"
import elevnHeart from "../../images/fiftycards/37.png"
import twelHeart from "../../images/fiftycards/38.png"
import thirteenHeart from "../../images/fiftycards/39.png"
import aDiamond from "../../images/fiftycards/40.png"
import twoDiamond from "../../images/fiftycards/41.png"
import threeDiamond from "../../images/fiftycards/42.png"
import forDiamond from "../../images/fiftycards/43.png"
import fiveDiamond from "../../images/fiftycards/44.png"
import sixDiamond from "../../images/fiftycards/45.png"
import sevenDiamond from "../../images/fiftycards/46.png"
import eghtDiamond from "../../images/fiftycards/47.png"
import nineDiamond from "../../images/fiftycards/48.png"
import tenDiamond from "../../images/fiftycards/49.png"
import elevnDiamond from "../../images/fiftycards/50.png"
import twelDiamond from "../../images/fiftycards/51.png"
import thirteenDiamond from "../../images/fiftycards/52.png"
import spintowinZero from "../../images/spintowin/0.png"
import spintowinOne from "../../images/spintowin/1.png"
import spintowinTwo from "../../images/spintowin/2.png"
import spintowinThree from "../../images/spintowin/3.png"
import spintowinFour from "../../images/spintowin/4.png"
import spintowinFive from "../../images/spintowin/5.png"
import spintowinSix from "../../images/spintowin/6.png"
import spintowinSeven from "../../images/spintowin/7.png"
import spintowinEgt from "../../images/spintowin/8.png"
import spintowinNine from "../../images/spintowin/9.png"

export const jackPotno = [
  {
    jackNo: 2,
  },
  {
    jackNo: 3,
  },
  {
    jackNo: 4,
  },
  {
    jackNo: 5,
  },
  {
    jackNo: 6,
  },
  {
    jackNo: 7,
  },
  {
    jackNo: 8,
  },
];
export const cards = [
  {
    number: 1,
    class: 'card-img-luky',
  },
  {
    number: 2,
    class: 'card-img-luky-two',
  },
  {
    number: 3,
    class: 'card-img-luky-three',
  },
  {
    number: 4,
    class: 'card-img-luky-for',
  },
  {
    number: 5,
    class: 'card-img-luky-five',
  },
  {
    number: 6,
    class: 'card-img-luky-six',
  },
  {
    number: 7,
    class: 'card-img-luky-seven',
  },
  {
    number: 8,
    class: 'card-img-luky-eight',
  },
  {
    number: 9,
    class: 'card-img-luky-nine',
  },
  {
    number: 10,
    class: 'card-img-luky-ten',
  },
  {
    number: 11,
    class: 'card-img-luky-eleven',
  },
  {
    number: 12,
    class: 'card-img-luky-twel',
  },
];
export const spinTowinCards = [
  {
    number: 0,
    img : spintowinZero
  },
  {
    number: 1,
    img : spintowinOne
  },
  {
    number: 2,
    img : spintowinTwo
  },
  {
    number: 3,
    img : spintowinThree
  },
  {
    number: 4,
    img : spintowinFour
  },
  {
    number: 5,
    img : spintowinFive
  },
  {
    number: 6,
    img : spintowinSix
  },
  {
    number: 7,
    img : spintowinSeven
  },
  {
    number: 8,
    img : spintowinEgt
  },
  {
    number: 9,
    img : spintowinNine
  }
]
export const fiftyTwoCards = [
  {
    number: 1,
    img : aSpade
  },
  {
    number: 2,
    img : twoSpade
  },
  {
    number: 3,
    img : threeSpade
  },
  {
    number: 4,
    img: forSpade 
  },
  {
    number: 5,
    img: fiveSpade
  },
  {
    number: 6,
    img: sixSpade
  },
  {
    number: 7,
    img : sevenSpade
  },
  {
    number: 8,
    img : eghtSpade
  },
  {
    number: 9,
    img : nineSpade
  },
  {
    number: 10,
    img : tenSpade
  },
  {
    number: 11,
    img : elevnSpade
  },
  {
    number: 12,
    img: twelSpade
  },
  {
    number: 13,
    img : thirteenSpade
  },
  {
    number: 14,
    img : aClub
  },
  {
    number: 15,
    img : twoClub
  },
  {
    number: 16,
    img : threeClub
  },
  {
    number: 17,
    img: forClub 
  },
  {
    number: 18,
    img: fiveClub
  },
  {
    number: 19,
    img: sixClub
  },
  {
    number: 20,
    img : sevenClub
  },
  {
    number: 21,
    img : eghtClub
  },
  {
    number: 22,
    img : nineClub
  },
  {
    number: 23,
    img : tenClub
  },
  {
    number: 24,
    img : elevnClub
  },
  {
    number: 24,
    img: twelClub
  },
  {
    number: 26,
    img : thirteenClub
  },
  {
    number: 27,
    img : aHeart
  },
  {
    number: 28,
    img : twoHeart
  },
  {
    number: 29,
    img : threeHeart
  },
  {
    number: 30,
    img: forHeart 
  },
  {
    number: 31,
    img: fiveHeart
  },
  {
    number: 32,
    img: sixHeart
  },
  {
    number: 33,
    img : sevenHeart
  },
  {
    number: 34,
    img : eghtHeart
  },
  {
    number: 35,
    img : nineHeart
  },
  {
    number: 36,
    img : tenHeart
  },
  {
    number: 37,
    img : elevnHeart
  },
  {
    number: 38,
    img: twelHeart
  },
  {
    number: 39,
    img : thirteenHeart
  },
  {
    number: 40,
    img : aDiamond
  },
  {
    number: 41,
    img : twoDiamond
  },
  {
    number: 42,
    img : threeDiamond
  },
  {
    number: 43,
    img: forDiamond 
  },
  {
    number: 44,
    img: fiveDiamond
  },
  {
    number: 45,
    img: sixDiamond
  },
  {
    number: 46,
    img : sevenDiamond
  },
  {
    number: 47,
    img : eghtDiamond
  },
  {
    number: 48,
    img : nineDiamond
  },
  {
    number: 49,
    img : tenDiamond
  },
  {
    number: 50,
    img : elevnDiamond
  },
  {
    number: 51,
    img: twelDiamond
  },
  {
    number: 52,
    img : thirteenDiamond
  },
];

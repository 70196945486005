import React, { useEffect } from 'react';
import './styles.css';
import UserGameBoard from '../components/BettingData/UserGameBoard';
import { gameTypeBoard } from '../store/actions/bettingDataAction';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import LcUserGameBoard from '../components/BettingData/LcUserGameBoard';
import FiftyTwoUserGameBoard from '../components/BettingData/FiftyTwoUserGameBoard';
import TripalChanceUserBoard from '../components/BettingData/TripalChanceUserBoard';
import SpinToWinUser from '../components/BettingData/SpinToWinUser';

const GameBoard = ({ gameTypeBoard, gameDetails }) => {
  const { id } = useParams();
  useEffect(() => {
    gameTypeBoard(id);
  }, [gameTypeBoard, id]);

  return (
    <>
            {gameDetails?.game === '611b69498a898d2aa6386cb3' && <UserGameBoard /> }
            {gameDetails?.game === '615ad717bfe7fcaa9cdd75e9' && <LcUserGameBoard /> }
            {gameDetails?.game === '619b8a1eb2e449216d0b970d' && <FiftyTwoUserGameBoard/> }
            {gameDetails?.game === '625e870d85c46e39c0884f7b' && <TripalChanceUserBoard/> }
            {gameDetails?.game === '619b89ffb2e449216d0b970c' && <SpinToWinUser/> }
    </>
  );
};
const mapStateToProps = (state) => (
  {
  gameDetails: state.bettingData?.gameDetailsOnHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { gameTypeBoard })(GameBoard);

import {
  GET_DASHBOARD_LOADING,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
  CLEAR_DASHBOARD_ERROR,
} from '../types';

const initialState = {
  dashboardData: [],
  webAppLogo: [],
  isLoading: false,
  error: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardData: payload.dashboardDataApi,
        webAppLogo : payload.appLogo
      };

    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    case CLEAR_DASHBOARD_ERROR:
      return {
        ...state,
        dashboardData: state.dashboardData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

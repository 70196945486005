import { GET_HISTORY_SUCCESS, GET_HISTORY_FAIL, CLEAR_HISTORY_ERROR } from '../types';

const initialState = {
  gameHistory: [],
  isLoading: false,
  error: null,
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gameHistory: payload.gameHistory,
        blankHistory: payload.blankHistory,
      };
    case GET_HISTORY_FAIL:
      return {
        ...state,
        isLoading: true,
        gameHistory: [],
        error: payload,
      };
    case CLEAR_HISTORY_ERROR:
      return {
        ...state,
        gameHistory: state.gameHistory.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { getHistory } from '../../store/actions/historyAction';
import { Button, Form } from 'react-bootstrap';

import './styles.css';
import { GET_HISTORY_SUCCESS } from '../../store/types';
import { getGames } from '../../store/actions/gamesAction';

const HistoryList = ({ getHistory, gameHistory, getGames, gamesList, blankHistory }) => {
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [day, setDay] = useState();
  const [gameId, setGameId] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_HISTORY_SUCCESS,
      payload: { gameHistory: [] },
    });
    getHistory();
  }, [getHistory, dispatch]);
  useEffect(() => {
    getGames();
  }, []);
  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getHistory(startDate, endDate, day, gameId, currentPage);
  };
  const clearState = () => {
    setCurrentPage(1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_HISTORY_SUCCESS,
      payload: { gameHistory: [] },
    });
    getHistory(startDate, endDate, day, gameId).then(clearState);
  };

  const autoFillDate = (e) => {
    let daysData = e.target.value;
    if (daysData === 'today') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisWeek') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('week').add('d', 1).format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'lastWeek') {
      setDay(e.target.value);
      setendDate(
        moment().subtract(1, 'weeks').endOf('week').add('d', 1).format('YYYY-MM-DDTHH:mm:ss'),
      );
      setstartDate(
        moment().subtract(1, 'weeks').startOf('week').add('d', 1).format('YYYY-MM-DDT00:00:00'),
      );
    }
    if (daysData === 'thisMonth') {
      setDay(e.target.value);
      setendDate(moment().format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
    if (daysData === 'thisLastMonth') {
      setDay(e.target.value);
      setendDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
      setstartDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDT00:00:00'));
    }
  };

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'Game ID', prop: 'gameid' },
    { title: 'Total Playing', prop: 'gamename' },
    { title: 'Total Winning', prop: 'status' },
    { title: 'Start', prop: 'start' },
    { title: 'End', prop: 'end' },
    { title: 'View Users', prop: 'viewUsers' },
  ];
  // Table Body Data
  let bodyData = [];
  if (gameHistory !== undefined && gameHistory.length > 0) {
    bodyData = [
      gameHistory.map((users, index) => {
        let viewUsers = (
          <Link to={'/history-user/' + users.id} className="setting-btn">
            <i className="fa fa-user" aria-hidden="true"></i> Users
          </Link>
        );
        let startDate = new Date(users.start);
        let endDate = new Date(users.end);

        return {
          srno: index + 1,
          gameid: <Link to={`/game-board/${users?.id}`}>{users?.id}</Link>,
          gamename: users?.total_betting.toFixed(2),
          status: users?.total_winning.toFixed(2),
          start: moment(startDate).format('DD/MM/YYYY hh:mm:ss'),
          end: moment(endDate).format('DD/MM/YYYY hh:mm:ss'),
          viewUsers: viewUsers,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={gameHistory.isLoading ? 'history loader' : 'history'}>
      <div className="row">
        <div className="col-md-3">
          <label>
            <b>Start Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={startDate}
            onChange={(e) => setstartDate(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <label>
            <b>End Date</b>
          </label>
          <Form.Control
            type="datetime-local"
            placeholder="Enter Date"
            step="1"
            value={endDate}
            onChange={(e) => setendDate(e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <label>
            <b>Day</b>
          </label>
          <br />
          <select name="days" className="select-days" value={day} onChange={autoFillDate}>
            <option value="">Select Days</option>
            <option value="today">Today</option>
            <option value="thisWeek">This Week</option>
            <option value="lastWeek">Last Week</option>
            <option value="thisMonth">This Month</option>
            <option value="thisLastMonth">This Last Month</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>
            <b>Game Name</b>
          </label>
          <br />
          <select name="days" className="select-days" onChange={(e) => setGameId(e.target.value)}>
            <option value="">Games</option>
            {gamesList.map(
              (games, index) =>
                !games?.name.match('Print') && !games?.name.match('jhandi munda') && !games?.status == false && <option value={games.id}>{games.name}</option>
            )}
          </select>
        </div>
        <div className="col-md-2 date-selector-btn">
          <Button variant="success" type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
      {!!blankHistory && !!blankHistory.length > 0 ? (
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>
              Load More.....
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  gameHistory: state.history.gameHistory,
  blankHistory: state.history.blankHistory,
  gamesList: state.games?.games,
  auth: state.auth,
});
export default connect(mapStateToProps, { getHistory, getGames })(HistoryList);

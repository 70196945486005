import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { getSettings, editSetting, clearUsersError } from '../../store/actions/settingsAction';
import './styles.css';
import EditSettings from '../../pages/Settings/EditSettings';
import { Button } from 'react-bootstrap';
import { getGames } from '../../store/actions/gamesAction';
import { GET_SETTINGS_SUCCESS } from '../../store/types';

const SettingList = ({ getSettings, settingData, getGames, gamesDataList }) => {
  const [gamesList, setGamesList] = useState('all');
  useEffect(() => {
    getSettings(gamesList);
    getGames();
  }, [getSettings, getGames]);

  const dispatch = useDispatch();

  const onSubmitGame = () => {
    dispatch({
      type: GET_SETTINGS_SUCCESS,
      payload: { settingData : [] },
    });
    getSettings(gamesList);
  };
  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'Name', prop: 'name' },
    { title: 'Help Text', prop: 'helptext' },
    { title: 'Value', prop: 'value' },
  ];
  // Table Body Data
  let bodyData = [];
  if (settingData !== undefined) {
    bodyData = [
      settingData.map((users, index) => {
        let deleteicon = [<EditSettings settingid={users?.id} value={users?.value} />];
        return {
          srno: index + 1,
          name: users?.name,
          helptext: users?.halptext,
          value: deleteicon,
        };
      }),
    ];
  }
  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={settingData.isLoading ? 'settingData loader' : 'settingData'}>
      <div className="row mb-2">
        <div className="col-md-5 form-group search-input-usr game-setting-filter">
          <select
            name="days"
            className="select-days col-md-8"
            value={gamesList}
            onChange={(e) => setGamesList(e.target.value)}
          >
            <option value="all">Games</option>
            {gamesDataList.map((games, index) => (
              !games?.name.match('Print') && !games?.name.match('jhandi munda') && <option value={games?.id}>{games?.name}</option>
            ))}
          </select>
          <Button variant="success" type="submit" onClick={onSubmitGame}>
            Submit
          </Button>
        </div>
      </div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  settingData: state.settings.settingData,
  gamesDataList: state.games?.games,
  auth: state.auth,
});

export default connect(mapStateToProps, { getSettings, getGames, editSetting, clearUsersError })(
  SettingList,
);

import React, { useEffect } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { placedUserBet } from '../../store/actions/bettingDataAction';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { fiftyTwoCards } from './card-data';

function TripalChanceUserBoard({
  placedUserBet,
  userBetNumber,
  userbetlist,
  winDetailData,
  winDetailHistory,
}) {
  const { gameid, id } = useParams();

  useEffect(() => {
    placedUserBet(gameid, id);
  }, [placedUserBet, gameid, id]);

  let winLuckyCrdTop = !!winDetailData && winDetailData.winning;
  let startTime = winDetailHistory?.start;
  let endTime = winDetailHistory?.end;
  // winLuckyCrdTop = parseFloat(winLuckyCrdTop);
  return (
    <>
      {/* input type */}
      <div className="row lucky-card-mrgn">
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-4">
              <h5>Single Number</h5>
              <table className="game-board-winTbl">
                <tr>
                  <th>Number</th>
                  <th>Playing Amount</th>
                </tr>
                {winDetailData?.numbers !== undefined &&
                  Object.entries(winDetailData?.numbers).map(([key, value]) => {
                    return (
                      <>
                        {key.length === 1 && (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
            {/* end */}
            <div className="col-md-4">
              <h5>Double Number</h5>
              <table className="game-board-winTbl">
                <tr>
                  <th>Number</th>
                  <th>Playing Amount</th>
                </tr>
                {winDetailData?.numbers !== undefined &&
                  Object.entries(winDetailData?.numbers).map(([key, value]) => {
                    return (
                      <>
                        {key.length === 2 && (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
            {/* end */}
            <div className="col-md-4">
              <h5>Triple Number</h5>
              <table className="game-board-winTbl">
                <tr>
                  <th>Number</th>
                  <th>Playing Amount</th>
                </tr>
                {winDetailData?.numbers !== undefined &&
                  Object.entries(winDetailData?.numbers).map(([key, value]) => {
                    return (
                      <>
                        {key.length === 3 && (
                          <tr>
                            <td>{key}</td>
                            <td>{value}</td>
                          </tr>
                        )}
                      </>
                    );
                  })}
              </table>
            </div>
            {/* end */}
          </div>
        </div>
        <div className="col-md-5">
          <div class="text-center">
            <b>Game ID</b>:- {id}
          </div>
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>Start</b>:- {moment(startTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
            <div className="col-md-6 winrs">
              <b>End</b>:-
              {moment(endTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
          </div>
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>BetTotal</b>:- {winDetailData?.betTotal}
            </div>
            <div className="col-md-6 winrs ">
              {console.log("fiftyTwoCards==>", fiftyTwoCards)}
              <b>Winning Number</b>:- {!!winDetailData && winDetailData?.winningNumber}
              {/* {fiftyTwoCards.map((data, index) => {
                console.log("datadatadata", data)
                return (
                  <>
                    {!!winDetailData &&
                      !!winDetailData?.winningNumber &&
                      data.number == winDetailData?.winningNumber && <img src={data.img} alt="Games cards"/>}
                  </>
                );
              })} */}
            </div>
          </div>
          {/* end */}
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>Jackpot</b>:- {winDetailData?.jackpot}
            </div>
            <div className="col-md-6 winrs">
              <b>Winning Amount</b>:- {winLuckyCrdTop}
              {/* {cards.map((data, index) => {
                  return(
                    <>
                   <b>{!!winDetailData && !!winDetailData?.winningNumber && data.number == winDetailData?.winningNumber && data.betName}</b> 
                    </>
                  )
              })
              } */}
            </div>
          </div>
          {/* end */}
          <div className="row txt-cntr mrg-top-dtd hdr-bg">
            <div className="col-md-6">
              <b>Betting Type</b>
            </div>
            <div className="col-md-6">
              <b>Amount</b>
            </div>
          </div>
          {userbetlist !== undefined
            ? Object.keys(userbetlist).map((key) => {
                return (
                  <>
                    <div className="row txt-cntr betting-type-dta">
                      <div className="col-md-6">{key}</div>
                      <div className="col-md-6">{userbetlist[key]}</div>
                    </div>
                  </>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
    userBetNumber: state.bettingData.userPlaced,
    userbetlist: state.bettingData?.bettingType,
    winDetailData: state.bettingData?.winDetailUser,
    winDetailHistory: state.bettingData?.gameDetailsOnHistory,
    auth: state.auth,
  }
);

export default connect(mapStateToProps, { placedUserBet })(TripalChanceUserBoard);

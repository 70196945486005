import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_WINNING_LOADING,
  GET_WINNING_SUCCESS,
  GET_WINNING_FAIL,
  CLEAR_WINNING_ERROR,
} from '../types';

export const getWinerData = () => async (dispatch, getState) => {
  dispatch({
    type: GET_WINNING_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/dashboard/winners', options);
    dispatch({
      type: GET_WINNING_SUCCESS,
      payload: { winningData: response.data.data },
    });
  } catch (err) {
    dispatch({
      type: GET_WINNING_FAIL,
      payload: err.message,
    });
  }
};

export const clearHistoryError = (id) => ({
  type: CLEAR_WINNING_ERROR,
  payload: { id },
});

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { getBettingtypes } from '../../store/actions/bettingActions';
import { deleteUsers } from '../../store/actions/usersAction';
import './styles.css';
import EditBettingType from '../../pages/BettingTypes/EditBettingType';

const BettingsUsersList = ({ bettingTypes, getBettingtypes, deleteUsers }) => {
  useEffect(() => {
    getBettingtypes();
  }, [getBettingtypes]);

  // const handleDelete = (e, id) => {
  //   e.preventDefault();
  //   if (!isEdit) {
  //     deleteUsers(id);
  //   }
  // };

  // Table data
  const header = [
    { title: 'Name', prop: 'name'},
    { title: 'Game Name', prop: 'gamename'},
    { title: 'Category', prop: 'category'},
    { title: 'Winning_x', prop: 'winning_x' },
    { title: 'Status', prop: 'status' },
    { title: 'Description', prop: 'description' },
    { title: 'Actions', prop: 'action' },
  ];

  let bodyData = [];
  if (bettingTypes !== undefined) {
    // Table Body Data
    bodyData = [
      bettingTypes.map((betting, index) => {
        let editProfile = [
          <EditBettingType
            name={betting?.name}
            gameid={betting?.id}
            winningX={betting?.winning_x}
            status={betting?.status}
            desc={betting?.description}
          />,
          // <span onClick={(e) => handleDelete(e, betting.id)} type="button"><i className="fa fa-trash" aria-hidden="true"></i></span>
        ];
        let statusTrue = 'true';
        let satusFalse = 'false';
        return {
          name: betting?.name,
          gamename: betting?.game?.name,
          category: betting?.category,
          winning_x: betting?.winning_x,
          status: betting?.status ? statusTrue : satusFalse,
          description: betting?.description,
          action: editProfile,
        };
      }),
    ];
  }
  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className={bettingTypes.isLoading ? 'message loader' : 'message'}>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
        rowsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bettingTypes: state.betting.bettingtypes,
  auth: state.auth,
});

export default connect(mapStateToProps, { getBettingtypes, deleteUsers })(BettingsUsersList);

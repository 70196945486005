import React from 'react';
import { connect } from 'react-redux';
import { logOutSUser, allUsers } from '../../store/actions/usersAction';


function LogoutUser(props) {
let userId = props.userid;
  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = () => {
    props.logOutSUser(userId);
    alert(!props?.logOutDtl ? 'Logout Successfully.' : 'Logout Successfully.');
    window.location.reload();
  };
  return (
    <>
      <i onClick={handleSubmit} className="fa fa-power-off role-base-comision logout-btn" aria-hidden="true"></i>
    </>
  );
}
const mapStateToProps = (state) => ({
  statusMessage: state.wallet.error,
  logOutDtl : state.users?.addNewUserByRole,
  auth: state.auth,
});
export default connect(mapStateToProps, { logOutSUser, allUsers })(LogoutUser);

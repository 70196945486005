import React, { useState, useEffect } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import {
  jakPotNuberLuckyCard,
  fiftyTwomanualNumber,
  fiftyTwoCurrentGameBet,
  fiftyToCardDayResult,
  fiftyTwoCurrentGameBetId,
} from '../../store/actions/bettingDataAction';
import { Button } from 'react-bootstrap';
import { fiftyTwoCards } from './card-data';

function FiftyTwoCardsGame(props) {
  const [active, setActive] = useState(null);
  const [jakPotNmbr, setjakPotNmbr] = useState('');
  const [betNameDtl, setBetName] = useState(null);
  const [cardSelectIndex, setCardSelectIndex] = useState(0);

  let gameCountr = props.lccurrentGameDetails?.counter;
  const [counter, setCounter] = useState(false);

  useEffect(() => {
    props.fiftyTwoCurrentGameBet();
    const intervalData = setInterval(() => {
      props.fiftyTwoCurrentGameBet();
    }, 10000);
    return () => clearInterval(intervalData);
  }, []);

  useEffect(() => {
    props.fiftyToCardDayResult();
  }, []);

  useEffect(() => {
    setCounter(gameCountr);
  }, [props.lccurrentGameDetails?.counter]);

  useEffect(() => {
    const intervalList = setTimeout(() => {
      if (counter) {
        setCounter(counter ? counter - 1 : gameCountr - 1);
      } else if (counter === 0) {
        return <>{setCounter(105)}</>;
      }
    }, 1000);
    return () => clearInterval(intervalList);
  }, [counter, gameCountr]);

  useEffect(() => {
    props.fiftyTwoCurrentGameBetId();
    const interval = setInterval(() => {
      props.fiftyTwoCurrentGameBetId();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const drownumbr = () => {
    props.fiftyTwomanualNumber(fiftyTwoCards[active]?.number, +jakPotNmbr);
    // props.jakPotNuberLuckyCard(jakPotNmbr);
    props.fiftyTwoCurrentGameBet();
    props.fiftyToCardDayResult();
  };

  if (105 - counter === 0) {
    window.location.reload();
  }

  let proLoss =
  props.lastTenResult?.total?.[0]?.total_betting - props.lastTenResult?.total?.[0]?.total_winning;

  return (
    <div style={{ backgroundColor: 105 - counter > 74 && 105 - counter < 90 ? '#40d9c4' : '' }}>
      <div className="row ">
        <div className="col-md-6 disply-flx spcing-rmov">
          {jakPotNmbr === '0' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('0')}>
              1x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('0')}>
              1x
            </div>
          )}
          {jakPotNmbr === '2' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('2')}>
              2x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('2')}>
              2x
            </div>
          )}
          {jakPotNmbr === '3' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('3')}>
              3x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('3')}>
              3x
            </div>
          )}
          {jakPotNmbr === '4' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('4')}>
              4x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('4')}>
              4x
            </div>
          )}
          {jakPotNmbr === '5' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('5')}>
              5x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('5')}>
              5x
            </div>
          )}
          {jakPotNmbr === '6' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('6')}>
              6x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('6')}>
              6x
            </div>
          )}
          {jakPotNmbr === '7' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('7')}>
              7x
            </div>
          ) : (
            <div className="col jak-port-tab " onClick={() => setjakPotNmbr('7')}>
              7x
            </div>
          )}
          {jakPotNmbr === '8' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('8')}>
              8x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('8')}>
              8x
            </div>
          )}
        </div>
        <div className="col-md-6 disply-flx spcing-rmov">
          <div className="col-md-4 declr-nmbr">
            JackPot:-{' '}
            <input
              className="jak-pot-nmbr"
              type="text"
              value={jakPotNmbr}
              onChange={(e) => setjakPotNmbr(e.target.value)}
            />
          </div>
          <div className="col-md-4 declr-nmbr nmbr-logo">
            Number:-{' '}
            <div className="top-lcky-nav-logo game-52cards-winCard">
              <img src={betNameDtl} />
            </div>
          </div>
          <div className="col-md-4 tex-cntr">
            {105 - counter < 88 ? (
              <Button className="sbmt-btn" onClick={drownumbr} variant="warning">
                <b>Submit</b>
              </Button>
            ) : (
              <Button className="sbmt-btn" onClick={drownumbr} variant="warning" disabled>
                <b>Please Wait</b>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Counter</b> : <span>{105 - counter ? 105 - counter : '0'}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Playing</b> : <span>{props.lastTenResult?.total?.[0]?.total_betting}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Winning</b> : <span>{props.lastTenResult?.total?.[0]?.total_winning}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Profit/Loss</b> :{' '}
              <b>
                <span>{proLoss.toFixed(2)}</span>
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="row manual-numbr">
        <div className="col-md-3">
          <p className="mrgn-0 font-lucky-card">
            Current Game Total Playing:-{' '}
            {props.lccurrentGameId?.totalbetamount ? props.lccurrentGameId?.totalbetamount : '0'}
          </p>
        </div>
        <div className="col-md-3">
          <p className="mrgn-0 font-lucky-card">
            Get Manual Number Before <b>87</b>.
          </p>
        </div>
        <div className="col-md-6">
          <p className="mrgn-0 latest-nmbr-detal nmbr-wth-jkpot">
            {!!props &&
              props.lastTenResult &&
              props?.lastTenResult?.numbers?.length > 0 &&
              props.lastTenResult.numbers.map((data, index) => {
                return (
                  <>
                    <img
                      className="fifty2-last-10-result-img"
                      src={fiftyTwoCards[data.number - 1].img}
                    />
                    <p className="jackpot-number-luckycard">
                      {data.jackpot === 0 ? '' : data.jackpot + 'x'}
                    </p>
                  </>
                );
              })}
          </p>
        </div>
      </div>
      {/* input type */}

      <div className="row">
        <div className="col-md-9">
          <div className="row jackPot-amount-dtl">
            <div className="col short-dtl-tbl">
              {jakPotNmbr
                ? !!props &&
                  !!props.jackPotAmount &&
                  props?.jackPotAmount[`${cardSelectIndex}`] * jakPotNmbr
                : !!props && !!props.jackPotAmount && props?.jackPotAmount[`${cardSelectIndex}`]
                ? jakPotNmbr
                  ? !!props &&
                    !!props.jackPotAmount &&
                    props?.jackPotAmount[`${cardSelectIndex}`] * jakPotNmbr
                  : !!props && !!props.jackPotAmount && props?.jackPotAmount[`${cardSelectIndex}`]
                : '0'}
            </div>
          </div>
          <div className="row fiftyTwoCardsAlign">
            
            {fiftyTwoCards.map((card, index) => (
              <div
                key={`index-${index}`}
                onClick={() => {
                  setActive(index);
                  setBetName(card.img);
                  setCardSelectIndex(index + 1);
                }}
                className="fifty-52-card-img"
              >
                <p className={`betamount-on-card-fifty2 ${!!props.fiftyTwostripNumn && props.fiftyTwostripNumn[index + 1] > 0 ? 'fifty2betAmounttext' : ''}`}>
                  {!!props.fiftyTwostripNumn && props.fiftyTwostripNumn[index + 1]}
                </p>
                {/* <p className="betamount-on-card-fifty2">
                  {!!props.fiftyTwostripNumn && props.fiftyTwostripNumn[index + 1]}
                </p> */}
                <img
                  className={`${card.class} ${index === active && 'selected-card-fifty2'}`}
                  src={card.img}
                />
              </div>
            ))}
          </div>
          <div className="row mrg-top-dtd">
        <div className="col-md-12">
          <div className="row current-bet-scroll-tbl-fifty">
            <table className="game-board-winTbl-fifty2">
              <tr>
                <th>UserName</th>
                <th>Playing Amount</th>
                <th>Sale</th>
                <th>Loss/Profit</th>
                {fiftyTwoCards.map((img, index) => {
                  return (
                    <th>
                      <img src={img.img} alt="Games"/>
                    </th>
                  );
                })}
              </tr>
              
                {props.lccurrentGameId?.users !== undefined
                  ? props.lccurrentGameId?.users.map((data, index) => {
                      return (
                        <>
                        <tr>
                          <td>{data.username ? data.username : 'UserName'}</td>
                          <td>{data.betamount ? data.betamount : 'Amount'}</td>
                          <td>{data.daily_play_points}</td>
                          <td>{data.daily_play_points - data.daily_winning_points}</td>
                          {Object.entries(!!props.fiftyTwoUserAmount && props.fiftyTwoUserAmount).map(
                            ([key, value]) => {
                              return (
                                <>
                                  <td>{value}</td>
                                </>
                              );
                            },
                          )}
                          </tr>
                        </>
                      );
                    })
                  : 'No Record Found.'}
            </table>
          </div>
        </div>
      </div>
        </div>
        <div className="col-md-3">
          <table className="game-board-fifty-two lucky-card-game-board">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              <th>Winning Amount</th>
            </tr>
            {props.lccurrentGameId?.win_bet_amount !== undefined &&
            props.lccurrentGameId?.win_bet_amount.length
              ? props.lccurrentGameId?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <img src={fiftyTwoCards[data.number - 1].img} alt="Games"/>
                        </td>
                        <td>{data.betamount.toFixed(2)}</td>
                        <td>{data.wincoins_if_draw_this_number.toFixed(1)}</td>
                      </tr>
                    </>
                  );
                })
              : 'No Record Found.'}
          </table>
        </div>
      </div>

    </div>
  );
}

const mapStateToProps = (state) => (
  {
  lccurrentGameDetails: state.bettingData?.lccurrentGame,
  lctodayResultData: state.bettingData?.lctodayResult,
  lccurrentGameId: state.bettingData?.fiftyTwoCardCurrent,
  fiftyTwoUserAmount: state.bettingData?.fiftyTwoCardCurrent?.numbers,
  stripNumn: state.bettingData?.lccurrentGameId?.numbers,
  jackPotAmount: state.bettingData?.fiftyTwoCardCurrent?.winamount_number,
  lcManulNo: state.bettingData.error,
  lastTenResult: state.bettingData?.fiftytoCdayResult,
  auth: state.auth,
  fiftyTwostripNumn: state.bettingData?.fiftyTwoCardCurrent?.numbers,
});

export default connect(mapStateToProps, {
  jakPotNuberLuckyCard,
  fiftyTwomanualNumber,
  fiftyTwoCurrentGameBet,
  fiftyToCardDayResult,
  fiftyTwoCurrentGameBetId,
})(FiftyTwoCardsGame);

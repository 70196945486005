import { GET_TOKEN_SUCCESS, GET_TOKEN_FAIL, CLEAR_TOKEN_ERROR } from '../types';

const initialState = {
  gameToken: [],
  isLoading: false,
  error: null,
  gameStatus: [],
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: true,
        gameToken: payload.gameToken,
        gameStatus: payload.gameUpdateToken,
      };
    case GET_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
        gameToken: [],
        error: payload,
      };
    case CLEAR_TOKEN_ERROR:
      return {
        ...state,
        gameToken: state.gameToken.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

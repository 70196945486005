import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { editSetting, getSettings } from '../../store/actions/settingsAction';
import './styles.css';

function EditSettings(props) {
  const [value, setvalue] = useState(props.value);
  const [gamesList, setGamesList] = useState('all');
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    // props.getSettings();
  };
  const handleShow = () => setShow(true);

  // POST DATA ON SUBMIT BUTTON
  const handleSubmit = async (e) => {
    await props.editSetting(props.settingid, value);
    props.getSettings(gamesList);
    setShow(false);
  };

  return (
    <>
      <span
        className="setting-btn"
        type="button"
        variant="primary"
        onClick={() => handleShow(props.settingid)}
      >
        {props.value}
      </span>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 profile-img cntr">
              <h3>EDIT SETTINGS</h3>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 profile-sub-details">
                <div className="row pt-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    value={value}
                    onChange={(e) => setvalue(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSubmit()} variant="success">
            Submit
          </Button>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const mapStateToProps = (state) => ({
  // message: state.users
});
export default connect(mapStateToProps, { getSettings, editSetting })(EditSettings);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { getTokens } from '../../store/actions/tokenAction';
import './styles.css';
import ToggleButtonGame from '../Navbar/ToggleButtonGame';

const TokensList = ({ getTokens, gameTokens }) => {
  useEffect(() => {
     getTokens();
  }, [getTokens]);

  // Table data
  const header = [
    { title: 'Sr No.', prop: 'srno' },
    { title: 'ID', prop: 'id' },
    { title: 'Name', prop: 'name'},
    { title: 'Coins', prop: 'coins' },
    { title: 'Status', prop: 'status' },
  ];

  // Table Body Data
  let bodyData = [];
  if (gameTokens !== undefined) {
    bodyData = [
      gameTokens.map((users, index) => {
        return {
          srno: index + 1,
          id: users?.id,
          name: users?.name,
          coins: users?.coins,
          status: users?.status ? (
            <ToggleButtonGame toknId={users?.id} status={users?.status} />
          ) : (
            <ToggleButtonGame toknId={users?.id} status={users?.status} />
          ),
          // <ToggleButtonGame toknId={users?.id} status="false"/>
        };
      }),
    ];
  }
 
  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className="">
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  gameTokens: state.token.gameToken,
});
export default connect(mapStateToProps, { getTokens })(TokensList);

import React, { useEffect } from 'react';
import LuckyCards from '../Navbar/LuckyCards';
import './styles.css';
import { connect } from 'react-redux';
import { placedUserBet } from '../../store/actions/bettingDataAction';
import { useParams } from 'react-router-dom';
import moment from 'moment';

function LcUserGameBoard({ placedUserBet, userBetNumber, userbetlist, winDetailData, betHistory }) {
  const { gameid, id, username } = useParams();
  useEffect(() => {
    placedUserBet(gameid, id);
  }, [placedUserBet, gameid, id]);

  const cards = [
    {
      number: 1,
      class: 'card-img-luky-three',
      betAmount: userBetNumber?.[1] ? <span>{userBetNumber['1']}</span> : <span>{'0'}</span>,
      betName: "JH" 
    },
    {
      number: 2,
      class: 'card-img-luky-two',
      betAmount: userBetNumber?.[2] ? <span>{userBetNumber['2']}</span> : <span>{'0'}</span>,
      betName: "JS" 
    },
    {
      number: 3,
      class: 'card-img-luky',
      betAmount: userBetNumber?.[3] ? <span>{userBetNumber['3']}</span> : <span>{'0'}</span>,
      betName: "JD"
    },
    {
      number: 4,
      class: 'card-img-luky-for',
      betAmount: userBetNumber?.[4] ? <span>{userBetNumber['4']}</span> : <span>{'0'}</span>,
      betName: "JC"
    },
    // end user
    {
      number: 5,
      class: 'card-img-luky-seven',
      betAmount: userBetNumber?.[5] ? <span>{userBetNumber['5']}</span> : <span>{'0'}</span>,
      betName: "QH" 
    },
    {
      number: 6,
      class: 'card-img-luky-six',
      betAmount: userBetNumber?.[6] ? <span>{userBetNumber['6']}</span> : <span>{'0'}</span>,
      betName: "QS"
    },
    {
      number: 7,
      class: 'card-img-luky-five',
      betAmount: userBetNumber?.[7] ? <span>{userBetNumber['7']}</span> : <span>{'0'}</span>,
      betName: "QD"
    },
    {
      number: 8,
      class: 'card-img-luky-eight',
      betAmount: userBetNumber?.[8] ? <span>{userBetNumber['8']}</span> : <span>{'0'}</span>,
      betName: "QC"
    },
    // end user
    {
      number: 9,
      class: 'card-img-luky-eleven',
      betAmount: userBetNumber?.[9] ? <span>{userBetNumber['9']}</span> : <span>{'0'}</span>,
      betName: "KH" 
    },
    {
      number: 10, 
      class: 'card-img-luky-ten',
      betAmount: userBetNumber?.[10] ? <span>{userBetNumber['10']}</span> : <span>{'0'}</span>,
      betName: "KS"
    },
    {
      number: 11,
      class: 'card-img-luky-nine',
      betAmount: userBetNumber?.[11] ? <span>{userBetNumber['11']}</span> : <span>{'0'}</span>,
      betName: "KD"
    },
    {
      number: 12,
      class: 'card-img-luky-twel',
      betAmount: userBetNumber?.[12] ? <span>{userBetNumber['12']}</span> : <span>{'0'}</span>,
      betName: "KC"
    },
  ];
  let winLuckyCrdTop = winDetailData?.winning;
  let startTime = betHistory?.start;
  let endTime = betHistory?.end;
  winLuckyCrdTop = parseFloat(winLuckyCrdTop);
  return (
    <>
      {/* input type */}
      <div className="row lucky-card-mrgn">
        <div className="col-md-7">
          <div className="row">
          {/* !!props && !!props.jackPotAmount && props?.jackPotAmount[`${cardSelectIndex}`] */}
            {cards.map((card, index) => (
              <div className="col-md-3" key={`index-${index}`}>
                <p className="betamount-on-card">{card?.betAmount}</p>
                <LuckyCards amount={`${card.betAmount}`} 
                imgClass={`${card.class} ${!!winDetailData && !!winDetailData?.winningNumber && card.number == winDetailData?.winningNumber && 'selected-card'} 
                l-cards-main`} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-5">
        {/* <div class="text-center"><b>Game ID</b>:- {id}</div> */}
        <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>UserName</b>:- {username}
            </div>
            <div className="col-md-6 winrs">
              <b>Game ID</b>:- 
              {id}
            </div>
          </div>
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>Start</b>:- {moment(startTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
            <div className="col-md-6 winrs">
              <b>End</b>:- 
              {moment(endTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
          </div>
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>BetTotal</b>:- {winDetailData?.betTotal}
            </div>
            <div className="col-md-6 winrs">
              <b>Winning Number</b>:-
              {cards.map((data, index) => {
                  return(
                    <>
                   <span className={!!winDetailData && !!winDetailData?.winningNumber && data.number == winDetailData?.winningNumber && data.class + " winning-number"}></span>
                    </>
                  )
              })
              }
            </div>
          </div>
          {/* end */}
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>Jackpot</b>:- {winDetailData?.jackpot}
            </div>
            <div className="col-md-6 winrs">
              <b>Winning Amount</b>:- {winLuckyCrdTop.toFixed(2)}
              {/* {cards.map((data, index) => {
                  return(
                    <>
                   <b>{!!winDetailData && !!winDetailData?.winningNumber && data.number == winDetailData?.winningNumber && data.betName}</b> 
                    </>
                  )
              })
              } */}
            </div>
          </div>
          {/* end */}
          <div className="row txt-cntr mrg-top-dtd hdr-bg">
            <div className="col-md-6">
              <b>Betting Type</b>
            </div>
            <div className="col-md-6">
              <b>Amount</b>
            </div>
          </div>
          {userbetlist !== undefined
            ? Object.keys(userbetlist).map((key) => {
                return (
                  <>
                    <div className="row txt-cntr betting-type-dta">
                      <div className="col-md-6">{key}</div>
                      <div className="col-md-6">{userbetlist[key]}</div>
                    </div>
                  </>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userBetNumber: state.bettingData.userPlaced,
  userbetlist: state.bettingData?.bettingType,
  winDetailData: state.bettingData?.winDetailUser,
  betHistory: state.bettingData?.gameDetailsOnHistory,
  auth: state.auth,
});

export default connect(mapStateToProps, { placedUserBet })(LcUserGameBoard);

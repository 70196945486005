import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Datatable from 'react-bs-datatable';
import { useParams } from 'react-router-dom';
import { getHistory, getUserHistory } from '../../store/actions/historyAction';

import './styles.css';

const HistoryUserList = ({ getUserHistory, userListing }) => {
  const { id } = useParams();
  useEffect(() => {
    getUserHistory(id);
  }, [getUserHistory, id]);

  // Table data
  const header = [
    { title: 'UserName', prop: 'UserName', sortable: true, filterable: true },
    { title: 'Bet Amount', prop: 'betamount' },
    { title: 'Winning Amount', prop: 'winstatus' },
    { title: 'Placed Bet', prop: 'status' },
  ];

  // Table Body Data
  let bodyData = [];
  if (userListing?.users !== undefined) {
    bodyData = [
      userListing?.users.map((data, index) => {
        let viewUsers = (
          <Link to={`/user-game-board/${data.userid}/${id}/${data.username}`} className="setting-btn">
            <i className="fa fa-gamepad" aria-hidden="true"></i> Placed Bet
          </Link>
        );

        return {
          UserName: data?.username,
          betamount: data?.betamount,
          winstatus: data?.winning,
          status: viewUsers,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  let winAmountTop = userListing?.totalWinning;
  winAmountTop = parseFloat(winAmountTop);

  let betAmountTop = userListing?.totalBetting;
  betAmountTop = parseFloat(betAmountTop);
  return (
    <div className="">
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Start Time</b>:
              <span>
                {userListing !== undefined
                  ? moment(userListing.start).format('DD/MM/YYYY hh:mm:ss')
                  : ''}
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>End Time</b>:
              <span>
                {userListing !== undefined
                  ? moment(userListing.end).format('DD/MM/YYYY hh:mm:ss')
                  : ''}
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Total Betting</b>:
              <span>{userListing !== undefined ? betAmountTop.toFixed(2) : ''}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Total Winning</b>:
              <span>{userListing !== undefined ? winAmountTop.toFixed(2) : ''}</span>
            </p>
          </div>
        </div>
      </div>
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
        rowsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userListing: state.history?.gameHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { getHistory, getUserHistory })(HistoryUserList);

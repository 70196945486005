import React, { useState, useEffect } from 'react';
import LuckyCards from '../Navbar/LuckyCards';
import jFirst from '../../images/1.jpg';
import jsec from '../../images/2.jpg';
import jthird from '../../images/3.jpg';
import jforth from '../../images/4.jpg';
import qFirst from '../../images/5.jpg';
import qsec from '../../images/6.jpg';
import qthird from '../../images/7.jpg';
import qforth from '../../images/8.jpg';
import kFirst from '../../images/9.jpg';
import ksec from '../../images/10.jpg';
import kthird from '../../images/11.jpg';
import kforth from '../../images/12.jpg';
import './styles.css';
import { connect } from 'react-redux';
import {
  jakPotNuberLuckyCard,
  lcmanualNumber,
  lcCurrentGameBet,
  lcToDayResult,
  lcCurrentGameBetId,
} from '../../store/actions/bettingDataAction';
import { Button } from 'react-bootstrap';

function LuckyCardCurrent(props) {
  const [active, setActive] = useState(null);
  const [jakPotNmbr, setjakPotNmbr] = useState('');
  const [betNameDtl, setBetName] = useState(null);
  const [cardSelectIndex, setCardSelectIndex] = useState(0);

  let gameCountr = props.lccurrentGameDetails?.counter;
  const [counter, setCounter] = useState(false);

  // useEffect(() => {
  //   props.lcCurrentGameBet();
  //   const intervalData = setInterval(() => {
  //     props.lcCurrentGameBet();
  //   }, 2000);
  //   return () => clearInterval(intervalData);
  // }, []);

  useEffect(() => {
    props.lcToDayResult();
  }, [105 - counter === 1]);

  useEffect(() => {
    setCounter(gameCountr);
  }, [props.lccurrentGameDetails?.counter]);

  useEffect(() => {
    const intervalList = setTimeout(() => {
      if (counter) {
        setCounter(counter ? counter - 1 : gameCountr - 1);
      } else if (counter === 0) {
        return <>{setCounter(105)}</>;
      }
    }, 1000);
    return () => clearInterval(intervalList);
  }, [counter, gameCountr]);

  useEffect(() => {
    props.lcCurrentGameBetId();
    props.lcCurrentGameBet();
    const interval = setInterval(() => {
      props.lcCurrentGameBetId();
      props.lcCurrentGameBet();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    props.lcCurrentGameBetId();
  }, [105 - counter === 81]);

  // useEffect(() => {
  //   props.lcmanualNumber(cards[active]?.number, +jakPotNmbr);
  //   props.lcCurrentGameBet();
  //   props.lcToDayResult();
  // }, [105 - counter === 84]);

  // useEffect(() => {
  //   props.lcCurrentGameBetId();
  //  const interval = setInterval(() => {
  //     props.lcCurrentGameBetId();
  //   }, counter === 81);
  //   return () => clearInterval(interval);
  // }, []);

  // const jackpotDeclare = () => {
  //     props.jakPotNuberLuckyCard(jakPotNmbr);
  //     alert("JackPot Amount Added Successfully.")
  // }

  const drownumbr = () => {
    props.lcmanualNumber(cards[active]?.number, +jakPotNmbr);
    // props.jakPotNuberLuckyCard(jakPotNmbr);
    props.lcCurrentGameBet();
    props.lcToDayResult();
  };

  //       const interval = setInterval(() => {
  //         props.lcCurrentGameBet();
  //         }, 10000);
  //             return () => clearInterval(interval);
  // }
  if (105 - counter === 0) {
    // alert('Refersh for new game');
    window.location.reload();
  }

  const cards = [
    {
      number: 1,
      class: 'card-img-luky-three',
      betAmount: props.stripNumn?.[1] ? (
        <span className="bet-numb-point">{props.stripNumn?.[1]}</span>
      ) : (
        '0'
      ),
      betName: 'JH',
    },

    {
      number: 2,
      class: 'card-img-luky-two',
      betAmount: props.stripNumn?.[2] ? (
        <span className="bet-numb-point">{props.stripNumn?.[2]}</span>
      ) : (
        '0'
      ),
      betName: 'JS',
    },
    {
      number: 3,
      class: 'card-img-luky',
      betAmount: props.stripNumn?.[3] ? (
        <span className="bet-numb-point">{props.stripNumn?.[3]}</span>
      ) : (
        '0'
      ),
      betName: 'JD',
    },

    {
      number: 4,
      class: 'card-img-luky-for',
      betAmount: props.stripNumn?.[4] ? (
        <span className="bet-numb-point">{props.stripNumn?.[4]}</span>
      ) : (
        '0'
      ),
      betName: 'JC',
    },
    {
      number: 5,
      class: 'card-img-luky-seven',
      betAmount: props.stripNumn?.[5] ? (
        <span className="bet-numb-point">{props.stripNumn?.[5]}</span>
      ) : (
        '0'
      ),
      betName: 'QH',
    },

    {
      number: 6,
      class: 'card-img-luky-six',
      betAmount: props.stripNumn?.[6] ? (
        <span className="bet-numb-point">{props.stripNumn?.[6]}</span>
      ) : (
        '0'
      ),
      betName: 'QS',
    },
    {
      number: 7,
      class: 'card-img-luky-five',
      betAmount: props.stripNumn?.[7] ? (
        <span className="bet-numb-point">{props.stripNumn?.[7]}</span>
      ) : (
        '0'
      ),
      betName: 'QD',
    },

    {
      number: 8,
      class: 'card-img-luky-eight',
      betAmount: props.stripNumn?.[8] ? (
        <span className="bet-numb-point">{props.stripNumn?.[8]}</span>
      ) : (
        '0'
      ),
      betName: 'QC',
    },

    {
      number: 9,
      class: 'card-img-luky-eleven',
      betAmount: props.stripNumn?.[9] ? (
        <span className="bet-numb-point">{props.stripNumn?.[9]}</span>
      ) : (
        '0'
      ),
      betName: 'KH',
    },

    {
      number: 10,
      class: 'card-img-luky-ten',
      betAmount: props.stripNumn?.[10] ? (
        <span className="bet-numb-point">{props.stripNumn?.[10]}</span>
      ) : (
        '0'
      ),
      betName: 'KS',
    },
    {
      number: 11,
      class: 'card-img-luky-nine',
      betAmount: props.stripNumn?.[11] ? (
        <span className="bet-numb-point">{props.stripNumn?.[11]}</span>
      ) : (
        '0'
      ),
      betName: 'KD',
    },
    {
      number: 12,
      class: 'card-img-luky-twel',
      betAmount: props.stripNumn?.[12] ? (
        <span className="bet-numb-point">{props.stripNumn?.[12]}</span>
      ) : (
        '0'
      ),
      betName: 'KC',
    },
  ];
  let proLoss =
    props.lctodayResultData?.total?.[0]?.total_betting -
    props.lctodayResultData?.total?.[0]?.total_winning;

  return (
    <div style={{ backgroundColor: 105 - counter > 74 && 105 - counter < 90 ? '#40d9c4' : '' }}>
      <div className="row ">
        <div className="col-md-6 disply-flx spcing-rmov">
          {jakPotNmbr === '0' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('0')}>
              1x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('0')}>
              1x
            </div>
          )}
          {jakPotNmbr === '2' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('2')}>
              2x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('2')}>
              2x
            </div>
          )}
          {jakPotNmbr === '3' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('3')}>
              3x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('3')}>
              3x
            </div>
          )}
          {jakPotNmbr === '4' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('4')}>
              4x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('4')}>
              4x
            </div>
          )}
          {jakPotNmbr === '5' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('5')}>
              5x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('5')}>
              5x
            </div>
          )}
          {jakPotNmbr === '6' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('6')}>
              6x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('6')}>
              6x
            </div>
          )}
          {jakPotNmbr === '7' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('7')}>
              7x
            </div>
          ) : (
            <div className="col jak-port-tab " onClick={() => setjakPotNmbr('7')}>
              7x
            </div>
          )}
          {jakPotNmbr === '8' ? (
            <div className="col jak-port-tab selectedJkPot" onClick={() => setjakPotNmbr('8')}>
              8x
            </div>
          ) : (
            <div className="col jak-port-tab" onClick={() => setjakPotNmbr('8')}>
              8x
            </div>
          )}
        </div>
        <div className="col-md-6 disply-flx spcing-rmov">
          <div className="col-md-4 declr-nmbr">
            {/* JackPot:- {jakPotNmbr ? jakPotNmbr : 0} <b>x</b> */}
            JackPot:-{' '}
            <input
              className="jak-pot-nmbr"
              type="text"
              value={jakPotNmbr}
              onChange={(e) => setjakPotNmbr(e.target.value)}
            />
          </div>
          <div className="col-md-4 declr-nmbr nmbr-logo">
            {/* {setnumber(active !== null && cards[active].number)} */}
            Number:-{' '}
            {/* {active !== null && cards[active].number
              ? active !== null && cards[active].number
              : '0'}{' '} */}
            {/* <div className={cards[data.number-1].class + " l-cards-tbl-main "}> */}
            <div className={betNameDtl + ' top-lcky-nav-logo '}></div>
          </div>
          <div className="col-md-4 tex-cntr">
            {105 - counter < 88 ? (
              <Button className="sbmt-btn" onClick={drownumbr} variant="warning">
                <b>Submit</b>
              </Button>
            ) : (
              <Button className="sbmt-btn" onClick={drownumbr} variant="warning" disabled>
                <b>Please Wait</b>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Counter</b> : <span>{105 - counter ? 105 - counter : '0'}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Playing</b> : <span>{props.lastTenResult?.total?.[0]?.total_betting}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Winning</b> : <span>{props.lastTenResult?.total?.[0]?.total_winning}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p className="mrgn-botm">
              <b>Today Profit/Loss</b> :{' '}
              <b>
                <span>{proLoss.toFixed(2)}</span>
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="row manual-numbr">
        <div className="col-md-3">
          <p className="mrgn-0 font-lucky-card">
            Current Game Total Playing:-{' '}
            {props.lccurrentGameId?.totalbetamount ? props.lccurrentGameId?.totalbetamount : '0'}
            {/* {' '}
            {props.lccurrentGameDetails?.currentgame?.total_betting
              ? props.lccurrentGameDetails?.currentgame?.total_betting
              : '0'} */}
          </p>
        </div>
        <div className="col-md-3">
          <p className="mrgn-0 font-lucky-card">
            Get Manual Number Before <b>87</b>.
          </p>
        </div>
        <div className="col-md-6">
          <p className="mrgn-0 latest-nmbr-detal nmbr-wth-jkpot">
            {!!props &&
              props.lastTenResult &&
              props?.lastTenResult?.numbers?.length > 0 &&
              props.lastTenResult.numbers.map((data, index) => {
                return (
                  <>
                    <p className={cards[data.number - 1].class + ' l-cards-tbl-main-jkpot '}></p>
                    <p className="jackpot-number-luckycard">
                      {data.jackpot === 0 ? '' : data.jackpot + 'x'}
                    </p>
                  </>
                );
              })}
          </p>
        </div>
      </div>
      {/* input type */}

      <div className="row">
        <div className="col-md-7">
          <div className="row jackPot-amount-dtl">
            <div className="col short-dtl-tbl">
              {jakPotNmbr
                ? !!props &&
                  !!props.jackPotAmount &&
                  props?.jackPotAmount[`${cardSelectIndex}`] * jakPotNmbr
                : !!props && !!props.jackPotAmount && props?.jackPotAmount[`${cardSelectIndex}`]
                ? jakPotNmbr
                  ? !!props &&
                    !!props.jackPotAmount &&
                    props?.jackPotAmount[`${cardSelectIndex}`] * jakPotNmbr
                  : !!props && !!props.jackPotAmount && props?.jackPotAmount[`${cardSelectIndex}`]
                : '0'}
            </div>
          </div>
          <div className="row">
            {cards.map((card, index) => (
              <div
                key={`index-${index}`}
                onClick={() => {
                  setActive(index);
                  setBetName(card.class);
                  setCardSelectIndex(index + 1);
                }}
                className="col-md-3"
              >
                <p className="betamount-on-card">{card.betAmount}</p>
                <LuckyCards
                  imgClass={`${card.class} ${index === active && 'selected-card'} l-cards-main`}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-5">
          <table className="game-board-winTbl lucky-card-game-board">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              <th>Winning Amount</th>
            </tr>
            {props.lccurrentGameId?.win_bet_amount !== undefined &&
            props.lccurrentGameId?.win_bet_amount.length
              ? props.lccurrentGameId?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <div className={cards[data.number - 1].class + ' l-cards-tbl-main '}>
                            {' '}
                          </div>
                        </td>
                        <td>{data.betamount.toFixed(2)}</td>
                        <td>{data.wincoins_if_draw_this_number.toFixed(1)}</td>
                      </tr>
                    </>
                  );
                })
              : 'No Record Found.'}
          </table>
        </div>
      </div>

      <div className="row mrg-top-dtd">
        <div className="col-md-12">
          <div className="row current-bet-scroll-tbl">
            <table className="game-board-winTbl">
              <tr>
                <th>UserName</th>
                <th>Playing Amount</th>
                <th>Sale</th>
                <th>Loss/Profit</th>
                <th>
                  <img src={jthird} alt="Games cards"/>
                </th>
                <th>
                  <img src={jsec} alt="Games cards"/>
                </th>
                <th>
                  <img src={jFirst} alt="Games cards"/>
                </th>
                <th>
                  <img src={jforth} alt="Games cards"/>
                </th>
                <th>
                  <img src={qthird} alt="Games cards"/>
                </th>
                <th>
                  <img src={qsec} alt="Games cards"/>
                </th>
                <th>
                  <img src={qFirst} alt="Games cards"/>
                </th>
                <th>
                  <img src={qforth} alt="Games cards"/>
                </th>
                <th>
                  <img src={kthird} alt="Games cards"/>
                </th>
                <th>
                  <img src={ksec} alt="Games cards"/>
                </th>
                <th>
                  <img src={kFirst} alt="Games cards"/>
                </th>
                <th>
                  <img src={kforth} alt="Games cards"/>
                </th>
              </tr>
              {props.lccurrentGameId?.users !== undefined
                ? props.lccurrentGameId?.users.map((data, index) => {
                    return (
                      <>
                        <tr>
                          <td>{data.username}</td>
                          <td>{data.betamount}</td>
                          <td>{data.daily_play_points}</td>
                          <td>{data.daily_play_points - data.daily_winning_points}</td>
                          <td>{data?.numbers['1']}</td>
                          <td>{data?.numbers['2']}</td>
                          <td>{data?.numbers['3']}</td>
                          <td>{data?.numbers['4']}</td>
                          <td>{data?.numbers['5']}</td>
                          <td>{data?.numbers['6']}</td>
                          <td>{data?.numbers['7']}</td>
                          <td>{data?.numbers['8']}</td>
                          <td>{data?.numbers['9']}</td>
                          <td>{data?.numbers['10']}</td>
                          <td>{data?.numbers['11']}</td>
                          <td>{data?.numbers['12']}</td>
                        </tr>
                      </>
                    );
                  })
                : 'No Record Found.'}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  lccurrentGameDetails: state.bettingData?.lccurrentGame,
  lctodayResultData: state.bettingData?.lctodayResult,
  lccurrentGameId: state.bettingData?.lccurrentGameId,
  stripNumn: state.bettingData?.lccurrentGameId?.numbers,
  jackPotAmount: state.bettingData?.lccurrentGameId?.winamount_number,
  lcManulNo: state.bettingData.error,
  lastTenResult: state.bettingData?.lctodayResult,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  jakPotNuberLuckyCard,
  lcmanualNumber,
  lcCurrentGameBet,
  lcToDayResult,
  lcCurrentGameBetId,
})(LuckyCardCurrent);

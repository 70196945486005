import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { placedGameBet } from '../../store/actions/bettingDataAction';
import { getUserHistory } from '../../store/actions/historyAction';
import './styles.css';
import { useParams } from 'react-router-dom';

const FullGameBoardBet = ({
  placedGameBet,
  gamePlaced,
  gamePlacedList,
  gameBetAmount,
  userListing,
  getUserHistory,
}) => {
  const { id } = useParams();

  useEffect(() => {
    placedGameBet(id);
  }, [placedGameBet, id]);

  useEffect(() => {
    getUserHistory(id);
  }, [getUserHistory, id]);

  return (
    <>
      <div className="">
        <div className="row short-dtl-tbl">
          <div className="col-md-3">
            <div className="game-tbl-timer">
              <p>
                <b>Start Time</b>:
                <span>
                  {gameBetAmount !== undefined
                    ? moment(gameBetAmount.start).format('DD/MM/YYYY hh:mm:ss')
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="game-tbl-timer">
              <p>
                <b>End Time</b>:
                <span>
                  {gameBetAmount !== undefined
                    ? moment(gameBetAmount.end).format('DD/MM/YYYY hh:mm:ss')
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="game-tbl-timer">
              <p>
                <b>Total Betting</b>:
                <span>{gameBetAmount !== undefined ? gameBetAmount?.totalbetamount : ''}</span>
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="game-tbl-timer">
              <p>
                <b>Total Winning</b>:
                <span>{gameBetAmount !== undefined ? gameBetAmount?.totalwinning : ''}</span>
              </p>
            </div>
          </div>
        </div>
        {/* Top Menu */}
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-1 pding-rmov">
              <div className="row bord-zero left-redus-bordr-top">
                <div className="col-md-12 bord-text-zero">
                  <span className="board-numb-left-top">00</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['00'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['00']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="row bord-zero left-redus-bordr-botm">
                <div className="col-md-12 bord-text-zero">
                  <span className="board-numb-left">0</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['0'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['0']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-10 pding-rmov">
              <div className="row">
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">3</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['3'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['3']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">6</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['6'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['6']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">9</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['9'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['9']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">12</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['12'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['12']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">15</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['15'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['15']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">18</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['18'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['18']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">21</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['21'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['21']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">24</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['24'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['24']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">27</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['27'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['27']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">30</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['30'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['30']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">33</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['33'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['33']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">36</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['36'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['36']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">2</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['2'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['2']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">5</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['5'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['5']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">8</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['8'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['8']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">11</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['11'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['11']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">14</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['14'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['14']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">17</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['17'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['17']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">20</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['20'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['20']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">23</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['23'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['23']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">26</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['26'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['26']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">29</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['29'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['29']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">32</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['32'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['32']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">35</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['35'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['35']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">1</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['1'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['1']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">4</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['4'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['4']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">7</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['7'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['7']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">10</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['10'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['10']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">13</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['13'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['13']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">16</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['16'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['16']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">19</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['19'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['19']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">22</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['22'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['22']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">25</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['25'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['25']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">28</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['28'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['28']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red num-black">31</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['31'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['31']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-1 bord-text-cntr">
                  <span className="board-numb-red">34</span>
                  {gamePlaced !== undefined ? (
                    gamePlaced['34'] === 0 ? null : (
                      <span className="bet-numb-point">{gamePlaced['34']}</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-1 pding-rmov mrgn-top-rit">
              <div className="row right-bord-box">
                <div className="col-md-12 bord-text-cntr-right">
                  <span className="board-numb-right">C3</span>
                </div>
              </div>
              <div className="row right-bord-box">
                <div className="col-md-12 bord-text-cntr-right">
                  <span className="board-numb-right">C2</span>
                </div>
              </div>
              <div className="row right-bord-box">
                <div className="col-md-12 bord-text-cntr-right">
                  <span className="board-numb-right">C1</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 pding-rmov">
              <div className="row">
                <div className="col-md-4 bordr-col-btns">1st 12</div>
                <div className="col-md-4 bordr-col-btns">2nd 12</div>
                <div className="col-md-4 bordr-col-btns">3rd 12</div>
              </div>
              <div className="row">
                <div className="col-md-2 bordr-col-btns">01 - 18</div>
                <div className="col-md-2 bordr-col-btns">EVEN</div>
                <div className="col-md-2 bordr-col-btns">
                  <span className="retcngl-btn-botm"></span>
                </div>
                <div className="col-md-2 bordr-col-btns">
                  <span className="retcngl-btn-botm-black"></span>
                </div>
                <div className="col-md-2 bordr-col-btns">ODD</div>
                <div className="col-md-2 bordr-col-btns">19 - 36</div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
        {/* <div className="row txt-cntr mrg-top-dtd hdr-bg">
                        <div className="col-md-6">
                           <b>Betting Type</b>
                        </div>
                        <div className="col-md-6">
                           <b>Amount</b>
                        </div>
        </div> */}
        <div className="row mrg-top-dtd">
          <div className="col-md-6">
            <div className="row">
              <table className="game-board-winTbl">
                <tr>
                  <th>UserName</th>
                  <th>Playing Amount</th>
                  <th>Winning</th>
                </tr>
                {userListing?.users !== undefined
                  ? userListing?.users.map((data, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{data?.username}</td>
                            <td>{data?.betamount}</td>
                            <td>{data?.winning}</td>
                          </tr>
                        </>
                      );
                    })
                  : 'No Record Found.'}
              </table>
            </div>
            {/* <div className="row mrgn-top-gameList">
                    <div className="col-md-6 txt-cntr">
                        <b>Betting Type</b>
                    </div>
                    <div className="col-md-6 txt-cntr">
                        <b>Amount</b>
                    </div>
                </div>
                    {gamePlacedList !== undefined ?
                    Object.keys(gamePlacedList).map(key => {
                        return(
                            <>
                            <div className="row txt-cntr betting-type-dta">
                                <div className="col-md-6">
                                {key}
                                </div>
                                <div className="col-md-6">
                                {gamePlacedList[key]}
                                </div>
                            </div>
                            </>
                        )
                    }) : ""
                } */}
          </div>
          <div className="col-md-6">
            {/* <div className="row">
                    <div className="col-md-2 txt-cntr">
                        <b>Number</b>
                    </div>
                    <div className="col-md-5 txt-cntr">
                        <b>Playing Amount</b>
                    </div>
                    <div className="col-md-5 txt-cntr">
                        <b>Winning Amount</b>
                    </div>
                </div> */}
            <table className="game-board-winTbl">
              <tr>
                <th>Number</th>
                <th>Playing Amount</th>
                <th>Winning Amount</th>
              </tr>
              {gameBetAmount?.win_bet_amount !== undefined
                ? gameBetAmount?.win_bet_amount.map((data, index) => {
                    return (
                      <>
                        <tr>
                          <td>{data?.number}</td>
                          <td>{data?.betamount}</td>
                          <td>{data?.wincoins_if_draw_this_number}</td>
                        </tr>
                      </>
                    );
                  })
                : ''}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  gamePlaced: state.bettingData.gamePlaced,
  gamePlacedList: state.bettingData.gamePlacedList,
  gameBetAmount: state.bettingData.fullGameBordData,
  userListing: state.history?.gameHistory,
  auth: state.auth,
});
export default connect(mapStateToProps, { placedGameBet, getUserHistory })(FullGameBoardBet);

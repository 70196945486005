import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_BETTING_LOADING,
  GET_BETTING_SUCCESS,
  GET_BETTING_FAIL,
  ADD_BETTING_LOADING,
  ADD_BETTING_SUCCESS,
  ADD_BETTING_FAIL,
  DELETE_BETTING_LOADING,
  DELETE_BETTING_SUCCESS,
  DELETE_BETTING_FAIL,
  EDIT_BETTING_LOADING,
  EDIT_BETTING_SUCCESS,
  EDIT_BETTING_FAIL,
  CLEAR_BETTING_ERROR,
} from '../types';

export const getBettingtypes = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTING_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/bettingtypes', options);
    dispatch({
      type: GET_BETTING_SUCCESS,
      payload: { bettingtypes: response.data.bettingTypes },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTING_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
export const addUsers =
  (id, name, username, email, phone, role, password) => async (dispatch, getState) => {
    dispatch({
      type: ADD_BETTING_LOADING,
      payload: { me: { ...getState().auth.me } },
    });
    try {
      const formData = { id, name, username, email, phone, role, password };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/bettingtypes', formData, options);

      dispatch({
        type: ADD_BETTING_SUCCESS,
        payload: { message: response.data.message },
      });
    } catch (err) {
      dispatch({
        type: ADD_BETTING_FAIL,
        payload: { error: err?.response?.data.message || err.message },
      });
    }
  };

export const deleteUsers = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_BETTING_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`/api/bettingtypes/${id}`, options);
    dispatch({
      type: DELETE_BETTING_SUCCESS,
      payload: { message: response.data.message },
    });
  } catch (err) {
    dispatch({
      type: DELETE_BETTING_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editBettingtype =
  (id, winning_x, status, description) => async (dispatch, getState) => {
    dispatch({
      type: EDIT_BETTING_LOADING,
      payload: { id },
    });
    try {
      const formData = { winning_x, status, description };
      const options = attachTokenToHeaders(getState);
      const response = await axios.put(`/api/bettingtypes/${id}`, formData, options);

      dispatch({
        type: EDIT_BETTING_SUCCESS,
        payload: { bettingtypes: response.data.bettingTypes },
      });
    } catch (err) {
      dispatch({
        type: EDIT_BETTING_FAIL,
        payload: { error: err?.response?.data.bettingtypes || err.bettingtypes, id },
      });
    }
  };

export const clearUsersError = (id) => ({
  type: CLEAR_BETTING_ERROR,
  payload: { id },
});

import React, { useEffect } from 'react';
import moment from 'moment';
import './styles.css';
import { connect } from 'react-redux';
import {
  placedGameBet,
  jakPotNuberLuckyCard,
  lcmanualNumber,
  lcCurrentGameBet,
  lcToDayResult,
  lcCurrentGameBetId,
} from '../../store/actions/bettingDataAction';
import { getUserHistory } from '../../store/actions/historyAction';
import { useParams } from 'react-router-dom';

function FullTripalChanceBoard({ gameBetAmount, placedGameBet, getUserHistory, luckyCrdTap }) {
  const { id } = useParams();
  useEffect(() => {
    placedGameBet(id);
  }, [placedGameBet, id]);

  useEffect(() => {
    getUserHistory(id);
  }, [getUserHistory, id]);

  return (
    <>
      <div className="row short-dtl-tbl">
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Start Time</b>:
              <span>
                {gameBetAmount !== undefined
                  ? moment(gameBetAmount.start).format('DD/MM/YYYY hh:mm:ss')
                  : ''}
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>End Time</b>:
              <span>
                {gameBetAmount !== undefined
                  ? moment(gameBetAmount.end).format('DD/MM/YYYY hh:mm:ss')
                  : ''}
              </span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Total Betting</b>:
              <span>{gameBetAmount !== undefined ? gameBetAmount?.totalbetamount : ''}</span>
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="game-tbl-timer">
            <p>
              <b>Total Winning</b>:
              <span>{gameBetAmount !== undefined ? gameBetAmount?.totalwinning : ''}</span>
            </p>
          </div>
        </div>
      </div>
      {/* input type */}
      <div className="row lucky-card-mrgn">
          {/* single number */}
        <div className="col-md-4">
            <h5>Single Number</h5>
          <table className="game-board-winTbl">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              {/* <th>Winning Amount</th> */}
            </tr>
            {gameBetAmount?.win_bet_amount !== undefined
              ? gameBetAmount?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                    {
                    data?.number.length === 1 &&
                      <tr>
                        <td>{data?.number}</td>
                        <td>{data?.betamount}</td>
                        {/* <td>{data?.wincoins_if_draw_this_number}</td> */}
                      </tr>
                    }
                    </>
                  );
                })
              : ''}
          </table>
        </div>
        {/* double number */}
        <div className="col-md-4">
        <h5>Double Number</h5>
          <table className="game-board-winTbl">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              {/* <th>Winning Amount</th> */}
            </tr>
            {gameBetAmount?.win_bet_amount !== undefined
              ? gameBetAmount?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                    {
                    data?.number.length === 2 &&
                      <tr>
                        <td>{data?.number}</td>
                        <td>{data?.betamount}</td>
                        {/* <td>{data?.wincoins_if_draw_this_number}</td> */}
                      </tr>
                    }
                    </>
                  );
                })
              : ''}
          </table>
        </div>
        {/* tripal number */}
        <div className="col-md-4">
        <h5>Triple Number</h5>
          <table className="game-board-winTbl">
            <tr>
              <th>Number</th>
              <th>Playing Amount</th>
              {/* <th>Winning Amount</th> */}
            </tr>
            {gameBetAmount?.win_bet_amount !== undefined
              ? gameBetAmount?.win_bet_amount.map((data, index) => {
                  return (
                    <>
                    {
                    data?.number.length === 3 &&
                      <tr>
                        <td>{data?.number}</td>
                        <td>{data?.betamount}</td>
                        {/* <td>{data?.wincoins_if_draw_this_number}</td> */}
                      </tr>
                    }
                    </>
                  );
                })
              : ''}
          </table>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  gamePlaced: state.bettingData.gamePlaced,
  gamePlacedList: state.bettingData.gamePlacedList,
  gameBetAmount: state.bettingData.fullGameBordData,
  luckyCrdTap: state.bettingData.fullGameBordData?.numbers,
  userListing: state.history?.gameHistory,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  placedGameBet,
  getUserHistory,
  jakPotNuberLuckyCard,
  lcmanualNumber,
  lcCurrentGameBet,
  lcToDayResult,
  lcCurrentGameBetId,
})(FullTripalChanceBoard);

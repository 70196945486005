import React, { useEffect } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { placedUserBet } from '../../store/actions/bettingDataAction';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { fiftyTwoCards } from './card-data';

function FiftyTwoUserGameBoard({ placedUserBet, userBetNumber, userbetlist, winDetailData, winDetailHistory }) {
  const { gameid, id } = useParams();

  useEffect(() => {
    placedUserBet(gameid, id);
  }, [placedUserBet, gameid, id]);

  let winLuckyCrdTop = winDetailData?.winning;
  let startTime = winDetailHistory?.start;
  let endTime = winDetailHistory?.end;
  winLuckyCrdTop = parseFloat(winLuckyCrdTop);
  return (
    <>
      {/* input type */}
      <div className="row lucky-card-mrgn">
        <div className="col-md-7">
          <div className="row">
          {fiftyTwoCards.map((card, index) => (
              <div className="col-md-1 fifty-52-card-img" key={`index-${index}`}>
                <p className="betamount-on-card-fiftyTo">{!!userBetNumber && userBetNumber[index +1]}</p>
                <img className={`${!!winDetailData && !!winDetailData?.winningNumber && card.number == winDetailData?.winningNumber && 'selected-card'}`} src={card.img} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-5">
        <div class="text-center"><b>Game ID</b>:- {id}</div>
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>Start</b>:- {moment(startTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
            <div className="col-md-6 winrs">
              <b>End</b>:- 
              {moment(endTime).format('DD/MM/YYYY hh:mm:ss A')}
            </div>
          </div>
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>BetTotal</b>:- {winDetailData?.betTotal}
            </div>
            <div className="col-md-6 winrs ">
              <b>Winning Number</b>:-
              {fiftyTwoCards.map((data, index) => {
                  return(
                    <>
                    {
                      !!winDetailData && !!winDetailData?.winningNumber && data.number == winDetailData?.winningNumber && <img src={data.img} />
                    }
                    </>
                  )
              })
              }
            </div>
          </div>
          {/* end */}
          <div className="row manual-numbr">
            <div className="col-md-6 winrs">
              <b>Jackpot</b>:- {winDetailData?.jackpot}
            </div>
            <div className="col-md-6 winrs">
              <b>Winning Amount</b>:- {winLuckyCrdTop.toFixed(2)}
              {/* {cards.map((data, index) => {
                  return(
                    <>
                   <b>{!!winDetailData && !!winDetailData?.winningNumber && data.number == winDetailData?.winningNumber && data.betName}</b> 
                    </>
                  )
              })
              } */}
            </div>
          </div>
          {/* end */}
          <div className="row txt-cntr mrg-top-dtd hdr-bg">
            <div className="col-md-6">
              <b>Betting Type</b>
            </div>
            <div className="col-md-6">
              <b>Amount</b>
            </div>
          </div>
          {userbetlist !== undefined
            ? Object.keys(userbetlist).map((key) => {
                return (
                  <>
                    <div className="row txt-cntr betting-type-dta">
                      <div className="col-md-6">{key}</div>
                      <div className="col-md-6">{userbetlist[key]}</div>
                    </div>
                  </>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => (
  {
  userBetNumber: state.bettingData.userPlaced,
  userbetlist: state.bettingData?.bettingType,
  winDetailData: state.bettingData?.winDetailUser,
  winDetailHistory: state.bettingData?.gameDetailsOnHistory,
  auth: state.auth,
});

export default connect(mapStateToProps, { placedUserBet })(FiftyTwoUserGameBoard);

import React from 'react';
import AdminDashboard from '../pages/UsersList/AdminDashboard';
import './AdminGames.css';
import { getGames } from '../store/actions/gamesAction';
import { connect } from 'react-redux';
import GameImg from '../images/game.jpg';
import luckyCard from '../images/lucky-card.jpg';
import { Link } from 'react-router-dom';
import fiftyTwocards from '../images/52cards.jpg';
import spinToWin from '../images/spintowin.jpg';
import tripleChance from '../images/triple-chance.jpg';

function AdminGames() {

  return (
    <div className="admin-manage-game">
      <div className="row multi-game-bg ">
        <div className="col-md-3 mt-4">
          <AdminDashboard img={GameImg} title={'Roulette Game'} url={'/current-game'} />
        </div>
        <div className="col-md-3 mt-4">
          <AdminDashboard img={luckyCard} title={'Lucky Card'} url={'/lucky-card'} />
        </div>
        <div className="col-md-3 mt-4">
          <AdminDashboard img={fiftyTwocards} title={'52 Cards'} url={'/fifty-two-cards'} />
        </div>
        <div className="col-md-3 mt-4">
          <AdminDashboard img={spinToWin} title={'Spin To Win'} url={'/spin-to-win'} />
        </div>
        <div className="col-md-3 mt-4">
          <AdminDashboard img={tripleChance} title={'Triple Chance'} url={'/triple-chance'} />
        </div>
        <div className="col-md-3 mt-4">
            <div className="go-to-userBack">
                <Link to="/admin">Go To Dashboard <i className="fa fa-arrow-right" aria-hidden="true"></i></Link>
            </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  gamesList: state.games.games,
  auth: state.auth,
});

export default connect(mapStateToProps, { getGames })(AdminGames);

import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_BETTINGDATA_LOADING,
  GET_BETTINGDATA_SUCCESS,
  GET_BETTINGDATA_FAIL,
  GET_CURRENTGAMEDETAIL_LOADING,
  GET_CURRENTGAMEDETAIL_SUCCESS,
  GET_CURRENTGAMEDETAIL_FAIL,
  GET_CURRENTRESULT_LOADING,
  GET_CURRENTRESULT_SUCCESS,
  GET_CURRENTRESULT_FAIL,
  GET_CURRENTRESULTIMER_LOADING,
  GET_CURRENTRESULTIMER_SUCCESS,
  GET_CURRENTRESULTIMER_FAIL,
  CLEAR_BETTINGDATA_ERROR,
} from '../types';

export const getBettingData =
  (start, end, day, gamename, currentPage, minuspage, username, ticket_id) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_BETTINGDATA_LOADING,
    });
    try {
      const { bettingData } = getState().bettingData;
      const walletType = { start, end, day, gamename, currentPage, minuspage, username, ticket_id };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/bettings/filter', walletType, options);
      dispatch({
        type: GET_BETTINGDATA_SUCCESS,
        payload: {
          bettingData: [...bettingData, ...response.data.bets],
          nagtvMenu: response.data.minuspage,
          blankPlaying: response.data.bets,
        },
      });
    } catch (err) {
      dispatch({
        type: GET_BETTINGDATA_FAIL,
        payload: err.message,
      });
    }
  };

export const placedUserBet = (gameid, id) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
    payload: { gameid, id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/usergamehistory/${gameid}/${id}`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: {
        userPlaced: response.data.numbers,
        bettingType: response.data.bettingtypes,
        winDetailUser: response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

export const placedGameBet = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/${id}`, options);

    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: {
        gamePlaced: response.data.numbers,
        gamePlacedList: response.data.bettingtypes,
        fullGameBordData: response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

export const currentGameBet = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/currentgame`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { currentGame: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

// lucky card
export const lcCurrentGameBet = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULTIMER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/lccurrentgame`, options);
    dispatch({
      type: GET_CURRENTRESULTIMER_SUCCESS,
      payload: { lccurrentGame: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULTIMER_FAIL,
      payload: err.message,
    });
  }
};
// lucky card
export const tripleChanceGameBet = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULTIMER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/triplechancecurrentgame`, options);
    dispatch({
      type: GET_CURRENTRESULTIMER_SUCCESS,
      payload: { tripleChancecurrentGame: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULTIMER_FAIL,
      payload: err.message,
    });
  }
};
// 52 cards lucky card
export const fiftyTwoCurrentGameBet = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULTIMER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/52cardscurrentgame`, options);
    dispatch({
      type: GET_CURRENTRESULTIMER_SUCCESS,
      payload: { lccurrentGame: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULTIMER_FAIL,
      payload: err.message,
    });
  }
};
// spin to win card
export const spinToWinCurrentGameBet = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULTIMER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/spintowincurrentgame`, options);
    dispatch({
      type: GET_CURRENTRESULTIMER_SUCCESS,
      payload: { lccurrentGame: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULTIMER_FAIL,
      payload: err.message,
    });
  }
};
// lucky card
export const fiftyTwoCurrentGameBetId = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/52card/crgamelist`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { fiftyTwocurrentGameId: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};
// spin to win card
export const spinToWinCurrentGameBetId = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/spin2win/crgamelist`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { spintoWincurrentGameId: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};
export const currentGameBetId = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/crgamelist`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: {
        currentGameId: response.data,
        jakPotWinAmount: response.data?.winamount_number,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

// lucky card
export const lcCurrentGameBetId = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/lcard/crgamelist`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { lccurrentGameId: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};
// triple chance betting
export const tripleChanceGameBetId = () => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/triplechance/crgamelist`, options);
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { tripleChancecurrentGameId: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

export const manualNumber = (number) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { number };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberBettingHistories/drawnumber',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { bettingData: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

// Lucky Card
export const lcmanualNumber = (number, jackpot) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { number, jackpot };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberBettingHistories/lcdrawnumber',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { lcManualNmbr: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};
// triple chance
export const tripleChance = (number, jackpot) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { number, jackpot };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberBettingHistories/triplechancedrawnumber',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { tripleChnaceManualNmbr: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};
// 52 Card draw number
export const fiftyTwomanualNumber = (number, jackpot) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { number, jackpot };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberBettingHistories/52cardsdrawnumber',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { fiftyToManualNmbr: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};
// spin to win draw number
export const spinToWinmanualNumber = (number, jackpot) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { number, jackpot };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberBettingHistories/spintowindrawnumber',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { fiftyToManualNmbr: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

export const toDayResult = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/todaystotal`, options);
    dispatch({
      type: GET_CURRENTRESULT_SUCCESS,
      payload: { todayResult: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULT_FAIL,
      payload: err.message,
    });
  }
};
// lucky card
export const lcToDayResult = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/lctodaystotal`, options);
    dispatch({
      type: GET_CURRENTRESULT_SUCCESS,
      payload: { lctodayResult: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULT_FAIL,
      payload: err.message,
    });
  }
};
// triple chnace today result
export const tripleChanceToDayResult = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/triplechancetodaystotal`, options);
    dispatch({
      type: GET_CURRENTRESULT_SUCCESS,
      payload: { tripleChnstodayResult: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULT_FAIL,
      payload: err.message,
    });
  }
};
// 52 card todays current game result
export const fiftyToCardDayResult = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/52cardstodaystotal`, options);
    dispatch({
      type: GET_CURRENTRESULT_SUCCESS,
      payload: { fiftytodayResult: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULT_FAIL,
      payload: err.message,
    });
  }
};
// spin to win todays current game result
export const spinToWinTodayDayResult = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTRESULT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/gamehistory/spintowintodaystotal`, options);
    dispatch({
      type: GET_CURRENTRESULT_SUCCESS,
      payload: { spinToWintodayResult: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTRESULT_FAIL,
      payload: err.message,
    });
  }
};
export const jakPotNuber = (jackpot) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { jackpot };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberbettinghistories/drawjackpot',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { bettingData: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

export const jakPotNuberLuckyCard = (jackpot) => async (dispatch, getState) => {
  dispatch({
    type: GET_BETTINGDATA_LOADING,
  });
  try {
    const walletType = { jackpot };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(
      '/api/numberbettinghistories/lcdrawjackpot',
      walletType,
      options,
    );
    dispatch({
      type: GET_BETTINGDATA_SUCCESS,
      payload: { luckyCardbettingData: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_BETTINGDATA_FAIL,
      payload: err.message,
    });
  }
};

// game board layout game type
export const gameTypeBoard = (gameHistoryId) => async (dispatch, getState) => {
  dispatch({
    type: GET_CURRENTGAMEDETAIL_LOADING,
    payload: { gameHistoryId },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `/api/gamehistory/checkrelatedgames/${gameHistoryId}`,
      options,
    );
    dispatch({
      type: GET_CURRENTGAMEDETAIL_SUCCESS,
      payload: {
        gameDetailsData: response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_CURRENTGAMEDETAIL_FAIL,
      payload: err.message,
    });
  }
};

export const clearHistoryError = (id) => ({
  type: CLEAR_BETTINGDATA_ERROR,
  payload: { id },
});

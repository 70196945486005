import React, { useEffect } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import DashboardList from '../components/Dashboard/DashboardList';
import { getDashboard } from '../store/actions/dashboardAction';
import { allUsers } from '../store/actions/usersAction';

const Dashboard = (props) => {
  const {getDashboard} = props;
  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  return (
    <div className="dash-main">
            <div className="row">
              
              <div className="col-md-4">
                <DashboardList
                  title={'Total Admin Amount'}
                  amount={
                    props.dashboardDataBlock?.[5]?.[0]?.coins
                      ? props.dashboardDataBlock?.[5]?.[0]?.coins
                      : 0
                  }
                  icon={<i className="fa fa-money" aria-hidden="true"></i>}
                  url="#"
                />
              </div>
              <div className="col-md-4">
                <DashboardList
                  title={'Total Playing'}
                  amount={
                    props.dashboardDataBlock?.[1]?.[0]?.total_betting
                      ? props.dashboardDataBlock?.[1]?.[0]?.total_betting
                      : 0
                  }
                  icon={<i className="fa fa-gamepad" aria-hidden="true"></i>}
                  url="/betting"
                />
              </div>
              <div className="col-md-4">
                <DashboardList
                  title={'Total Winning'}
                  amount={
                    props.dashboardDataBlock?.[1]?.[0]?.total_winning
                      ? props.dashboardDataBlock?.[1]?.[0]?.total_winning
                      : 0
                  }
                  icon={<i className="fa fa-gamepad" aria-hidden="true"></i>}
                  url="/winning"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <DashboardList
                  title={'Total Agents'}
                  amount={
                    props.dashboardDataBlock?.[2]?.[0]?.agents
                      ? props.dashboardDataBlock?.[2]?.[0]?.agents
                      : 0
                  }
                  icon={<i className="fa fa-users" aria-hidden="true"></i>}
                  url="#"
                />
              </div>
              <div className="col-md-4">
                <DashboardList
                  title={'Total Customers'}
                  amount={
                    props.dashboardDataBlock?.[3]?.[0]?.customers
                      ? props.dashboardDataBlock?.[3]?.[0]?.customers
                      : 0
                  }
                  icon={<i className="fa fa-users" aria-hidden="true"></i>}
                  url="/users"
                />
              </div>
              <div className="col-md-4">
                <DashboardList
                  title={'Total Blocked'}
                  amount={
                    props.dashboardDataBlock?.[4]?.[0]?.Blocked
                      ? props.dashboardDataBlock?.[4]?.[0]?.Blocked
                      : 0
                  }
                  icon={<i className="fa fa-ban" aria-hidden="true"></i>}
                  url="#"
                />
              </div>
            </div>
          </div>
  );
};
const mapStateToProps = (state) => ({
  dashboardDataBlock: state.getDashboard.dashboardData,
  auth: state.auth,
});

export default connect(mapStateToProps, { getDashboard, allUsers })(Dashboard);

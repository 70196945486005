import React, { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { loginUserWithEmail } from '../../store/actions/authActions';
import './styles.css';
import { Button } from 'react-bootstrap';

const Login = ({ auth, history, loginUserWithEmail }) => {
  // useEffect(() => {
  //   if (localStorage.getItem('token')) {
  //     history.push('./users');
  //   }
  // }, [history]);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      loginUserWithEmail(values, history);
    },
  });

  if (auth.isAuthenticated && auth.me?.role.name === 'admin') return <Redirect to="/admin" />;
  if (auth.isAuthenticated && auth.me?.role.name === 'agent') return <Redirect to="/users" />;
  if (auth.isAuthenticated && auth.me?.role.name === 'customer') return <Redirect to="/transactions" />;

  return (
    <div className="login">
      <div className="container">
        <h1 className="loginTitle mb-4">LogIn</h1>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <input
              placeholder="Enter Your UserName"
              name="username"
              className="text"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <p className="error">{formik.errors.username}</p>
            ) : null}
            <input
              placeholder="Enter Your Password"
              name="password"
              type="password"
              className="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="error">{formik.errors.password}</p>
            ) : null}
          </div>
          <div>
            <Button
              className="btn login-button"
              disabled={auth.isLoading || !formik.isValid}
              type="submit"
              variant="success"
            >
              LogIn
            </Button>
            {auth.error && <p className="error">{auth.error}</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default compose(withRouter, connect(mapStateToProps, { loginUserWithEmail }))(Login);

import {
  GET_BETTING_LOADING,
  GET_BETTING_SUCCESS,
  GET_BETTING_FAIL,
  ADD_BETTING_LOADING,
  ADD_BETTING_SUCCESS,
  ADD_BETTING_FAIL,
  DELETE_BETTING_LOADING,
  DELETE_BETTING_SUCCESS,
  DELETE_BETTING_FAIL,
  EDIT_BETTING_LOADING,
  EDIT_BETTING_SUCCESS,
  EDIT_BETTING_FAIL,
  CLEAR_BETTING_ERROR,
} from '../types';

const initialState = {
  bettingtypes: [],
  isLoading: false,
  error: null,
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_BETTING_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_BETTING_LOADING:
      return {
        ...state,
        bettingtypes: [
          {
            id: 0,
            text: 'Loading...',
            isLoading: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            user: { ...payload.me },
          },
          ...state.bettingtypes,
        ],
      };

    case DELETE_BETTING_LOADING:
    case EDIT_BETTING_LOADING:
      return {
        ...state,
        bettingtypes: state.bettingtypes.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: true };
          return m;
        }),
      };
    case GET_BETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bettingtypes: [...payload.bettingtypes],
      };
    case ADD_BETTING_SUCCESS:
      return {
        ...state,
        bettingtypes: state.bettingtypes.map((m) => {
          if (m.id === 0) return payload.bettingtypes;
          return m;
        }),
      };
    case DELETE_BETTING_SUCCESS:
      return {
        ...state,
        bettingtypes: state.bettingtypes.filter((m) => m.id !== payload.bettingtypes.id),
      };
    case EDIT_BETTING_SUCCESS:
      return {
        ...state,
        bettingtypes: state.bettingtypes.map((m) => {
          if (m.id === payload.bettingtypes.id) return payload.bettingtypes;
          return m;
        }),
      };
    case DELETE_BETTING_FAIL:
    case EDIT_BETTING_FAIL:
      return {
        ...state,
        error: null,
        bettingtypes: state.bettingtypes.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: payload.error };
          return m;
        }),
      };
    case GET_BETTING_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_BETTING_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        bettingtypes: state.bettingtypes.filter((m) => m.id !== 0),
      };
    case CLEAR_BETTING_ERROR:
      return {
        ...state,
        bettingtypes: state.bettingtypes.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

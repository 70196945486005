import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_DASHBOARD_LOADING,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
  CLEAR_DASHBOARD_ERROR,
} from '../types';
 
export const getDashboard = () => async (dispatch, getState) => {
  dispatch({
    type: GET_DASHBOARD_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/dashboard', options);

    dispatch({
      type: GET_DASHBOARD_SUCCESS,
      payload: {
        dashboardDataApi: response.data.data,
        appLogo: response.data?.logo,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_DASHBOARD_FAIL,
      payload: { error: err?.response?.data.settings || err.settings },
    });
  }
};
export const clearUsersError = (id) => ({
  type: CLEAR_DASHBOARD_ERROR,
  payload: { id },
});

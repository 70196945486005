import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Datatable from 'react-bs-datatable';
import moment from 'moment';
import { deleteLockedDevices, getLockedDevices } from '../../store/actions/settingsAction';
import { GET_LOCKEDDEVICES_SUCCESS } from '../../store/types';

const LockedDevices = ({
getLockedDevices,
lockedDevicesData,
blankmsg,
deleteLockedDevices
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
        type: GET_LOCKEDDEVICES_SUCCESS,
        payload: { lockedDevice: [] },
    });
    getLockedDevices()
  }, []);
  const loadMoreData = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
    getLockedDevices(currentPage);
  };
  // Table data
  const header = [
    { title: 'SrNo.', prop: 'srno' },
    { title: 'Username', prop: 'username' },
    { title: 'Device Id', prop: 'deviceid' },
    { title: 'Date', prop: 'date' },
    { title: 'Action', prop: 'action' },
  ];

  // Table Body Data
  let bodyData = [];
  if (lockedDevicesData !== undefined) {
    bodyData = [
        lockedDevicesData.map((users, index) => {
        return {
          srno: index+1 + ".",
          username: users?.username,
          deviceid: users?.deviceid,
          date: moment(users.createdAt).format('DD/MM/YYYY hh:mm:ss'),
          action: <span onClick={async() => {
            dispatch({
                type: GET_LOCKEDDEVICES_SUCCESS,
                payload: { lockedDevice: [] },
            });
            await deleteLockedDevices(users?.id);
            getLockedDevices();
            }
        } class="delete-icon" type="button" variant="primary"><i class="fa fa-trash-o"></i></span>,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className="mt-3">
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
      />
    {!!blankmsg && !!blankmsg.length > 0 ? (
        <div className="row">
          <div className="col-md-12">
            <button className="load-more-btn btn btn-success" onClick={loadMoreData}>
              Load More.....
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
    lockedDevicesData: state.settings.lockedDevicesList,
    blankmsg: state.settings.blankmsgs,
    auth: state.auth,
});
export default connect(mapStateToProps, { getLockedDevices, deleteLockedDevices })(LockedDevices);

import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  SEARCH_USERS_LOADING,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
  GET_USERS_LOADING,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USERS_TOKEN_LOADING,
  ADD_USERS_TOKEN_SUCCESS,
  ADD_USERS_TOKEN_FAIL,
  ADD_TRAN_LOADING,
  ADD_TRAN_SUCCESS,
  ADD_TRAN_FAIL,
  ADD_USERS_LOADING,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  DELETE_USERS_LOADING,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  EDIT_USERS_LOADING,
  EDIT_USERS_SUCCESS,
  EDIT_USERS_FAIL,
  CLEAR_USERS_ERROR,
  ERROR_SUCCESS,
  ADD_NEW_USERS_SUCCESS,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_AGENTS_SELECT_LOADING,
  GET_AGENTS_SELECT_SUCCESS,
  GET_AGENTS_SELECT_FAIL,
} from '../types';

export const getUsers = () => async (dispatch, getState) => {
  dispatch({
    type: GET_USERS_LOADING,
  });
  try {
    let config = {
      headers: {
        'Content-type': 'application/json',
        'x-auth-token': getState().auth.token,
      },
    };
    const response = await axios.get('/api/users', config);
    const token = response.data.users.token;
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: { messages: response.data.users, token },
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
// search-agents
export const getSelectAgents = () => async (dispatch, getState) => {
  dispatch({
    type: GET_AGENTS_SELECT_LOADING,
  });
  try {
    // let config = {
    //   headers: {
    //     'Content-type': 'application/json',
    //     'x-auth-token': getState().auth.token,
    //   },
    // };
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/users/allagents', options);
    const token = response.data.users.token;
    dispatch({
      type: GET_AGENTS_SELECT_SUCCESS,
      payload: { selectedAgent: response.data.users, token },
    });
  } catch (err) {
    dispatch({
      type: GET_AGENTS_SELECT_FAIL,
      payload: { error: err?.response?.data.selectedAgent || err.selectedAgent },
    });
  }
};
// end
export const allUsers = (role, currentPage) => async (dispatch, getState) => {
  dispatch({
    type: ADD_USERS_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const { roleUsers } = getState().users;
    const formData = { role, currentPage };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/users/byrole', formData, options);
    dispatch({
      type: ADD_USERS_SUCCESS,
      payload: {
        message: [...roleUsers, ...response.data.users],
        blankmsg: response.data.users,
        roleIDLoad: response?.data?.roleId,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const searchUser = (role, currentPage, searchusername) => async (dispatch, getState) => {
  dispatch({
    type: SEARCH_USERS_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const formData = { role, currentPage, searchusername };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/users/byrole', formData, options);
    dispatch({
      type: SEARCH_USERS_SUCCESS,
      payload: { message: response.data.users },
    });
  } catch (err) {
    dispatch({
      type: SEARCH_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addUsers =
  (id, name, username, email, phone, role, password, commission, pname, games, canAgentCreateUser) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_USERS_LOADING,
      payload: { me: { ...getState().auth.me } },
    });
    try {
      role = parseInt(role);
      const formData = {
        id,
        name,
        username,
        email,
        phone,
        role,
        password,
        commission,
        pname,
        games,
        canAgentCreateUser
      };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/users', formData, options);
      dispatch({
        type: ADD_NEW_USERS_SUCCESS,
        payload: { addNewUserByRole: response.data.message },
      });
    } catch (err) {
      dispatch({
        type: ADD_USERS_FAIL,
        payload: {
          error: err?.response?.data.message || err.message,
        },
      });
    }
  };

export const deleteUsers = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_USERS_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`/api/users/${id}`, options);
    dispatch({
      type: DELETE_USERS_SUCCESS,
      payload: { message: response.data.message },
    });
  } catch (err) {
    dispatch({
      type: DELETE_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editUsers =
  (id, name, username, email, phone, status, password, commission, role, games,canAgentCreateUser, softDelete ) =>
  async (dispatch, getState) => {
    dispatch({
      type: EDIT_USERS_LOADING,
      payload: { id },
    });
    try {
      let config = {
        headers: {
          'Content-type': 'application/json',
          'x-auth-token': getState().auth.token,
        },
      };
      const formData = { name, username, email, phone, status, password, commission, role, games ,canAgentCreateUser, softDelete};
      const response = await axios.put(`/api/users/${id}`, formData, config);
      dispatch({
        type: EDIT_USERS_SUCCESS,
        payload: { message: response.data.users },
      });
    } catch (err) {
      dispatch({
        type: EDIT_USERS_FAIL,
        payload: { error: err?.response?.data.message || err.message, id },
      });
    }
  };

export const logOutSUser = (userId) => async (dispatch, getState) => {
  dispatch({
    type: ADD_USERS_TOKEN_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const userid = { userId };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('api/users/logout/user', userid, options);
    dispatch({
      type: ADD_USERS_TOKEN_SUCCESS,
      payload: { addNewUserByRole: response.data.message },
    });
  } catch (err) {
    dispatch({
      type: ADD_USERS_TOKEN_FAIL,
      payload: {
        error: err?.response?.data.message || err.message,
      },
    });
  }
};

export const transaction = (userid, currentPage) => async (dispatch, getState) => {
  dispatch({
    type: ADD_USERS_LOADING,
    payload: { userid },
  });
  try {
    const { transactionUser } = getState().users;
    const formData = { userid, currentPage };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`/api/transactions`, formData, options);
    dispatch({
      type: ADD_TRAN_SUCCESS,
      payload: {
        transactionUser: [...transactionUser, ...response.data],
        blankData: response.data,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_TRAN_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const userParent = (userid) => async (dispatch, getState) => {
  dispatch({
    type: ADD_USERS_LOADING,
    payload: { userid },
  });
  try {
    const formData = { userid };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`/api/users/byrole`, formData, options);
    dispatch({
      type: ADD_TRAN_SUCCESS,
      payload: { parentUnderUser: response.data.users },
    });
  } catch (err) {
    dispatch({
      type: ADD_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const bettings = (userid, currentPage) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TRAN_LOADING,
    payload: { userid, currentPage },
  });
  try {
    const { Userbettings } = getState().users;
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/bettings/byuser/${userid}/${currentPage}`, options);
    dispatch({
      type: ADD_TRAN_SUCCESS,
      payload: {
        Userbettings: [...Userbettings, ...response.data.bettings],
        blankPlaying: response.data.bettings,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_TRAN_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const ChangePassword = (password, newpassword) => async (dispatch, getState) => {
  dispatch({
    type: CHANGE_PASSWORD_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const formData = { password, newpassword };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/users/change-password', formData, options);
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: {
        changePassword: response.data.users,
        changePasswordmsg: response.data.message,
      },
    });
  } catch (err) {
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: {
        error: err?.response?.data.message || err.message,
      },
    });
  }
};

export const clearUsersError = (id) => ({
  type: CLEAR_USERS_ERROR,
  payload: { id },
});
export const errorStatusShow = () => ({
  type: ERROR_SUCCESS,
});

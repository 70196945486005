import { combineReducers } from 'redux';

import authReducer from './authReducer';
import registerReducer from './registerReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import messageReducer from './messageReducer';
import gamesReducer from './gamesReducer';
import historyReducer from './historyReducer';
import bettingReducer from './bettingReducer';
import bettingDataReducer from './bettingDataReducer';
import settingsReducer from './settingsReducer';
import walletReducer from './walletReducer';
import tokenReducer from './tokenReducer';
import dashboardReducer from './dashboardReducer';
import winningReducer from './winningReducer';
import inactiveReducer from './inactiveReducer';
import turnOverReducer from './turnOverReducer';

export default combineReducers({
  auth: authReducer,
  register: registerReducer,
  message: messageReducer,
  user: userReducer,
  users: usersReducer,
  games: gamesReducer,
  history: historyReducer,
  betting: bettingReducer,
  bettingData: bettingDataReducer,
  settings: settingsReducer,
  wallet: walletReducer,
  token: tokenReducer,
  getDashboard: dashboardReducer,
  winning: winningReducer,
  inactive: inactiveReducer,
  turnOver: turnOverReducer,
});

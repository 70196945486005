import {
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  ADD_SETTINGS_LOADING,
  ADD_SETTINGS_SUCCESS,
  ADD_SETTINGS_FAIL,
  DELETE_SETTINGS_LOADING,
  DELETE_SETTINGS_SUCCESS,
  DELETE_SETTINGS_FAIL,
  EDIT_SETTINGS_LOADING,
  EDIT_SETTINGS_SUCCESS,
  EDIT_SETTINGS_FAIL,
  CLEAR_SETTINGS_ERROR,GET_LOCKEDDEVICES_LOADING,
  GET_LOCKEDDEVICES_SUCCESS,GET_LOCKEDDEVICES_FAIL
} from '../types';

const initialState = {
  settingData: [],
  lockedDevicesList: [],
  blankmsgs: [],
  isLoading: false,
  error: null,
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_SETTINGS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_SETTINGS_LOADING:
      return {
        ...state,
        settingData: [
          {
            id: 0,
            text: 'Loading...',
            isLoading: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            user: { ...payload.me },
          },
          ...state.settingData,
        ],
      };

    case DELETE_SETTINGS_LOADING:
    case EDIT_SETTINGS_LOADING:
      return {
        ...state,
        settingData: state.settingData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: true };
          return m;
        }),
      };
      case GET_LOCKEDDEVICES_LOADING:
        return{
          ...state,
          isLoading: true,
        };
    case GET_LOCKEDDEVICES_SUCCESS:
        return{
          ...state,
          lockedDevicesList: payload.lockedDevice,
          blankmsgs: payload.blankmsg
        }
    case GET_LOCKEDDEVICES_FAIL:
        return{
          ...state,
          error: true
        }
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingData: [...payload.settingData],
      };
    case ADD_SETTINGS_SUCCESS:
      return {
        ...state,
        settingData: state.settingData.map((m) => {
          if (m.id === 0) return payload.settingData;
          return m;
        }),
      };
    case DELETE_SETTINGS_SUCCESS:
      return {
        ...state,
        settingData: state.settingData.filter((m) => m.id !== payload.settingData.id),
      };
    case EDIT_SETTINGS_SUCCESS:
      return {
        ...state,
        settingData: state.settingData.map((m) => {
          if (m.id === payload.settingData.id) return payload.settingData;
          return m;
        }),
      };
    case DELETE_SETTINGS_FAIL:
    case EDIT_SETTINGS_FAIL:
      return {
        ...state,
        error: null,
        settingData: state.settingData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: payload.error };
          return m;
        }),
      };
    case GET_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        settingData: state.settingData.filter((m) => m.id !== 0),
      };
    case CLEAR_SETTINGS_ERROR:
      return {
        ...state,
        settingData: state.settingData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_WALLET_LOADING,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAIL,
  ADD_WALLET_LOADING,
  ADD_WALLET_SUCCESS,
  ADD_WALLET_FAIL,
  CLEAR_WALLET_ERROR,
} from '../types';

export const getWallet =
  (start, end, username, _id, day, gamename, currentPage) => async (dispatch, getState) => {
    dispatch({
      type: GET_WALLET_LOADING,
    });
    try {
      const { Wallet } = getState().wallet;
      const walletType = { start, end, username, _id, day, gamename, currentPage };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/transactions/filter', walletType, options);
      dispatch({
        type: GET_WALLET_SUCCESS,
        payload: { Wallet: [...Wallet, ...response.data], blankWallet: response.data },
      });
    } catch (err) {
      dispatch({
        type: GET_WALLET_FAIL,
        payload: { error: err?.response?.data.message || err.message },
      });
    }
  };

export const getTranFiltr = (type) => async (dispatch, getState) => {
  dispatch({
    type: GET_WALLET_LOADING,
  });
  try {
    const walletType = { type };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/transactions/filter', walletType, options);
    dispatch({
      type: GET_WALLET_SUCCESS,
      payload: { Wallet: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_WALLET_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addWallet = (user_id, trans_coins) => async (dispatch, getState) => {
  dispatch({
    type: ADD_WALLET_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    trans_coins = parseInt(trans_coins);
    const formData = { user_id, trans_coins };
    const options = attachTokenToHeaders(getState);
    const response = await axios.post('/api/transactions/wallet', formData, options);
    dispatch({
      type: ADD_WALLET_SUCCESS,
      payload: {
         Wallet: response.data.wallet,
         scsmsg: response.data.message
        },
    });
  } catch (err) {
    dispatch({
      type: ADD_WALLET_FAIL,
      payload: { error: err?.response?.data.Wallet || err.Wallet },
    });
  }
};

export const clearWalletError = (id) => ({
  type: CLEAR_WALLET_ERROR,
  payload: { id },
});

import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_HISTORY_LOADING,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
  CLEAR_HISTORY_ERROR,
} from '../types';

export const getHistory =
  (start, end, day, gamename, currentPage) => async (dispatch, getState) => {
    dispatch({
      type: GET_HISTORY_LOADING,
    });
    try {
      const { gameHistory } = getState().history;
      const walletType = { start, end, day, gamename, currentPage };
      const options = attachTokenToHeaders(getState);
      const response = await axios.post('/api/gamehistory/filter', walletType, options);
      dispatch({
        type: GET_HISTORY_SUCCESS,
        payload: { gameHistory: [...gameHistory, ...response.data], blankHistory: response.data },
      });
    } catch (err) {
      dispatch({
        type: GET_HISTORY_FAIL,
        payload: err.message,
      });
    }
  };

export const getUserHistory = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_HISTORY_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`/api/users/gamehistory/${id}`, options);
    dispatch({
      type: GET_HISTORY_SUCCESS,
      payload: { gameHistory: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_HISTORY_FAIL,
      payload: err.message,
    });
  }
};

export const clearHistoryError = (id) => ({
  type: CLEAR_HISTORY_ERROR,
  payload: { id },
});

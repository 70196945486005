import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Datatable from 'react-bs-datatable';
import { getWinerData } from '../../store/actions/winningAction';
import trueIcon from '../../images/true.png';
import falseIcon from '../../images/false.png';

import './styles.css';

const WinningList = ({ getWinerData, winningUsers }) => {
  useEffect(() => {
    getWinerData();
  }, [getWinerData]);

  // Table data
  const header = [
    { title: 'Name', prop: 'name'},
    { title: 'Amount', prop: 'amount' },
    { title: 'Status', prop: 'status' },
    { title: 'Win Status', prop: 'winStatus' },
    { title: 'Winning', prop: 'winning' },
  ];

  // Table Body Data
  let bodyData = [];
  if (winningUsers !== undefined) {
    bodyData = [
      winningUsers.map((users, index) => {
        return {
          name: users?.name,
          amount: users?.amount,
          status: users?.status,
          winStatus: users?.win_status ? <img src={trueIcon} alt="Condision true"/> : <img src={falseIcon} alt="Condision False"/>,
          winning: users?.winning,
        };
      }),
    ];
  }

  // Classes
  const classes = {
    table: 'table-striped table-hover',
    td: 'tbl-img',
  };

  return (
    <div className="">
      <Datatable
        tableHeaders={header}
        tableBody={bodyData.length > 0 ? bodyData[0] : []}
        classes={classes}
        rowsPerPage={10}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  winningUsers: state.winning.winningData,
  auth: state.auth,
});
export default connect(mapStateToProps, { getWinerData })(WinningList);

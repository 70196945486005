import { GET_WINNING_SUCCESS, GET_WINNING_FAIL, CLEAR_WINNING_ERROR } from '../types';

const initialState = {
  winningData: [],
  isLoading: false,
  error: null,
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_WINNING_SUCCESS:
      return {
        ...state,
        isLoading: true,
        winningData: payload.winningData,
      };
    case GET_WINNING_FAIL:
      return {
        ...state,
        isLoading: false,
        winningData: [],
        error: payload,
      };
    case CLEAR_WINNING_ERROR:
      return {
        ...state,
        winningData: state.winningData.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}

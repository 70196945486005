import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import './profile.css';

function MainProfile(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <span type="button" variant="primary" onClick={handleShow}>
        {props.profileicon}
      </span>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header className="profile-header">
          <div className="row">
            <div className="col-md-12 cntr user-profile-name mt-5">
              <p>{props.auth?.me?.name}</p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-8 profile-sub-details">
                <div className="row pt-3">
                  <div className="col-md-4 cntr">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                  <div className="col-md-8">{props.auth?.me?.name}</div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-4 cntr">
                    <i className="fa fa-tasks" aria-hidden="true"></i>
                  </div>
                  <div className="col-md-8">{props.auth?.me?.coins} Coins</div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-4 cntr">
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <div className="col-md-8">{props.auth?.me?.email}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn dngr-color" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(MainProfile);

import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import { GET_TOKEN_LOADING, GET_TOKEN_SUCCESS, GET_TOKEN_FAIL, CLEAR_TOKEN_ERROR } from '../types';

export const getTokens = () => async (dispatch, getState) => {
  dispatch({
    type: GET_TOKEN_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get('/api/tokens', options);
    dispatch({
      type: GET_TOKEN_SUCCESS,
      payload: { gameToken: response.data.token },
    });
  } catch (err) {
    dispatch({
      type: GET_TOKEN_FAIL,
      payload: err.message,
    });
  }
};

export const updateTokens = (id, status) => async (dispatch, getState) => {
  dispatch({
    type: GET_TOKEN_LOADING,
    payload: { id },
  });
  try {
    const formData = { id, status };
    const options = attachTokenToHeaders(getState);
    const response = await axios.put('/api/tokens/update', formData, options);
    dispatch({
      type: GET_TOKEN_SUCCESS,
      payload: { gameUpdateToken: response.data.token },
    });
  } catch (err) {
    dispatch({
      type: GET_TOKEN_FAIL,
      payload: err.message,
    });
  }
};
export const clearTokenrror = (id) => ({
  type: CLEAR_TOKEN_ERROR,
  payload: { id },
});

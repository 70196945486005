import {
  GET_WALLET_LOADING,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAIL,
  ADD_WALLET_LOADING,
  ADD_WALLET_SUCCESS,
  ADD_WALLET_FAIL,
  DELETE_WALLET_LOADING,
  DELETE_WALLET_SUCCESS,
  DELETE_WALLET_FAIL,
  EDIT_WALLET_LOADING,
  EDIT_WALLET_SUCCESS,
  EDIT_WALLET_FAIL,
  CLEAR_WALLET_ERROR,
} from '../types';

const initialState = {
  Wallet: [],
  isLoading: false,
  error: '',
  scussMsg: []
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_WALLET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_WALLET_LOADING:
      return {
        ...state,
        Wallet: [
          {
            id: 0,
            text: 'Loading...',
            isLoading: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            user: { ...payload.me },
          },
          ...state.Wallet,
        ]
      };

    case DELETE_WALLET_LOADING:
    case EDIT_WALLET_LOADING:
      return {
        ...state,
        Wallet: state.Wallet.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: true };
          return m;
        }),
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        Wallet: [...payload.Wallet],
        blankWallet: payload.blankWallet
      };
    case ADD_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: true,
        Wallet: state.Wallet.map((m) => {
          if (m.id === 0) return payload.Wallet;
          return m;
        }),
        error: null,
        scussMsg: payload.scsmsg
      };
    case DELETE_WALLET_SUCCESS:
      return {
        ...state,
        Wallet: state.Wallet.filter((m) => m.id !== payload.Wallet.id),
      };
    case EDIT_WALLET_SUCCESS:
      return {
        ...state,
        Wallet: state.Wallet.map((m) => {
          if (m.id === payload.Wallet.id) return payload.Wallet;
          return m;
        }),
        error: payload.error,
      };
    case DELETE_WALLET_FAIL:
    case EDIT_WALLET_FAIL:
      return {
        ...state,
        error: null,
        Wallet: state.Wallet.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: payload.error };
          return m;
        }),
      };
    case GET_WALLET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_WALLET_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case CLEAR_WALLET_ERROR:
      return {
        ...state,
        Wallet: state.Wallet.map((m) => {
          if (m.id === payload.id) return { ...m, isLoading: false, error: null };
          return m;
        }),
      };
    default:
      return state;
  }
}
